import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { cn } from 'shared/lib';

const Input: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ className, ...props }) => {
  return (
    <input
      className={cn(
        `ag-text-base ag-border ag-border-neutral-300 ag-rounded-md ag-text-neutral-900 placeholder:ag-text-neutral-500 focus-visible:ag-outline-secondary disabled:ag-bg-white disabled:ag-opacity-50`,
        className
      )}
      {...props}
    />
  );
};

export default Input;
