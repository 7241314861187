import React, { FC, ReactNode, useRef, useState } from 'react';

import { AiFillCaretDown } from 'react-icons/ai';
import { VscChevronDown } from 'react-icons/vsc';

import { capitalize, scrollBarClasses, useClickOutside, cn } from 'shared';

type SelectInputProps = {
  name: string;
  options: string[] | readonly string[];
  value: string | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  className?: string;
  error?: string | boolean;
  showFont?: boolean;
  scroll?: boolean;
  placeholder?: string;
  borderColorClass?: string;
  icon?: ReactNode;
  CustomBtn?: FC<{ value: SelectInputProps['value']; open: boolean }>;
  optionsClassName?: string;
  wrapperClassName?: string;
  optionsWrapperClassName?: string;
};

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  options,
  value,
  onChange,
  disabled,
  className,
  error,
  showFont = false,
  scroll = true,
  placeholder = 'Select...',
  borderColorClass = 'ag-border-tertiary',
  icon,
  CustomBtn,
  optionsClassName = '',
  wrapperClassName,
  optionsWrapperClassName = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOnTop, setIsOnTop] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(selectRef, setIsOpen);

  return (
    <div
      className={cn(
        `ag-group ag-relative ag-text-xs ag-w-full`,
        wrapperClassName
      )}
    >
      <div
        className={cn(
          'ag-text-base ag-border  focus-visible:ag-outline-none disabled:ag-bg-white disabled:ag-opacity-50 ag-text-md focus:ag-outline-none ag-shadow-sm  focus:ag-ring-secondary focus:ag-border-secondary ag-cursor-pointer ag-relative ag-flex ag-items-center ag-justify-between ag-w-full ag-rounded-md ag-px-4 ag-py-3 ag-bg-[#171717] ag-border-[#525252] ag-text-white',
          className
        )}
        onClick={(e) => {
          setIsOnTop(
            e.currentTarget.getBoundingClientRect().bottom + 160 >
              window.innerHeight - 20
          );
          setIsOpen((prevState) => !prevState);
        }}
        ref={selectRef}
      >
        {CustomBtn ? (
          <CustomBtn open={isOpen} value={value} />
        ) : (
          <>
            <div className="ag-flex ag-items-center">
              {icon && <div className="ag-mr-2">{icon}</div>}
              {value && <span>{capitalize(value)}</span>}
            </div>
            <VscChevronDown
              className={`ag-right-2 ag-absolute ${
                isOpen && 'ag-transform ag-rotate-180'
              } `}
            />
          </>
        )}
        <div
          className={`ag-absolute ag-w-full ag-min-w-[13rem] ag-max-auto ag-py-1 ag-px-1 ag-left-0 ag-overflow-y-auto ag-overflow-x-visible ag-bg-[#171717] ag-border-[#525252] ag-z-50 ag-border ag-rounded-lg 
            ${isOpen ? 'ag-block' : 'ag-hidden'} ${
            isOnTop
              ? // ? 'ag-left-1/2 ag-bottom-0 ag--translate-x-1/2  lg:-translate-x-[1.5rem] -translate-y-[3.5rem]'
                'ag-left-0 ag-bottom-10'
              : 'ag-top-8'
          } ag-space-y-0.5 ${scrollBarClasses} ${optionsWrapperClassName}`}
        >
          {options?.map((opt) => (
            <div
              onClick={() => onChange(opt)}
              key={`${name}-${opt}`}
              className={cn(
                `ag-py-2 ag-px-2 ag-flex ag-justify-between hover:ag-bg-[#262626]/80 ag-rounded-lg`,
                opt === value &&
                  (optionsClassName ? 'ag-bg-neutral-100' : 'ag-bg-[#262626]'),
                optionsClassName
              )}
            >
              <span>{opt}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
