import { FormikProps } from 'formik';
import clsx from 'clsx';
import FormError from '../../../components/Forms/FormError/FormError';
import { FC } from 'react';
import { ITavusPersonaFormik } from 'entities/tavus/persona';

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  classNameBg?: string;
};

export const TavusPersonaDataForm: FC<Props> = ({
  formik,
  classNameBg = 'ag-bg-neutral-800'
}) => {
  return (
    <div className="ag-space-y-10">
      <div className="ag-flex ag-flex-col ag-relative">
        <label className="ag-mb-4 ag-text-white" htmlFor="name">
          <div>Name</div>
          <div className="ag-text-sm ag-text-neutral-400">
            What’s the name of your Video Agent?
          </div>
        </label>

        <input
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.name && formik.touched.name
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="My Video Agent"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          name="name"
          id="name"
        />
        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="systemPrompt">
          <div>Description</div>
          <div className="ag-text-sm ag-text-neutral-400">
            In a few words, describe your Video Agent.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.systemPrompt && formik.touched.systemPrompt
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="For example, “As a Life Coach, you are a dedicated professional who specializes in...”"
          name="systemPrompt"
          id="systemPrompt"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.systemPrompt}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.systemPrompt && formik.touched.systemPrompt && (
              <FormError>{formik.errors.systemPrompt}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.systemPrompt.length}/10 000 characters
          </div>
        </div>
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="greeting">
          <div>Context</div>
          <div className="ag-text-sm ag-text-neutral-400">
            This is the context that will be used by the llm.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.context && formik.touched.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
          )}
          placeholder={`For example, “Here are a few times that you have helped an individual make a breakthrough in...”`}
          name="context"
          id="context"
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.context}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.context && formik.touched.context && (
              <FormError>{formik.errors.context}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.context.length}/10 000 characters
          </div>
        </div>
      </div>
    </div>
  );
};
