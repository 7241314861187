import React, { useState } from 'react';
import { IAvatar } from '../../../shared/model/types';
import { FaInfoCircle } from 'react-icons/fa';
import clsx from 'clsx';
import Modal from 'components/shared/Modal';
import CustomTalkingPhotoInfo from './CustomTalkingPhotoFlow/components/CustomTalkingPhotoInfo';

type AvatarItemProps = {
  avatar: IAvatar;
  onChooseAvatar: (avatar: IAvatar) => void;
  isActive?: boolean;
  openModal?: (avatar: IAvatar) => void;
};

const AvatarItem: React.FC<AvatarItemProps> = ({
  avatar,
  onChooseAvatar,
  isActive,
  openModal
}) => {
  const [isInfoOpened, setIsInfoOpened] = useState(false);
  const imgUrl =
    avatar.inputImageUrl ?? avatar.menuImageUrl ?? avatar.rawImageUrl;

  const handleChooseAvatar = () => {
    onChooseAvatar(avatar);
  };

  return (
    <div
      onClick={handleChooseAvatar}
      className={clsx(
        `ag-relative ag-group ag-h-fit`,
        isActive
          ? 'ag-cursor-default'
          : 'ag-cursor-pointer ag-opacity-60 hover:ag-opacity-100'
      )}
    >
      {imgUrl && (
        <img
          src={imgUrl}
          alt={avatar.name}
          className={`ag-w-full ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-600'
          } `}
        />
      )}
      {avatar.customAvatar && (
        <button
          onClick={() => {
            setIsInfoOpened(true);
          }}
          type="button"
          className={clsx(
            'ag-absolute ag-w-5 ag-h-5 !ag-rounded-full ag-top-1 ag-right-0.5 ag-justify-center ag-items-center ag-opacity-75 hover:ag-opacity-100 ag-transition-opacity ag-hidden group-hover:ag-block'
          )}
        >
          <FaInfoCircle className="ag-text-sm ag-bg-white ag-text-black ag-rounded-full" />
        </button>
      )}
      <div className="ag-hidden group-hover:ag-block ag-absolute ag-bottom-0 ag-top-2/3 ag-left-0 ag-right-0 ag-bg-title-gradient ag-rounded-t" />
      {openModal && (
        <button
          onClick={() => {
            openModal(avatar);
          }}
          className="ag-absolute ag-w-5 ag-h-5 !ag-rounded-full ag-top-1 ag-right-1 ag-hidden ag-justify-center ag-items-center ag-z-20 group-hover:ag-flex ag-opacity-75 hover:ag-opacity-100"
        >
          <FaInfoCircle className="ag-text-sm ag-bg-white ag-text-black ag-rounded-full" />
        </button>
      )}
      <span className="ag-text-white ag-text-sm ag-inline ag-absolute ag-bottom-2 ag-left-2">
        {avatar.name}
      </span>
      {isInfoOpened && (
        <Modal
          setOpen={() => setIsInfoOpened(false)}
          open={isInfoOpened}
          noPadding
          noCrossIcon
          className="ag-w-full"
        >
          <CustomTalkingPhotoInfo
            avatar={avatar}
            onClose={() => setIsInfoOpened(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default AvatarItem;
