import { create } from 'zustand';

type CreditState = {
  isChatAvailable: boolean;
  premiumVoicesAllowed: boolean;
  productName: string;
};

type CreditActions = {
  updateCreditStore: (key: string, value: any) => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  toggleBuyCreditsModal: () => void;
  toggleUpgradeModal: () => void;
};

export const features = [
  'express',
  'pro',
  'agents',
  'embed',
  'extraCredits',
  'api',
  'premiumVoices',
  'speech',
  'chatGpt',
  'createAvatar'
] as const;

export type CreditStore = CreditState & CreditActions;

export const creditStore = create<CreditStore>()((set) => ({
  toggleUpgradeModal: () => {},
  toggleBuyCreditsModal: () => {},
  toggleFeatureBlockModal: (open) => {},
  isChatAvailable: true,
  premiumVoicesAllowed: false,
  productName: '',
  updateCreditStore: (key, value) => {
    set({ [key]: value });
  }
}));
