import Lottie from 'lottie-react';
import mainLoaderAnimation from './lottie/main-loader.json';
import btnLoaderAnimation from './lottie/btn-loader.json';
import loaderAnimation from './lottie/loader.json';

type Props = {
  size?: number;
  isButton?: boolean;
};

export const Loader = ({ size = 20, isButton = false }: Props) => {
  return (
    <Lottie
      animationData={isButton ? btnLoaderAnimation : loaderAnimation}
      style={{ width: size + 'px' }}
    />
  );
};
