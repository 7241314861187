import { FormikErrors, FormikProps } from 'formik';
import { ITavusPersonaFormik } from 'entities/tavus/persona';
import { AiModelItem, aiModels } from 'entities/tavus/ai-model';

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  onChange: (
    value: string
  ) => Promise<void> | Promise<FormikErrors<ITavusPersonaFormik>>;
};

export const TavusChooseModel = ({ formik, onChange }: Props) => {
  return (
    <div>
      <div className="ag-text-neutral-100">Engine</div>
      <div className="ag-text-neutral-400 ag-text-sm ag-mb-4">
        Select an model for your video agent
      </div>
      <div className="ag-space-y-2">
        {aiModels.map((e) => (
          <AiModelItem
            key={e.name}
            {...e}
            isActive={e.value === formik.values.model}
            onChange={() => onChange(e.value)}
          />
        ))}
      </div>
    </div>
  );
};
