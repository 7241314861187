import { useEffect, useRef, useState } from 'react';
import { ConnectionState, RoomEvent } from 'livekit-client';

import { capitalize, cn } from 'shared';

import { videoCallStore, useBotTrack } from 'features/livekit-call';

export const EmbedStatusConnection = ({
  isSubscribed
}: {
  isSubscribed: boolean;
}) => {
  const [connectedShown, setConnectedShown] = useState(false);
  const { isVideoStarted } = videoCallStore();

  const botTrack = useBotTrack({
    updateOnlyOn: [RoomEvent.ConnectionStateChanged],
    onlySubscribed: false
  });

  const botConnection =
    (botTrack && isSubscribed && ConnectionState.Connected) ||
    (isVideoStarted
      ? ConnectionState.Disconnected
      : ConnectionState.Connecting + '...');

  useEffect(() => {
    // Cosmetic stuff (show connected message for 4 seconds after connection established)
    if (isSubscribed) {
      setConnectedShown(true);
      setTimeout(() => {
        setConnectedShown(false);
      }, 4000);
    }
  }, [isSubscribed]);

  return (
    <div
      className={cn(
        'ag-group ag-absolute ag-top-4 ag-right-4 ag-py-1 ag-px-2 ag-border ag-border-neutral-600 ag-rounded-lg ag-bg-black/60 ag-text-white ag-text-xs ag-z-50 ag-flex ag-items-center ag-gap-2'
      )}
    >
      <span
        className={cn(
          botConnection !== ConnectionState.Connected && 'ag-animate-pulse',
          botConnection === ConnectionState.Connected &&
            'ag-hidden group-hover:ag-block',
          botConnection === ConnectionState.Connected &&
            connectedShown &&
            '!ag-block'
        )}
      >
        {capitalize(botConnection)}
      </span>
      <div
        className={cn(
          'ag-size-2 ag-rounded-full ',
          botConnection === ConnectionState.Connected
            ? 'ag-bg-green-600 ag-my-1'
            : 'ag-bg-white ag-animate-pulse'
        )}
      ></div>
    </div>
  );
};
