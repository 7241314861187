import { useDataChannel } from '@livekit/components-react';
import { useCallback, useEffect, useState } from 'react';

import type { ReceivedDataMessage } from '@livekit/components-core';
import { ErrorPacket, Packet, PacketType } from 'shared';

export const ErrorMessage = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onData = useCallback((message: ReceivedDataMessage) => {
    const decoder = new TextDecoder();
    const packet = JSON.parse(decoder.decode(message.payload)) as Packet;
    if (packet.type === PacketType.Error) {
      const errorPacket = packet.data as ErrorPacket;
      setError(errorPacket.message);
    }
  }, []);

  useDataChannel('', onData);

  useEffect(() => {
    if (!error) return;

    setVisible(true);
    const timeout = setTimeout(() => {
      setError('');
      setVisible(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, [error]);

  return visible ? (
    <div className="fixed left-1/2 text-center px-1 top-4 rounded bg-[#A52A2A] transform -translate-x-1/2">
      {error}
    </div>
  ) : (
    <> </>
  );
};
