import { computed } from 'zustand-middleware-computed-state';
import { create, StoreApi } from 'zustand';
import { AxiosError, isAxiosError } from 'axios';

import { authService } from '../services/auth.service';
import { userService } from '../services/user.service';

import {
  showError,
  showSuccessMessage,
  EXPIRED_SESSION_ERROR,
  getErrorMessage,
  getCookie,
  setCookie,
  IUser,
  ENVIRONMENT
} from 'shared';

type EmailWithField<field extends string> = Record<'email' | field, string>;

interface Store {
  user: null | IUser;
  onboarded: boolean;
  isOnboardingDisabled: boolean;
  isLoaded: boolean;
  isSuspended: boolean;
  isAuthLoad: boolean;
  authModal: boolean;
  noCreditsModal: boolean;
  apiKey: string | undefined;
  agentKey: string | undefined;
}

export interface UserStoreAction {
  checkIsSuspended: (email: string) => Promise<void>;
  login: (credentials: EmailWithField<'password'>) => Promise<void>;
  logout: () => Promise<void>;
  register: (
    user: Omit<IUser, 'id' | 'apiKey' | 'trialUser'> & {
      surname: string;
      password: string;
      phoneNumber: string;
    }
  ) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (querystring: string, password: string) => Promise<void>;
  changePassword: (passwords: {
    currentPassword: string;
    newPassword: string;
  }) => Promise<void>;
  regenerateApiKey: () => Promise<void>;
  me: () => Promise<void>;
  refreshToken: () => Promise<void>;
  loginViaToken: () => Promise<void>;
  verifyEmail: (query: string, email: string) => Promise<void>;
  resendVerificationEmail: (email: string) => Promise<void>;
  changeEmail: (data: { email: string }) => Promise<void>;
  updateOrganizationName: (organizationName: {
    organizationName: string;
  }) => Promise<void>;
  setAuthModal: (state: boolean) => void;
  setNoCreditsModal: (state: boolean) => void;
  toggleCreateAccModal: () => void;
  updateAuthStore: (key: string, value: any) => void;
}

interface ComputedStore {
  isAuth: boolean;
}

export const useAuthStore = create<Store & UserStoreAction & ComputedStore>(
  computed<Store & UserStoreAction, ComputedStore>(
    (
      set: StoreApi<Store & UserStoreAction>['setState'],
      get: StoreApi<Store & UserStoreAction>['getState']
    ) =>
      ({
        toggleCreateAccModal: () => {},
        updateAuthStore: (key, value) => {
          set({ [key]: value });
        },
        user: null,
        isLoaded: false,
        onboarded: false,
        isOnboardingDisabled: false,
        authModal: false,
        noCreditsModal: false,
        isAuthLoad: false,
        apiKey: undefined,
        agentKey: undefined,
        setAuthModal: (value) => {
          set({ authModal: value });
        },
        setNoCreditsModal: (value) => {
          set({ noCreditsModal: value });
        },
        me: async () => {
          const user = await userService.me();
          // TODO: Fix suspension
          // const isSuspended = await authService.checkSuspension(user.email);
          set({
            user
          });
        },
        isSuspended: false,
        checkIsSuspended: async (email) => {
          const isSuspended = await authService.checkSuspension(email);
        },
        login: async (credentials) => {
          set({ isAuthLoad: true });
          await authService.login(credentials);
          await get().me();
          set({
            isLoaded: true,
            isAuthLoad: false
          });
        },
        loginViaToken: async () => {
          // try {
          //   if (!getCookie(`${ENVIRONMENT}_yepic_refresh_token`)) return;
          //   await get().me();
          // } catch (e) {
          //   if (e instanceof AxiosError && e.message === EXPIRED_SESSION_ERROR)
          //     return;
          //   showError(getErrorMessage(e));
          // } finally {
          //   set({
          //     isLoaded: true
          //   });
          // }
          if (!getCookie(`${ENVIRONMENT}_yepic_access_token`)) return;
          set({
            isLoaded: true
          });
        },
        logout: async () => {
          set({ isAuthLoad: true });

          try {
            await authService.logout();
            setCookie(`${ENVIRONMENT}_yepic_access_token`, '', 0);
            setCookie(`${ENVIRONMENT}_yepic_refresh_token`, '', 0);
            set({
              user: null
            });
          } catch (e) {
            const message = getErrorMessage(
              e,
              'Unable to logout. Please try again later',
              'Unable to logout. Please try again later. Error: '
            );
            showError(message);
          } finally {
            set({ isAuthLoad: false });
          }
        },
        register: async (userData) => {
          await authService.register(userData);
        },
        forgotPassword: async (email) => {
          await authService.forgotPassword(email);
        },
        changePassword: async (newPassword) => {
          await authService.changePassword(newPassword);
        },
        resetPassword: async (querystring, password) => {
          await authService.resetPassword(querystring, password);
        },
        regenerateApiKey: async () => {
          await userService.regenerateApiKey();
          await get().me();
        },
        refreshToken: async () => {
          await authService.refreshToken();
        },
        changeEmail: async (email) => {
          await authService.changeEmail(email);
        },
        verifyEmail: async (query, email) => {
          try {
            await authService.verifyEmail(query);
            showSuccessMessage(`${email} verified`);
            if (get().user !== null) await get().me();
          } catch (e) {
            console.error({ e });
            if (isAxiosError(e)) {
              showError(
                `There was a problem when we tried to verify your email, error: ${
                  e.response?.data?.message ?? e.response?.data.errorCode
                }`
              );

              // if (e.response?.data.errorCode === 'VerificationFailed') {
              //   get().resendVerificationEmail(email);
              // }
            }
          }
        },
        resendVerificationEmail: async (email) => {
          try {
            await authService.resendVerificationEmail(email);
            showSuccessMessage(
              `We have sent a new verification link to ${email}`
            );
          } catch (e) {
            console.error({ e });
            if (isAxiosError(e)) {
              showError(
                `There was a problem when we tried to resend verification email, error message: ${e.response?.data.message}`
              );
            }
          }
        },
        updateOrganizationName: async (data) => {
          const user = get().user;
          if (!user) return;
          await authService.updateOrganizationName(data);
          set({
            user: {
              ...user,
              organizationName: data.organizationName
            }
          });
        }
      } as Store & UserStoreAction),
    (state) => ({
      isAuth: getCookie(`${ENVIRONMENT}_yepic_access_token`) !== null
    })
  )
);
