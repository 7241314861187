import {
  FEATURE_FLAG_EMBED,
  FEATURE_FLAG_SHARE,
  FEATURE_FLAG_STOP_TALK
} from './config';

export const featureFlags = {
  stopTalking: FEATURE_FLAG_STOP_TALK === 'true',
  embed: FEATURE_FLAG_EMBED === 'true',
  share: FEATURE_FLAG_SHARE === 'true'
};
