import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  LayoutContextProvider,
  RoomAudioRenderer,
  TrackRefContext,
  useCreateLayoutContext
} from '@livekit/components-react';
import { LogLevel, RoomEvent } from 'livekit-client';

import { cn, useDebugMode, featureFlags, ENVIRONMENT, Loader } from 'shared';
import {
  useBotTrack,
  videoCallStore,
  EmbedParticipantTile
} from 'features/livekit-call';
import { UserVideo } from 'features/recognize-emotions';

import { EmbedControlBar, StopTalkingBtn } from 'widgets/call-controls';
import { EmbedStatusConnection } from 'widgets/connection-status';
import { Captions } from 'widgets/captions';

export const EmbedConference = () => {
  const {
    isConnected,
    isVideoStarted,
    toggleIsConnected,
    getLivekitToken,
    persona,
    setVideoStarted
  } = videoCallStore();

  useEffect(
    () => () => {
      setVideoStarted(false);
    },
    []
  );

  const connectionFallbackRef = useRef<NodeJS.Timeout>();

  const track = useBotTrack({
    updateOnlyOn: [RoomEvent.ActiveSpeakersChanged]
  });

  const webcamRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if ((isConnected && !track) || (!isConnected && track)) toggleIsConnected();
  }, [track, isConnected]);

  const layoutContext = useCreateLayoutContext();

  useDebugMode({
    logLevel:
      ENVIRONMENT === 'development' || ENVIRONMENT === 'local'
        ? LogLevel.debug
        : LogLevel.error
  });

  useEffect(() => {
    if (!connectionFallbackRef.current && !isVideoStarted) {
      connectionFallbackRef.current = setTimeout(() => {
        const { isConnected, isVideoStarted } = videoCallStore.getState();
        if (isConnected && !isVideoStarted && persona)
          getLivekitToken(persona.id);
      }, 120 * 1000);
    }
    if (isVideoStarted && connectionFallbackRef.current) {
      clearTimeout(connectionFallbackRef.current);
      connectionFallbackRef.current = undefined;
    }
  }, [isConnected, isVideoStarted]);

  const [isSubscribed, setIsSubscribed] = React.useState(false);

  const isSubscribedRef = useRef(isSubscribed);
  isSubscribedRef.current = isSubscribed;

  const handleSubscribe = React.useCallback(
    (subscribed: boolean) => {
      if (subscribed) {
        toast.dismiss('livekit-connection');
        toast.clearWaitingQueue({
          containerId: 'livekit-connection'
        });
        if (!isSubscribedRef.current) setIsSubscribed(subscribed);
      }
    },
    [track, layoutContext]
  );

  useEffect(() => {
    if (isSubscribed) {
      setVideoStarted(isSubscribed);
    }
  }, [isSubscribed]);

  return (
    <>
      <LayoutContextProvider value={layoutContext}>
        <TrackRefContext.Provider value={track}>
          <div className="ag-flex ag-flex-col ag-items-center ag-justify-between ag-w-full ag-overflow-hidden ag-relative ag-h-full">
            <EmbedStatusConnection isSubscribed={isSubscribed} />

            <div className="ag-absolute ag-left-4 ag-top-4 ag-z-30">
              <UserVideo ref={webcamRef} />
            </div>

            <div className="ag-w-full ag-h-full ag-relative">
              {/* <video
                src="https://videos.pexels.com/video-files/3571264/3571264-hd_1280_720_30fps.mp4"
                autoPlay
                loop
                className="ag-w-full ag-h-full ag-mx-auto ag-object-contain"
                preload="auto"
              /> */}
              <EmbedParticipantTile
                trackRef={track}
                isSubscribed={isSubscribed}
                handleSubscribe={handleSubscribe}
                className="ag-w-full ag-h-full ag-absolute sm:ag-inset-0 ag-mx-auto ag-object-cover"
              />
              {!isSubscribed && (
                <div className="ag-absolute ag-w-full ag-h-full ag-top-0 ag-left-0 ag-right-0 ag-bottom-0 ag-object-cover ag-z-20 ag-flex ag-items-center ag-justify-center">
                  <Loader size={96} />
                </div>
              )}
            </div>
            <div className="ag-w-full ag-text-neutral-900 ag-shrink-0 ag-relative ag-z-20 ">
              <div className="ag-flex ag-items-center ag-justify-between md:ag-justify-center ag-w-full ag-gap-2 ag-absolute ag-bottom-[calc(100%+0.75rem)] ag-px-4">
                <div className="ag-grow md:ag-grow-0">
                  <Captions />
                </div>
                {featureFlags.stopTalking && isSubscribed && (
                  <div className="ag-relative ag-shrink-0 ag-size-10">
                    <StopTalkingBtn />
                  </div>
                )}
              </div>
              <EmbedControlBar
                webcamRef={webcamRef}
                isSubscribed={isSubscribed}
              />
              {/* {isSubscribed && <EmbedControlBar />} */}
            </div>
          </div>
        </TrackRefContext.Provider>
      </LayoutContextProvider>
      {/* <ErrorMessage /> */}
      <RoomAudioRenderer />
    </>
  );
};
