import { httpService } from '../shared/api/http.service';
import { IUser } from '../shared/model/user';

export const userService = {
  me: async () => {
    const { data: userData } = await httpService.get<IUser>('/users/current');

    return userData;
  },
  regenerateApiKey: async () => {
    await httpService.post('/users/generatekey', {});
  }
};
