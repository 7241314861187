import { FC, Fragment, MouseEventHandler, ReactNode } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react';
import { cn } from 'shared/lib';
import { IconType } from 'react-icons';

type DropdownProps = {
  options?: {
    label: string;
    icon?: IconType;
    onClick: MouseEventHandler<HTMLDivElement>;
    isLoading?: boolean;
  }[];
  children?: ReactNode;
  label?: string;
  icon: ReactNode;
  buttonClassName?: string;
  optionClassName?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  className?: string;
};

const Dropdown: FC<DropdownProps> = ({
  options,
  children,
  label,
  icon,
  buttonClassName,
  className,
  optionClassName,
  wrapperClassName,
  labelClassName
}) => {
  return (
    <Menu as="div" className={cn('ag-relative ag-w-full', wrapperClassName)}>
      {({ close }) => (
        <>
          <MenuButton
            className={cn(
              `ag-text-white ag-gap-2 ag-flex ag-justify-center ag-border ag-rounded-lg ag-border-neutral-600`,
              buttonClassName
            )}
          >
            {label !== undefined && (
              <span
                className={cn(
                  'ag-w-full ag-text-sm ag-font-semibold',
                  labelClassName
                )}
              >
                {label}
              </span>
            )}
            {icon}
          </MenuButton>

          <Transition
            enter="ag-transition ag-duration-100 ag-ease-out"
            enterFrom="ag-transform ag-scale-95 ag-opacity-0"
            enterTo="ag-transform ag-scale-100 ag-opacity-100"
            leave="ag-transition ag-duration-75 ag-ease-out"
            leaveFrom="ag-transform ag-scale-100 ag-opacity-100"
            leaveTo="ag-transform ag-scale-95 ag-opacity-0"
          >
            <MenuItems
              className={cn(
                `ag-absolute ag-top-9 ag-right-0 ag-z-10 ag-mt-0.5 ag-w-full ag-rounded-md ag-shadow-lg ag-border ag-bg-neutral-800 ag-border-neutral-600`,
                className
              )}
              as="div"
            >
              {options?.map((option, index) => (
                <MenuItem
                  key={index}
                  as="div"
                  onClick={option.onClick}
                  className={cn(
                    'ag-px-4 ag-py-3 ag-flex ag-items-center ag-text-white ag-fill-white hover:ag-bg-neutral-700 ag-rounded-md ag-cursor-pointer',
                    optionClassName
                  )}
                >
                  <>
                    {option.icon !== undefined && (
                      <option.icon className="ag-min-w-[1rem] ag-size-4" />
                    )}
                    <span
                      className={cn(
                        'ag-font-medium ag-text-sm',
                        option.icon ? 'ag-ml-2' : ''
                      )}
                    >
                      {option.label}
                    </span>
                  </>
                </MenuItem>
              ))}
              {children}
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default Dropdown;
