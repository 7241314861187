import { FC, useEffect, useMemo, useState } from 'react';
import { RoomOptions, VideoPresets } from 'livekit-client';

import { USER_CHOICES } from 'shared';

import { LiveKitRoom, videoCallStore } from 'features/livekit-call';

import { EventConference } from './EventConference';

const EventView2 = () => {
  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        red: false,
        dtx: false,
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216]
      },
      audioCaptureDefaults: {
        deviceId: USER_CHOICES.audioDeviceId ?? undefined
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true
    };
  }, [USER_CHOICES]);

  return (
    <LiveKitRoom
      token={
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjM1Mjk1MTk3NTAsImlzcyI6IkFQSWljSHl4akpDUzNTRyIsIm5iZiI6MTcyNjEzMTk2Niwic3ViIjoiTWFyayIsInZpZGVvIjp7ImNhblB1Ymxpc2giOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsInJvb20iOiJHQUlOLWRldiIsInJvb21Kb2luIjp0cnVlfX0.hpNVFVhT5H2smFrG7JLuu8TzYvKJ1pi4P0-Us9f6OQE'
      }
      serverUrl={'wss://chatgptme-sp76gr03.livekit.cloud/'}
      options={roomOptions}
      video={USER_CHOICES.videoEnabled}
      audio={USER_CHOICES.audioEnabled}
    >
      <EventConference />
    </LiveKitRoom>
  );
};

export default EventView2;
