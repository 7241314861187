import { FC, useEffect, useRef, useState } from 'react';
import avatarReadyData from './data/avatarReadyData.json';
import dateData from './data/dateData.json';
import divingData from './data/divingData.json';
import skydivingData from './data/skydivingData.json';
import Lottie from 'lottie-react';

import { cn, Loader } from 'shared';
import { videoCallStore } from 'features/livekit-call';

type AnimationType = 'avatarReady' | 'on a date' | 'diving' | 'skydiving';
// const animationTypes: AnimationType[] = ['on a date', 'diving', 'skydiving'];
const animationTypes: AnimationType[] = ['diving', 'skydiving'];

const getRandomAnimation = () =>
  animationTypes[Math.floor(Math.random() * animationTypes.length)];

const getAnimationData = (type: AnimationType) => {
  switch (type) {
    case 'avatarReady':
      return avatarReadyData;
    case 'on a date':
      return dateData;
    case 'diving':
      return divingData;
    case 'skydiving':
      return skydivingData;
    default:
      return dateData;
  }
};

export const LoadingAnimation: FC<{
  isLoaded: boolean;
  estimateTime: number;
  onAnimationLoaded: () => void;
  isEmbed?: boolean;
}> = ({ isLoaded, estimateTime, onAnimationLoaded, isEmbed = false }) => {
  const progressRef = useRef<number>(1);
  const progressDivRef = useRef<HTMLDivElement | null>(null);
  const animationInterval = useRef<NodeJS.Timeout>();
  const [animation, setAnimation] = useState<AnimationType>(
    getRandomAnimation()
  );

  const { persona } = videoCallStore();

  const updateProgress = (progress: number) => {
    progressRef.current = progress;
    if (progressDivRef.current)
      progressDivRef.current.style.width = `${progress}%`;
  };

  useEffect(() => {
    setAnimation(getRandomAnimation());
    if (!isLoaded) {
      animationInterval.current = setInterval(() => {
        if (progressRef.current < 85) {
          const newProgress = Math.min(
            progressRef.current +
              (60 * Math.floor(Math.random() * 100 + 50)) /
                (estimateTime * 400),
            90
          );
          updateProgress(newProgress);
        } else {
          const newProgress = Math.min(
            progressRef.current + (100 - progressRef.current) / 20,
            95
          );
          updateProgress(newProgress);
        }

        if (progressRef.current > 95) {
          clearInterval(animationInterval.current);
        }
      }, 250);
    } else {
      updateProgress(100);
      setTimeout(onAnimationLoaded, 500);
    }

    return () => clearInterval(animationInterval.current);
  }, [isLoaded]);

  const name = persona?.name || 'Lisa';

  return (
    <div
      className={cn(
        'ag-h-full ag-flex ag-justify-center ag-items-center ag-pb-5 ag-flex-col',
        isEmbed && 'ag-pt-16'
      )}
    >
      <div className="ag-h-2/3 ag-mt-10 ag-mx-4">
        <Lottie
          autoplay
          loop
          animationData={getAnimationData(animation)}
          className="hidden sm:ag-h-4/5"
        />
        <div className="ag-flex ag-items-center ag-justify-center h-full sm:ag-h-1/5">
          <Loader size={50} />
        </div>
        {/* <div className="mx-[7%] ag-h-2 bg-neutral-800 ag-rounded-3xl ag-z-10 ag-relative">
          
          {/* <div
            className="ag-h-2 bg-secondary shadow-[0_0_0.134px_0_#C34DFF,0_0_0.268px_0_#C34DFF,0_0_0.936px_0_#C34DFF,0_0_1.873px_0_#C34DFF,0_0_3.211px_0_#C34DFF,0_0_5.619px_0_#C34DFF] ag-rounded-3xl ag-transition-all ag-ease-in ag-duration-300 ag-w-0"
            ref={progressDivRef}
          /> 
        </div> */}
      </div>
      <div className="ag-h-1/3 ag-flex ag-flex-col ag-items-center ag-justify-between ag-mt-6 ag-text-center ag-mx-4">
        <div>
          <div className="ag-text-white ag-text-xl">
            {animation !== 'avatarReady'
              ? `${name} is ${animation} right now.`
              : `Oh, ${name} is back`}
          </div>
          {animation !== 'avatarReady' && (
            <span className="ag-mt-1 ag-text-sm ag-text-neutral-300 ag-mb-10">
              We are just trying to get a hold of them, please wait...
            </span>
          )}
        </div>
        {/* <span className="ag-align-self-end ag-text-xs ag-text-center ag-text-neutral-300 ag-max-w-2xl ag-px-4">
          This is a research preview and requires a strong consistent internet
          connection. If you have high latency, the audio and video may look out
          of sync. The stream should adapt, but with latency above 100ms this is
          not possible. We do not recommend using over conference wifi.
        </span> */}
      </div>
    </div>
  );
};
