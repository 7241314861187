import React, { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { CustomPhotoDetails, GenderOptions } from '../types/types';
import SelectInput from '../components/SelectInput';

// import SelectInput from '../../../common/SelectInput';

type Step2Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  uploadedPhoto: string;
  onUpdateDetails: (details: CustomPhotoDetails) => void;
  uploadedPhotoDetails: CustomPhotoDetails;
  hideBackButton?: boolean;
};

const Step2: React.FC<Step2Props> = ({
  onSetStep,
  uploadedPhoto,
  onUpdateDetails,
  uploadedPhotoDetails,
  hideBackButton = 'false'
}) => {
  return (
    <div className="text-left">
      <h1 className="text-lg font-semibold mb-2">Talking Picture details</h1>
      <p className="text-neutral-400 mb-4 sm:mb-8">
        Please enter your Talking Picture details
      </p>
      <Formik
        initialValues={uploadedPhotoDetails}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter name'),
          age: Yup.number()
            .positive()
            .integer()
            .required('Please enter age')
            .nullable()
            .typeError('Age must be number'),
          gender: Yup.string()
            .required('Please select gender')
            .oneOf(['male', 'female', 'other'])
            .typeError('Please choose gender')
        })}
        onSubmit={(values) => {
          onSetStep((prevState) => prevState + 1);
          onUpdateDetails(values);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col sm:flex-row">
              <div className="border border-gray-100 rounded w-8/12 mx-auto sm:w-5/12 sm:mr-4 overflow-hidden mb-6 sm:mb-0">
                <img
                  src={uploadedPhoto}
                  alt="Uploaded photo"
                  className="w-full h-full object-contain"
                />
              </div>

              <div className="sm:w-7/12">
                <div className="mb-2 text-sm relative">
                  <label htmlFor="name" className="mb-1.5 block">
                    Name
                  </label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    className={`block rounded-lg w-full ${
                      formik.touched.name && formik.errors.name
                        ? 'border-red-500'
                        : 'border-gray-300'
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg: string) => (
                      <div className="absolute text-red-500 text-xs sm:mt-1 text-right right-0  w-full">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                <div className="mb-2 text-sm relative">
                  <label htmlFor="age" className="mb-1.5 block">
                    Age
                  </label>
                  <Field
                    id="age"
                    name="age"
                    type="text"
                    className={`block rounded-lg w-full ${
                      formik.touched.age && formik.errors.age
                        ? 'border-red-500'
                        : 'border-gray-300'
                    }`}
                  />
                  <ErrorMessage
                    name="age"
                    render={(msg: string) => (
                      <div className="absolute text-red-500 text-xs sm:mt-1 text-right right-0 w-full">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                <div className="mb-2 text-sm relative">
                  <label htmlFor="gender" className="mb-1.5 block">
                    Gender
                  </label>
                  <SelectInput
                    name="gender"
                    options={['male', 'female', 'other']}
                    value={formik.values.gender}
                    onChange={(option: GenderOptions) =>
                      formik.setFieldValue('gender', option)
                    }
                    placeholder="Select gender..."
                    error={formik.touched.gender && formik.errors.gender}
                    borderColorClass="border-gray-300"
                  />
                  <ErrorMessage
                    name="gender"
                    render={(msg: string) => (
                      <div className="absolute text-red-500 text-xs mt-1 text-right right-0">
                        {msg}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className="absolute flex flex-col-reverse sm:flex-row gap-2 mt-4 justify-between items-center 
            bottom-0 left-0 sm:left-6 sm:right-4 sm:bottom-4 bg-white z-20 sm:z-auto w-full sm:w-auto py-4 sm:py-0"
            >
              <button
                onClick={() => onSetStep((prevState) => prevState - 1)}
                className={`bg-white border border-black  text-sm font-semibold rounded py-2.5 px-8 sm:px-16 min-w-[180px] ${
                  hideBackButton ? 'invisible' : ''
                }`}
              >
                Back
              </button>
              <button
                type="submit"
                className="bg-secondary border border-secondary text-white text-sm font-semibold rounded py-2.5 px-8 sm:px-16 min-w-[180px]"
              >
                Next
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Step2;
