import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { IAvatar, MAIN_ROUTE } from 'shared/model';
import { FilledButton, Loader, OutlinedButton } from 'shared/ui';
import { BiX } from 'react-icons/bi';
import { EditPersonaSidebar } from './EditPersonaSidebar';
import {
  ICreatePersonaReq,
  IPersona,
  IPersonaFormik,
  personaStore
} from 'entities/persona';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const EditPersonaLayout: FC<
  PropsWithChildren<{ persona: IPersona }>
> = ({ persona }) => {
  const [avatar, setAvatar] = useState<{
    menuUrl: string;
    idleLoopUrl: string;
    inputImageUrl?: string;
    gender: IAvatar['gender'];
  }>({
    menuUrl: '',
    idleLoopUrl: persona.avatar.idleLoopVideoUrl,
    inputImageUrl: persona.avatar.inputImageUrl,
    gender: persona.avatar.gender
  });

  const nav = useNavigate();

  const { addPersona, updatePersona, isPersonaCreating } = personaStore();

  const onSubmit = async (values: ICreatePersonaReq) => {
    const submitValues = { ...values };
    if (
      submitValues.knowledgeSources &&
      submitValues.knowledgeSources.length > 0 &&
      submitValues.knowledgeSources[0].type === 'Ungrounded'
    ) {
      submitValues.knowledgeSources = [];
    }
    if (submitValues.name === '') {
      submitValues.name = 'Video Agent Name';
    }
    if (persona.visibility !== 'Public') {
      await updatePersona(persona, submitValues);
    } else {
      await addPersona(submitValues);
    }
    nav(MAIN_ROUTE);
  };

  const formik = useFormik<IPersonaFormik>({
    initialValues: {
      name: persona.name,
      avatarId: persona.avatar.id,
      voice: persona.voice,
      description: persona.description,
      visibility: persona.visibility,
      definition: persona.definition,
      greeting: persona.greeting,
      starterQuestions: persona.starterQuestions ?? [],
      callToActions: persona.callToActions ?? [],
      knowledgeSources:
        persona.knowledgeSources && persona.knowledgeSources?.length > 0
          ? persona.knowledgeSources
          : [],
      introMessages: persona.introMessages ?? [],
      model: persona.model || 'gpt-4o'
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      description: Yup.string()
        .required('Please enter persona description')
        .max(100, 'Please make description shorter')
    }),
    onSubmit
  });
  const isDisabled = !formik.dirty;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="ag-bg-neutral-800 ag-grid ag-grid-rows-[auto,1fr,4.5rem] lg:ag-grid-rows-[4rem,calc(100vh-4rem)] lg:ag-grid-cols-[556px,calc(100vw-556px)] ag-h-screen ag-w-full"
    >
      <div className="lg:ag-col-span-2 ag-h-14 lg:ag-h-16 bg-neutral-800 ag-border-b ag-border-neutral-600 ag-font-semibold ag-text-white ag-flex ag-justify-between ag-items-center ag-py-4 lg:ag-py-3 ag-px-4 lg:ag-px-6">
        <Link to={MAIN_ROUTE} className="ag-items-center ag-hidden lg:ag-flex">
          <GoArrowLeft className="ag-w-6 ag-h-6 ag-mr-4" />
          Edit video agent
        </Link>
        <FilledButton
          className="ag-h-full ag-px-6 ag-hidden lg:ag-flex disabled:ag-bg-primary-600 disabled:ag-opacity-20 ag-w-36"
          disabled={isDisabled}
          type="submit"
        >
          {isPersonaCreating ? <Loader isButton /> : 'Save changes'}
        </FilledButton>
        <h1 className="ag-font-semibold lg:ag-hidden">Edit Video Agent</h1>
        <Link to={MAIN_ROUTE} className="ag-p-1 lg:ag-hidden">
          <BiX className="ag-w-5 ag-h-5" />
        </Link>
      </div>
      <EditPersonaSidebar
        setAvatarUrl={setAvatar}
        formik={formik}
        persona={persona}
        avatarGender={avatar.gender}
      />
      <div className="ag-w-full ag-h-full ag-hidden lg:ag-flex ag-items-center ag-justify-center ag-bg-neutral-900">
        <div className="ag-py-11 ag-px-20 ag-aspect-square ag-h-[min(calc(100vh-4rem),calc(100vw-556px))]">
          <video
            src={avatar.idleLoopUrl}
            className="ag-rounded-sm ag-border ag-border-neutral-600 ag-w-full ag-object-contain ag-aspect-square"
            autoPlay
            muted
            loop
            poster={avatar.inputImageUrl}
          />
        </div>
      </div>
      <div className="ag-p-4 ag-border-t ag-border-neutral-600 ag-bg-neutral-800 ag-flex ag-gap-4 lg:ag-hidden">
        <Link to={MAIN_ROUTE} className="ag-w-full">
          <OutlinedButton className="ag-h-full ag-w-full ag-bg-neutral-800 ag-text-white">
            Cancel
          </OutlinedButton>
        </Link>
        <FilledButton
          className="ag-h-full ag-w-full disabled:ag-bg-primary-600 disabled:ag-ag-opacity-20"
          disabled={isDisabled}
          type="submit"
        >
          {isPersonaCreating ? <Loader isButton /> : 'Save changes'}
        </FilledButton>
      </div>
    </form>
  );
};
