import App, { AppProps } from 'app/App';
import { BrowserRouter } from 'react-router-dom';

export default (props: AppProps) => (
  <BrowserRouter>
    <App
      toggleUpgradeModal={props.toggleUpgradeModal}
      toggleBuyCreditsModal={props.toggleBuyCreditsModal}
      toggleFeatureBlockModal={props.toggleFeatureBlockModal}
      updateCallStore={props.updateCallStore}
      toggleUploadAvatar={props.toggleUploadAvatar}
      talkingPhotos={props.talkingPhotos}
      customTalkingPhotos={props.customTalkingPhotos}
      toggleCreateAccModal={props.toggleCreateAccModal}
      isChatAvailable={props.isChatAvailable}
      productName={props.productName}
      premiumVoicesAllowed={props.premiumVoicesAllowed}
      apiKey={props.apiKey}
      agentKey={props.agentKey}
    />
  </BrowserRouter>
);
