import { create } from 'zustand';
import { assetApi } from '../api';
import { getErrorMessageV2 } from 'shared/lib';
import { showError } from 'shared/model';
import { IAsset } from './assetTypes';

type Store = {
  isUploading: boolean;
};

type Action = {
  uploadFile: (formData: FormData) => Promise<IAsset | undefined>;
};

export const assetStore = create<Store & Action>((set, get) => ({
  isUploading: false,
  uploadFile: async (formData) => {
    set({ isUploading: true });

    try {
      const asset = await assetApi.uploadFile(formData);

      return asset;
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to upload your file'
      );
      showError(message);
    } finally {
      set({ isUploading: false });
    }
  }
}));
