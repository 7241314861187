import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import { ListReplicas, Replica } from '../model';

const replicasEndpoint = '/replicas';

export const replicaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<Replica> = await tavusHttpService.get(
      `${replicasEndpoint}/${id}`
    );

    return data;
  },
  fetchAll: async () => {
    const { data }: AxiosResponse<ListReplicas> = await tavusHttpService.get(
      replicasEndpoint
    );

    return data.data;
  }
};
