import clsx from 'clsx';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_ROUTE } from '../../../../shared';
import { IoMdClose } from 'react-icons/io';
import { tavusSteps } from '../../../../widgets/tavus-create-persona';

type Props = {
  onStepChange: (step: number) => void;
  currentStep: number;
};

export const TavusTopBar: FC<Props> = ({ currentStep, onStepChange }) => {
  return (
    <div className="ag-w-full ag-border-b ag-bg-neutral-800 ag-text-neutral-500 ag-border-neutral-700 ag-sticky ag-top-0 ag-left-0 ag-z-[51]">
      <div className="ag-flex ag-items-center md:ag-justify-center ag-border-b ag-flex-wrap ag-border-neutral-800 ag-relative">
        {tavusSteps.map((s, _, steps) => (
          <div
            key={`${s.name}-${s.num}`}
            onClick={() => onStepChange(s.num)}
            className={clsx(
              'ag-border-b-2 ag-p-2 md:ag-pb-3 md:ag-pt-6 -ag-mb-[2px] ag-px-6 md:ag-px-8 lg:ag-px-12 ag-transition-all ag-duration-300',
              currentStep === s.num
                ? 'ag-border-transparent md:ag-border-neutral-100 ag-text-neutral-100'
                : 'ag-border-neutral-800 ag-hidden md:ag-block xl:hover:ag-border-neutral-100  xl:hover:ag-text-neutral-100 ag-cursor-pointer'
            )}
          >
            <div>{s.name}</div>
            <div
              className={clsx('md:ag-hidden ag-text-neutral-300 ag-text-sm')}
            >
              Step 0{s.num}/0{steps.length}
            </div>
          </div>
        ))}
      </div>
      <Link
        to={MAIN_ROUTE}
        className="ag-absolute md:ag-my-auto ag-top-4 ag-right-2 md:ag-right-4 ag-cursor-pointer ag-z-50"
      >
        <IoMdClose className="ag-text-3xl ag-text-neutral-100 " />
      </Link>
    </div>
  );
};
