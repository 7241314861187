import { AiOutlineOpenAI } from 'react-icons/ai';

export const engines = [
  {
    value: 'gpt-3.5-turbo-0125',
    name: 'GPT-3.5 Turbo',
    provider: 'Open AI',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'Efficient and reliable responses with good general knowledge, perfect for diverse topics.'
  },
  {
    value: 'gpt-4-turbo',
    name: 'GPT-4 Turbo',
    provider: 'Open AI',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'Fast and precise responses, suitable for detailed and engaging conversations.'
  },
  {
    value: 'gpt-4o-mini',
    name: 'GPT-4o mini',
    provider: 'Open AI',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'Quick responses with basic understanding, ideal for simple queries.'
  },
  {
    value: 'gpt-4o',
    name: 'GPT-4o',
    provider: 'Open AI',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'Balanced performance with enhanced comprehension for moderate interactions.'
  },
  {
    value: 'gpt-4',
    name: 'GPT-4',
    provider: 'Open AI',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'Highly accurate and nuanced responses, best for complex and in-depth interactions.'
  }
];
