import { useMorph } from 'features/recognize-emotions';
import { useExternalScript } from 'shared/lib';
import { useEffect, useRef } from 'react';

export const useWebcam = () => {
  const { start, stop, isWebcam, loading: webcamLoading, load } = useMorph();
  const mphToolsState = useExternalScript(
    'https://sdk.morphcast.com/mphtools/v1.0/mphtools.js'
  );
  const aiSdkState = useExternalScript(
    'https://ai-sdk.morphcast.com/v1.16/ai-sdk.js'
  );
  useEffect(() => {
    load(aiSdkState, mphToolsState);
  }, [aiSdkState, mphToolsState]);
  return {
    startWebcam: start,
    stopWebcam: stop,
    isWebcam,
    webcamLoading
  };
};
