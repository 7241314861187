import CustomTalkingPhotoFlow from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/CustomTalkingPhotoFlow';
import Modal from 'components/shared/Modal';
import { avatarStore } from 'entities/avatar';

export const UploadCustomPhoto = () => {
  const { isUploadAvatarOpen, toggleUploadAvatar } = avatarStore();
  return (
    <Modal open={isUploadAvatarOpen} setOpen={toggleUploadAvatar} noPadding>
      <CustomTalkingPhotoFlow onClose={toggleUploadAvatar} />
    </Modal>
  );
};
