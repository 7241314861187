import { AxiosResponse } from 'axios';
import {
  ICreatePersonaReq,
  IKnowledge,
  IKnowledgeReq,
  IPersona,
  IPersonaList,
  IStarterQuestion
} from '../model';
import { httpService } from 'shared/api';
import { logger, TApiKeys } from 'shared';
import { getDefaultVoiceId } from '../../../stores/useVoiceStore';
import { embedHttpService } from 'shared/api/embedHttp.service';

const personaEndpoint = '/persona';

export const personaApi = {
  fetchAll: async () => {
    const { data }: AxiosResponse<IPersonaList[]> = await httpService.get(
      personaEndpoint
    );

    return data;
  },
  create: async (personaData: ICreatePersonaReq) => {
    const { data }: AxiosResponse<IPersona> = await httpService.post(
      personaEndpoint,
      personaData
    );
    if (data.voice === 'Castle.Proxies.TextToSpeechVoiceProxy')
      data.voice = getDefaultVoiceId(data.avatar?.gender || 'Female');
    return data;
  },
  update: async (
    id: string,
    personaData: Omit<
      ICreatePersonaReq,
      'knowledgeSources' | 'starterQuestions' | 'callToActions'
    >
  ) => {
    const { data }: AxiosResponse<IPersona> = await httpService.patch(
      `${personaEndpoint}/${id}`,
      personaData
    );

    return data;
  },
  delete: async (id: string) => {
    await httpService.delete(`${personaEndpoint}/${id}`);
  },
  getById: async (id: string, keys?: TApiKeys) => {
    const headers = keys
      ? {
          'x-api-key': keys.apiKey,
          'x-agent-key': keys.agentKey
        }
      : {};

    const { data } = await httpService.get(`${personaEndpoint}/${id}`, {
      headers
    });

    return data;
  },
  addStarterQuestion: async (id: string, question: string) => {
    const { data } = await httpService.post<IStarterQuestion>(
      `${personaEndpoint}/${id}/starterquestion`,
      { question }
    );

    return data;
  },
  deleteStartQuestion: async (id: string, questionId: string) => {
    await httpService.delete(
      `${personaEndpoint}/${id}/starterquestion/${questionId}`
    );
  },
  addKnowledgeSource: async (id: string, knowledge: IKnowledgeReq) => {
    return await httpService.post<IKnowledge>(
      `${personaEndpoint}/${id}/knowledgesource`,
      knowledge
    );
  },
  deleteKnowledgeSource: async (id: string, knowledgeId: string) => {
    await httpService.delete(
      `${personaEndpoint}/${id}/knowledgesource/${knowledgeId}`
    );
  }
  // addCallToAction: async (id: string, callToAction: string) => { TODO: callToAction
  //   const { data }: AxiosResponse<IPersona> = await httpService.post(
  //     `${personaEndpoint}/${id}/calltoaction`,
  //     { callToAction }
  //   );
  //
  //   return data;
  // },
  // deleteCallToAction: async (id: string, callToActionId: string) => {
  //   await httpService.delete(
  //     `${personaEndpoint}/${id}/calltoaction/${callToActionId}`
  //   );
  // }
};
