import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from '@headlessui/react';
import { WhereOptions, whereOptions } from '../model';
import { capitalize, cn } from 'shared/lib';
import { FiChevronDown } from 'react-icons/fi';

type Props = {
  where: WhereOptions;
  handleChangeWhere: (where: WhereOptions) => void;
};

export const WhereSelector = ({ where, handleChangeWhere }: Props) => {
  return (
    <div className="ag-relative">
      <Listbox value={where} onChange={handleChangeWhere}>
        <ListboxButton className="ag-text-left ag-w-full ag-border ag-px-4 ag-py-2 ag-rounded-lg ag-flex ag-items-center ag-justify-between">
          <div>{capitalize(where)}</div>
          <FiChevronDown className="ag-size-4" aria-hidden="true" />
        </ListboxButton>
        <Transition
          leave="ag-transition ag-ease-in ag-duration-100"
          leaveFrom="ag-opacity-100"
          leaveTo="ag-opacity-0"
        >
          <ListboxOptions
            className="ag-absolute ag-bg-white ag-top-12 ag-left-0 ag-rounded-lg ag-transform ag-origin-top-left ag-w-full ag-border ag-shadow ag-overflow-hidden"
            as="div"
          >
            {whereOptions.map((where) => (
              <ListboxOption key={where} value={where}>
                {({ focus, selected }) => (
                  <div
                    className={cn(
                      'ag-text-neutral-900 ag-px-4 ag-py-2',
                      selected
                        ? 'ag-bg-neutral-100 ag-cursor-default'
                        : 'ag-cursor-pointer hover:ag-bg-neutral-100'
                    )}
                  >
                    {capitalize(where)}
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  );
};
