import { AiOutlineOpenAI } from 'react-icons/ai';

export const aiModels = [
  {
    value: 'tavus-gpt-4o',
    name: 'GPT-4o',
    provider: 'Tavus',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description: 'The smartest option for complex interactions.'
  },
  {
    value: 'tavus-gpt-4o-mini',
    name: 'GPT-4 mini',
    provider: 'Tavus',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description: 'A hybrid model that balances performance and intelligence'
  },
  {
    value: 'tavus-llama',
    name: 'LLAMA',
    provider: 'Tavus',
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-white" />,
    apiKey: false,
    description:
      'The default choice if no LLM layer is provided. This is the fastest model, offering the best user-to-user (U2U) experience. It’s on-premise, making it incredibly performant.'
  }
];
