import { httpService } from 'shared/api';
import { IUser } from 'shared/model';

export const userApi = {
  me: async () => {
    const { data: userData } = await httpService.get<IUser>('/users/current');

    return userData;
  },
  regenerateApiKey: async () => {
    await httpService.post('/users/generatekey', {});
  }
};
