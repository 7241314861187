import React, { useEffect, useState } from 'react';
import { videoCallStore } from 'features/livekit-call';
import { cn, TMessage } from 'shared';

interface CaptionsProps {
  isChatScreen?: boolean;
}

export const Captions: React.FC<CaptionsProps> = ({ isChatScreen = false }) => {
  const { messages, withCaptions } = videoCallStore();

  const [assistantMessages, setAssistantMessages] = useState<TMessage>();

  const getTheLatestMessage = (messages: TMessage[]) => {
    if (messages.length === 0) setAssistantMessages(undefined);
    const assistantMessages = messages.filter((m) => m.role === 'assistant');
    setAssistantMessages(assistantMessages[assistantMessages.length - 1]);
  };

  useEffect(() => {
    getTheLatestMessage(messages);
  }, [messages]);

  if (!withCaptions || !assistantMessages) return null;

  return (
    <div
      className={cn(
        'ag-rounded-lg ag-bg-black/60 ag-text-white ag-z-50 ag-overflow-hidden',
        isChatScreen
          ? 'ag-text-sm md:ag-text-base md:ag-max-w-md lg:ag-max-w-xl ag-pl-3 ag-pr-2 ag-py-1 ag-static ag-mx-4 md:ag-mx-auto md:ag-absolute ag-bottom-4 md:ag-inset-x-0'
          : 'ag-text-sm md:ag-w-96 ag-mx-auto'
      )}
    >
      <div
        className={cn(
          'ag-max-h-16 ag-h-14 md:ag-h-max ag-w-full ag-scrollbar-thin ag-scrollbar-thumb-neutral-700 ag-scrollbar-track-transparent',
          isChatScreen
            ? 'md:ag-max-h-24 ag-overflow-y-auto'
            : 'md:ag-max-h-20 ag-overflow-y-scroll ag-p-2'
        )}
      >
        {assistantMessages?.content}
      </div>
    </div>
  );
};
