import { forwardRef, useEffect } from 'react';
import { useAutoPlayVideo, useMorph } from '../model';

export const UserVideo = forwardRef<HTMLVideoElement, {}>((_, ref) => {
  const { isWebcam, bindEvents } = useMorph();

  useEffect(() => {
    return bindEvents();
  }, []);

  const { autoplayAllowed } = useAutoPlayVideo();

  return (
    <video
      className={`ag-h-20 ag-rounded-lg ${isWebcam ? '' : 'ag-opacity-0'}`}
      muted={autoplayAllowed}
      playsInline
      ref={ref}
    />
  );
});
