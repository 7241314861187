import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { videoCallStore } from 'features/livekit-call';

import { personaStore } from 'entities/persona';
import {
  EMBED_ROUTE,
  FilledButton,
  Loader,
  showError,
  useQueryParams
} from 'shared';

import EmbedView from './EmbedView';

export const CallEmbed = () => {
  const { getLivekitToken, isCallLoading, secrets, persona } = videoCallStore();
  const { getDetailedPersona, detailedPersona } = personaStore();

  const { id } = useParams();
  const query = useQueryParams();
  const nav = useNavigate();

  useEffect(() => {
    getPersonaData(id);
  }, []);

  const getPersonaData = async (id: string | undefined) => {
    if (!id) return showError("Can't get agent. Please check embed url");

    if (query.get('apiKey') || query.get('agentKey')) {
      const agentKey = query.get('agentKey');
      const apiKey = query.get('apiKey');

      await getDetailedPersona(id, { apiKey, agentKey }).then((success) => {
        !success &&
          showError(
            'Unable to get persona data, please check embed url or try again later'
          );
      });
    }
  };

  const startCall = () => {
    if (!id) return showError("Can't get agent. Please check embed url");

    if (query.get('apiKey') || query.get('agentKey')) {
      const agentKey = query.get('agentKey');
      const apiKey = query.get('apiKey');

      getLivekitToken(id as string, { apiKey, agentKey }).then((success) => {
        if (!success) {
          showError(
            'Unable to call shared agent. Please check embed url or agent key'
          );
        }
      });
    }
  };

  return (
    <div className={`ag-w-full ag-bg-white ag-text-neutral-900`}>
      {!detailedPersona ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="ag-h-screen ag-flex ag-flex-col">
          <div className="ag-relative ag-z-50 ag-left-0 ag-top-0 ag-w-full ag-border-b ag-bg-white">
            <div className="ag-flex ag-flex-col ag-justify-center ag-items-center ag-p-3 ag-text-xs">
              <div className="ag-font-semibold">{detailedPersona.name}</div>
              <div className="ag-text-neutral-600">Powered by yepic ai</div>
            </div>
          </div>

          <>
            {isCallLoading ? (
              <div className="ag-w-full ag-bg-white ag-flex ag-items-center ag-justify-center ag-h-screen">
                <Loader size={50} />
              </div>
            ) : !secrets || !persona ? (
              <div className="ag-w-full ag-grow ag-flex ag-justify-center ag-items-center">
                <div className="ag-w-56 lg:ag-w-96 ag-flex ag-flex-col ag-items-center ag-justify-center ag-mx-auto ">
                  <div className="ag-w-full ag-rounded-full ag-overflow-hidden ag-mb-7 ">
                    <img
                      className="ag-w-full ag-h-full ag-object-cover"
                      src={detailedPersona.avatar.inputImageUrl}
                    />
                  </div>
                  <FilledButton
                    className="ag-w-full ag-py-2 ag-mb-7"
                    onClick={startCall}
                  >
                    Start new chat
                  </FilledButton>
                </div>
              </div>
            ) : (
              <div className={`ag-bg-white ag-grow ag-shrink-0`}>
                <EmbedView />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};
