import React, { useEffect, useState } from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { FaStop } from 'react-icons/fa';
import { IVoice } from '../../../shared/model/types';
import { useNotificationStore } from '../../../stores/useNotificationStore';
import { creditStore } from 'entities/credit';
import { cn } from 'shared/lib';

type VoiceProps = {
  voice: IVoice;
  onPlay: (audio: HTMLAudioElement) => void;
  onPause: () => void;
  isPlaying: boolean;
  currentAudio: any;
  selected: boolean;
  doesGenderMatch: boolean;
  onHandleChange: (voice: IVoice) => void;
};

const Voice: React.FC<VoiceProps> = ({
  voice,
  onPlay,
  onPause,
  isPlaying,
  currentAudio,
  selected,
  doesGenderMatch,
  onHandleChange
}) => {
  const { premiumVoicesAllowed, toggleFeatureBlockModal } = creditStore();
  const showWarn = useNotificationStore((state) => state.showWarn);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (voice.audioSampleUrl) {
      setAudio(new Audio(voice.audioSampleUrl));
    }
  }, []);

  const handlePlay = () => {
    if (isPlaying && currentAudio === audio) {
      onPause();
      return;
    }
    if (audio) onPlay(audio);
  };

  const handlePlayNotification = () => {
    showWarn(
      "We're sorry, but at the moment voice preview is disabled due to technical problems."
    );
  };

  const handleChooseVoice = async () => {
    if (voice.isPremiumVoice) {
      if (!premiumVoicesAllowed) {
        return toggleFeatureBlockModal('premiumVoices');
      }
    }

    onHandleChange(voice);
  };

  return (
    <div
      className={cn(
        `ag-flex ag-justify-between ag-p-1 ag-py-3 ag-w-full ag-border ag-items-center ag-text-sm disabled:ag-opacity-50 ag-rounded-lg`,
        selected
          ? 'ag-border-secondary'
          : 'ag-border-neutral-600 ag-cursor-pointer'
      )}
      onClick={handleChooseVoice}
    >
      <div className="ag-flex ag-items-center ag-space-x-2 ag-truncate">
        <button
          className="ag-w-6 ag-h-6 ag-p-1.5 ag-pl-2 ag-shrink-0"
          onClick={(e) => {
            e.stopPropagation();
            if (voice.audioSampleUrl) {
              handlePlay();
            } else {
              handlePlayNotification();
            }
          }}
          type="button"
        >
          {isPlaying && currentAudio === audio ? (
            <FaStop className="ag-text-secondary" />
          ) : (
            <svg
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.525 13.0258C1.19167 13.2425 0.854333 13.2552 0.513 13.0638C0.171666 12.8725 0.000666667 12.5765 0 12.1758V1.82582C0 1.42582 0.171 1.12982 0.513 0.937822C0.855 0.745822 1.19233 0.758489 1.525 0.975822L9.675 6.15082C9.975 6.35082 10.125 6.63416 10.125 7.00082C10.125 7.36749 9.975 7.65082 9.675 7.85082L1.525 13.0258Z"
                fill="#C34DFF"
              />
            </svg>
          )}
        </button>
        <div
          title={
            doesGenderMatch
              ? ''
              : "This voice doesn't match chosen actor's gender"
          }
          className={`ag-w-6 ag-h-6 ag-p-1 ag-shrink-0 ${
            doesGenderMatch ? '' : 'ag-text-red-400'
          }`}
        >
          {voice?.gender === 'male' ? (
            <BsGenderMale className="ag-w-full ag-h-full" />
          ) : (
            <BsGenderFemale className="ag-w-full ag-h-full" />
          )}
        </div>
        <div className="ag-text-left ag-inline-flex">
          <span className="ag-text-sm ag-mr-2 ag-font-medium ag-w-fit">
            {voice.name.charAt(0).toUpperCase() + voice.name.slice(1)}
          </span>
          <span className="ag-text-sm ag-overflow-hidden ag-inline-block ag-truncate sm:ag-w-auto">
            {voice.language}
          </span>
        </div>
      </div>
      {voice.isPremiumVoice && (
        <div className="ag-mx-4">
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.86866 14.4599L4 21L8.5884 18.247C8.73805 18.1572 8.81288 18.1123 8.89276 18.0947C8.96341 18.0792 9.03659 18.0792 9.10724 18.0947C9.18712 18.1123 9.26195 18.1572 9.4116 18.247L14 21L13.1319 14.4571M13.4259 3.24888C13.5803 3.6224 13.8768 3.9193 14.25 4.0743L15.5589 4.61648C15.9325 4.77121 16.2292 5.06799 16.384 5.44154C16.5387 5.81509 16.5387 6.23481 16.384 6.60836L15.8422 7.91635C15.6874 8.29007 15.6872 8.71021 15.8427 9.08374L16.3835 10.3913C16.4602 10.5764 16.4997 10.7747 16.4997 10.975C16.4998 11.1752 16.4603 11.3736 16.3837 11.5586C16.3071 11.7436 16.1947 11.9118 16.0531 12.0534C15.9114 12.195 15.7433 12.3073 15.5582 12.3839L14.2503 12.9256C13.8768 13.0801 13.5799 13.3765 13.4249 13.7498L12.8827 15.0588C12.728 15.4323 12.4312 15.7291 12.0577 15.8838C11.6841 16.0386 11.2644 16.0386 10.8909 15.8838L9.58296 15.342C9.20942 15.1877 8.78987 15.188 8.41656 15.3429L7.10767 15.8843C6.73434 16.0387 6.31501 16.0386 5.94178 15.884C5.56854 15.7293 5.27194 15.4329 5.11711 15.0598L4.57479 13.7504C4.42035 13.3769 4.12391 13.08 3.75064 12.925L2.44175 12.3828C2.06838 12.2282 1.77169 11.9316 1.61691 11.5582C1.46213 11.1849 1.46192 10.7654 1.61633 10.3919L2.1581 9.08391C2.31244 8.71035 2.31213 8.29079 2.15722 7.91746L1.61623 6.60759C1.53953 6.42257 1.50003 6.22426 1.5 6.02397C1.49997 5.82369 1.5394 5.62536 1.61604 5.44032C1.69268 5.25529 1.80504 5.08716 1.94668 4.94556C2.08832 4.80396 2.25647 4.69166 2.44152 4.61508L3.74947 4.07329C4.12265 3.91898 4.41936 3.6229 4.57448 3.25004L5.11664 1.94111C5.27136 1.56756 5.56813 1.27078 5.94167 1.11605C6.3152 0.961318 6.7349 0.961318 7.10844 1.11605L8.41638 1.65784C8.78993 1.81218 9.20948 1.81187 9.58279 1.65696L10.8922 1.11689C11.2657 0.962245 11.6853 0.962277 12.0588 1.11697C12.4322 1.27167 12.729 1.56837 12.8837 1.94182L13.426 3.25115L13.4259 3.24888Z"
              stroke="#FEC84B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Voice;
