export const activeClasses = 'ag-relative active:ag-top-0.5 active:ag-left-px';

export const scrollBarClasses =
  'ag-scrollbar-thumb-neutral-300 ag-scrollbar-track-transparent ag-scrollbar ag-scrollbar-thumb-rounded-full ag-scrollbar-track-rounded-full';

export function capitalize(str: string) {
  // noinspection SuspiciousTypeOfGuard
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function getParams(url: string) {
  const params = {};
  url.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    // @ts-ignore
    function (_, key: string, value: string) {
      // @ts-ignore
      params[key] = value;
    }
  );

  return params;
}

export function getVideoDimensions(url: string) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;

        resolve({ width, height });
      },
      false
    );

    video.src = url;
  });
}

export const isObject = (value: string) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const leadingZero = (n: number) => (n >= 10 ? `${n}` : `0${n}`);
export const formatTime = (slideTime: number) => {
  if (typeof +slideTime !== 'number' || slideTime === null || isNaN(slideTime))
    return '00:00:00';
  // @ts-ignore
  const minutes = parseInt(slideTime / 60);
  const seconds = slideTime % 60;
  return `00:${leadingZero(minutes)}:${leadingZero(seconds)}`;
};

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const shuffleArray = <T extends Array<any>>(array: T): T => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isArabic = (text: string) => {
  return /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(
    text
  );
};

export async function* streamAsyncIterable<T extends ReadableStream>(
  stream: T
): AsyncGenerator<T extends ReadableStream<infer U> ? U : never> {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

export const throttle = (fun: Function, delay: number) => {
  let flag = true;
  return function () {
    if (flag) {
      fun(...arguments);
      flag = false;
      setTimeout(() => (flag = true), delay);
    }
  };
};
