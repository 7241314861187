import countries from 'shared/lib/countries.json';
import { IVoice, Language, httpService } from 'shared';

export type TCountry = {
  name: string;
  emoji: string;
  image: string;
};

export const voiceService = {
  getVoices: async (): Promise<[IVoice[], string[], Language[]]> => {
    const { data } = await httpService.get('/voices');
    const voices = data as IVoice[];

    const voicesByLang: IVoice[] = [];

    for (const voice of voices) {
      if (!voicesByLang.some((v) => v.languageCode === voice.languageCode)) {
        voicesByLang.push(voice);
      }
    }

    let languages: Language[] = voicesByLang.map((v: IVoice) => {
      const countryCode = v.languageCode.slice(3);
      const country = (countries as { [key: string]: TCountry })[countryCode];
      const isMultilingual = v.isMultilingual;

      return {
        language: v.language,
        country,
        isMultilingual
      };
    });

    const allLangs = {
      language: 'All languages',
      country: null,
      isMultilingual: true
    };

    const multi = {
      language: 'multilingual',
      country: { name: '', emoji: '🌎', image: '' },
      isMultilingual: true
    };

    const engLangs = languages
      .filter((el: any) => el.language.includes('English'))
      .reverse();
    const nonEngLangs = languages?.filter(
      (el: any) => !el.language.includes('English')
    );

    languages = [allLangs, multi, ...engLangs, ...nonEngLangs];

    const voiceProviders = [
      ...new Set([...voices.map((v: any) => v.provider)])
    ].sort();

    voiceProviders.unshift('All providers');

    return [voices, voiceProviders, languages];
  }
};
