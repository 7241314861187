export enum DatachannelMessageType {
  MicrophoneTranscription = 'MicrophoneTranscription',
  TextboxInput = 'TextboxInput',
  SystemMessage = 'SystemMessage',
  Voice = 'Voice',
  Engine = 'GPT',
  Style = 'Style',
  Avatar = 'Avatar',
  ClearChat = 'ClearChat',
  StopTalk = 'StopTalk'
}

export type LocalUserChoices = {
  username: string;
  videoEnabled: boolean;
  audioEnabled: boolean;
  videoDeviceId?: string;
  audioDeviceId?: string;
  language: string;
  stt?: STTProviders;
  tts?: TTSProviders;
};

export type STTProviders = 'google' | 'openai';
export type TTSProviders = 'google' | 'azure';

export type MediaDeviceKind = 'audioinput' | 'audiooutput' | 'videoinput';
