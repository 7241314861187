import EventView2 from './EventView2';

export const CallEvent2 = () => {
  console.log('CallEvent2');

  return (
    <div className={`ag-w-full ag-bg-white ag-text-neutral-900`}>
      <div className="ag-h-screen ag-flex ag-flex-col">
        <div className={`ag-bg-white ag-grow ag-shrink-0`}>
          <EventView2 />
        </div>
      </div>
    </div>
  );
};
