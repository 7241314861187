export const PREFIX = '/video-agents';

export const MAIN_ROUTE = PREFIX;
export const CREATE_PERSONA_ROUTE = PREFIX + '/create';
export const PROFILE_ROUTE = PREFIX + '/profile';
export const EDIT_PERSONA_ROUTE = PREFIX + '/edit/:id';
export const CALL_PERSONA_ROUTE = PREFIX + '/call/:id';
export const EMBED_ROUTE = PREFIX + '/embed/:id';
export const EVENT_ROUTE = PREFIX + '/event/:id';
export const EVENT2_ROUTE = PREFIX + '/event2';

export const LOGIN_ROUTE = '/login';

export const TAVUS_PREFIX = PREFIX + '/demo';
export const TAVUS_EMBED_ROUTE = TAVUS_PREFIX + '/embed/:id';
export const TAVUS_MAIN_ROUTE = TAVUS_PREFIX;
export const TAVUS_CALL_ROUTE = TAVUS_PREFIX + '/call';
export const TAVUS_CREATE_PERSONA_ROUTE = TAVUS_PREFIX + '/create';
