import { useConnectionState } from '@livekit/components-react';
import { ConnectionState, Room } from 'livekit-client';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { logger } from 'shared';

/** @public */
export interface ConnectionStateToastProps {
  room?: Room;
}

export const useConnectionStateToast = (
  props: ConnectionStateToastProps = {}
) => {
  const state = useConnectionState(props.room);
  const isFirstRender = React.useRef(true);

  const curConnection = useRef({
    state
  });

  curConnection.current = {
    state
  };
  useEffect(() => {
    logger.debug(state, 'connection state:');
    if (!isFirstRender.current)
      switch (state) {
        case ConnectionState.Reconnecting:
          toast.dismiss();
          toast.loading('Reconnecting');
          break;
        // case ConnectionState.Connecting:
        //   setTimeout(() => {
        //     if (curConnection.current.state === ConnectionState.Connected) {
        //       toast.dismiss();
        //       toast.loading('Connecting');
        //     }
        //   }, 300);
        //   break;
        case ConnectionState.Disconnected:
          setTimeout(() => {
            if (curConnection.current.state === ConnectionState.Disconnected) {
              toast.dismiss();
              toast('Try again');
            }
          }, 300);
          break;
        default:
          toast.dismiss();
          break;
      }
    isFirstRender.current = false;
  }, [state]);

  return null;
};
