import { RefObject } from 'react';

import EmbedCallSettings from './EmbedCallSettings';
import { TalkingMode } from './TalkingMode';

import { cn } from 'shared';

import { useCall, videoCallStore } from 'features/livekit-call';

export const EmbedControlBar = ({
  webcamRef,
  isSubscribed,
  isEvent = false
}: {
  webcamRef: RefObject<HTMLVideoElement>;
  isSubscribed: boolean;
  isEvent?: boolean;
}) => {
  const { sttLocale, withTapToTalk, isListening } = videoCallStore();

  const {
    handleEndCall,
    handleSendMessage,
    startWebcam,
    stopWebcam,
    webcamLoading,
    isRecordingMic,
    startMicrophone,
    stopMicrophone,
    message,
    isWebcam,
    setMessage
  } = useCall();

  return (
    <>
      {withTapToTalk && !isListening && (
        <div className="ag-hidden lg:ag-flex ag-fixed ag-h-screen ag-w-[calc(255px+1rem)] ag-left-0 ag-top-0 bottom-20 ag-border-r ag-border-neutral-200 ag-flex-col ag-items-center ag-justify-center ag-bg-white ag-z-20">
          <div
            className={cn(
              'ag-rounded-full ag-size-16   ag-border ag-border-neutral-300 ag-p-3.5 ag-flex ag-items-center ag-justify-center ag-cursor-pointer',
              isRecordingMic
                ? 'ag-text-primary-700 ag-bg-primary-100'
                : 'ag-text-neutral-600 ag-bg-neutral-200'
            )}
            onMouseDown={startMicrophone}
            onMouseUp={stopMicrophone}
            onTouchStart={startMicrophone}
            onTouchEnd={stopMicrophone}
          >
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="currentColor"
              stroke="none"
              className="ag-size-full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 0.5C5.55695 0.501389 4.41317 0.975941 3.56955 1.81955C2.90411 2.485 2.46829 3.33722 2.31321 4.25307C2.24404 4.66157 2.58612 5 3.00044 5C3.41441 5 3.74078 4.6593 3.84315 4.25819C3.97527 3.74052 4.2445 3.26286 4.62868 2.87868C5.19129 2.31607 5.95435 2 6.75 2C7.54565 2 8.30871 2.31607 8.87132 2.87868C9.2555 3.26286 9.52473 3.74052 9.65685 4.25819C9.75922 4.6593 10.0856 5 10.4996 5C10.9139 5 11.256 4.66157 11.1868 4.25307C11.0317 3.33722 10.5959 2.485 9.93045 1.81955C9.08683 0.975941 7.94305 0.501389 6.75 0.5Z"
                fill="currentColor"
              />
              <path
                d="M12.7504 21.5H9.42037C8.66791 21.4998 7.94299 21.2169 7.38937 20.7073L0.489368 14.3577C0.328266 14.2105 0.201212 14.0298 0.117057 13.8284C0.0329023 13.627 -0.00632925 13.4097 0.00209545 13.1916C0.0105201 12.9735 0.0663987 12.7598 0.165838 12.5655C0.265277 12.3712 0.405886 12.2009 0.577868 12.0665C0.872508 11.8506 1.23372 11.7453 1.5982 11.7691C1.96268 11.7928 2.30719 11.944 2.57137 12.1962L5.25037 14.6427V5C5.25037 4.60218 5.4084 4.22064 5.68971 3.93934C5.97101 3.65804 6.35254 3.5 6.75037 3.5C7.14819 3.5 7.52972 3.65804 7.81103 3.93934C8.09233 4.22064 8.25037 4.60218 8.25037 5V10.25C8.25037 9.85218 8.4084 9.47064 8.68971 9.18934C8.97101 8.90804 9.35254 8.75 9.75037 8.75C10.1482 8.75 10.5297 8.90804 10.811 9.18934C11.0923 9.47064 11.2504 9.85218 11.2504 10.25V11C11.2504 10.6022 11.4084 10.2206 11.6897 9.93934C11.971 9.65804 12.3525 9.5 12.7504 9.5C13.1482 9.5 13.5297 9.65804 13.811 9.93934C14.0923 10.2206 14.2504 10.6022 14.2504 11V11.75C14.2504 11.3522 14.4084 10.9706 14.6897 10.6893C14.971 10.408 15.3525 10.25 15.7504 10.25C16.1482 10.25 16.5297 10.408 16.811 10.6893C17.0923 10.9706 17.2504 11.3522 17.2504 11.75V17C17.2504 18.1935 16.7763 19.3381 15.9324 20.182C15.0884 21.0259 13.9438 21.5 12.7504 21.5Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="ag-font-semibold ag-text-neutral-600">
            Press to talk
          </div>
        </div>
      )}
      <div className="ag-w-full ag-border-t ag-bg-white ag-border-neutral-200 ag-relative ag-z-20">
        <div
          className={cn(
            'ag-flex ag-gap-4 ag-p-3 ag-border-b ag-z-30 ag-relative',
            !isEvent && 'ag-justify-between'
          )}
        >
          <div className="ag-flex ag-gap-4 ag-items-center">
            <EmbedCallSettings isEvent={isEvent} />

            <div className="ag-h-10 ag-border-l ag-border-neutral-300" />
            <button
              onClick={() => {
                if (isWebcam) return stopWebcam();

                if (webcamRef && webcamRef.current)
                  startWebcam(webcamRef.current);
              }}
              disabled={webcamLoading}
              className="ag-border ag-rounded-full ag-border-neutral-300 size-10 disabled:ag-opacity-80"
            >
              {isWebcam ? (
                <svg
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ag-size-10"
                >
                  <circle cx="19.7224" cy="20" r="13.6111" fill="#525252" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.6375 26.2568C30.8567 26.3695 31.1215 26.3565 31.3293 26.225C31.5386 26.0936 31.6667 25.861 31.6667 25.6111V15.5C31.6667 15.2501 31.5386 15.0176 31.3279 14.8861C31.1172 14.7532 30.8553 14.7431 30.636 14.8543L25.9723 17.2203V15.5C25.9723 14.3055 25.0142 13.3333 23.8369 13.3333H11.0244C9.84703 13.3333 8.88894 14.3055 8.88894 15.5V25.6111C8.88894 26.8057 9.84703 27.7778 11.0244 27.7778H23.8369C25.0142 27.7778 25.9723 26.8057 25.9723 25.6111V23.8908L30.6375 26.2568Z"
                    fill="#E5E5E5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  fill="none"
                  className="ag-size-10"
                >
                  <circle
                    cx="19.9996"
                    cy="20.0001"
                    r="17.2222"
                    fill="#DC2626"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM31.1972 14.6549L26.5277 16.9896V15.5902L32.0143 10.1036C32.2919 9.82601 32.2919 9.37472 32.0143 9.09712C31.7367 8.81952 31.2854 8.81952 31.0078 9.09712L9.65366 30.4513C9.37606 30.7289 9.37606 31.1802 9.65366 31.4578C9.93127 31.7354 10.3826 31.7354 10.6602 31.4578L14.7217 27.3962H24.3923C25.5739 27.3962 26.5277 26.4424 26.5277 25.2608V23.5667L31.1972 25.9014C31.4605 26.0139 31.704 25.984 31.8805 25.873C32.094 25.7306 32.2222 25.5028 32.2222 25.2608V15.2955C32.2222 15.0535 32.094 14.8257 31.8805 14.6834C31.6812 14.5552 31.4107 14.541 31.1972 14.6549ZM10.8395 27.254L24.8763 13.2172C24.7197 13.1745 24.5631 13.1603 24.3923 13.1603H11.5798C10.3982 13.1603 9.44438 14.1141 9.44438 15.2957V25.261C9.44438 26.1863 10.0281 26.9693 10.8395 27.254Z"
                    fill="#E5E5E5"
                  />
                </svg>
              )}
            </button>

            <TalkingMode
              isRecordingMic={isRecordingMic}
              startMicrophone={startMicrophone}
              stopMicrophone={stopMicrophone}
              isEvent={true}
            />
          </div>
          {isEvent && (
            <div className="ag-h-10 ag-border-l ag-border-neutral-300" />
          )}
          <div className="ag-flex ag-gap-4 ag-items-center ag-justify-center">
            <button onClick={handleEndCall} className="disabled:ag-opacity-80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 56 56"
                fill="none"
                className="ag-size-10"
              >
                <g clipPath="url(#clip0_1358_2025)">
                  <path
                    d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                    fill="#DC2626"
                  />
                  <path
                    d="M25.301 30.6687C29.1466 34.5143 32.9157 36.0744 34.2732 34.7169L36.1374 32.8531C36.7541 32.2365 37.5905 31.8901 38.4625 31.8901C39.3346 31.8901 40.171 32.2365 40.7876 32.8531L44.9728 37.0382C45.5894 37.6549 45.9358 38.4913 45.9358 39.3633C45.9358 40.2354 45.5894 41.0718 44.9728 41.6884L41.7121 44.9491C38.6312 48.03 29.5189 43.9175 20.7856 35.1845C16.4211 30.8179 13.5585 26.7049 11.7083 22.8374C9.85812 18.9699 9.48319 15.7976 11.0227 14.258L14.2834 10.9974C14.9001 10.3807 15.7365 10.0343 16.6085 10.0343C17.4806 10.0343 18.317 10.3807 18.9336 10.9974L23.1187 15.1812C23.7354 15.7978 24.0818 16.6342 24.0818 17.5063C24.0818 18.3783 23.7354 19.2147 23.1187 19.8314L21.2532 21.6999C19.8957 23.0562 21.4554 26.8248 25.301 30.6687Z"
                    fill="#EEEFEE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1358_2025">
                    <rect width="56" height="56" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>

        <div className="ag-flex ag-relative ag-items-center ag-z-20 ag-gap-3 ag-p-2.5">
          <input
            disabled={!isSubscribed}
            className={cn(
              sttLocale === 'ar-AE' && 'ag-text-right',
              'ag-w-full ag-py-2 ag-px-2.5 ag-bg-neutral-100 ag-border ag-rounded-md ag-placeholder-neutral-600 ag-ring-0 ag-outline-0 focus:ag-ring-0 focus:ag-outline-none disabled:ag-bg-neutral-50 disabled:ag-cursor-not-allowed disabled:ag-text-neutral-400 disabled:ag-placeholder-neutral-400'
            )}
            placeholder="Speak or type"
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            autoFocus
          />
          <div>
            <button
              disabled={!isSubscribed || !message}
              className={cn(
                'ag-bg-primary-600 ag-rounded-md ag-text-white ag-px-2.5 ag-py-2 disabled:ag-bg-primary-200 disabled:ag-cursor-not-allowed ag-text-sm'
              )}
              onClick={() => handleSendMessage()}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
