import { FiInfo } from 'react-icons/fi';

export const CreditsWarning = () => {
  return (
    <div className="ag-border-red-300 ag-border ag-rounded-xl ag-flex ag-items-center ag-p-3 ag-bg-[#FFFBFA] ag-gap-3">
      <div className="ag-rounded-full ag-bg-red-100 ag-text-lg ag-text-red-600 ag-flex ag-items-center ag-justify-center ag-p-2">
        <FiInfo />
      </div>
      <div className="ag-text-red-700 ag-text-sm ag-font-medium">
        When users chat with your Video Agent, it will deduct credits from your
        account.
      </div>
    </div>
  );
};
