import { useDataChannel } from '@livekit/components-react';
import { useMorph, useWebcam } from 'features/recognize-emotions';
import { logger, useExternalScript } from 'shared/lib';
import { DatachannelMessageType, videoCallStore } from '.';
import { useSpeechRecognition } from 'features/speech-recognition';
import { useEffect, useState } from 'react';
import { MAIN_ROUTE } from 'shared/model';
import { useLocation, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

export const useCall = () => {
  const location = useLocation();

  const { startWebcam, stopWebcam, isWebcam, webcamLoading } = useWebcam();

  const [message, setMessage] = useState('');

  const dc = useDataChannel();

  const {
    isConnected,
    dataChannelMessage,
    sendDataChannel,
    messages,
    addMessage,
    secrets,
    sttLocale,
    persona,
    endCall
  } = videoCallStore();

  const sendJsonMessage = (
    data: { type: DatachannelMessageType } & { [k: string]: any },
    topic?: string
  ) => {
    logger.debug(data, 'json message');
    logger.debug(topic, 'topic');
    dc.send(new TextEncoder().encode(JSON.stringify(data)), { topic });
  };

  useEffect(() => {
    if (!dataChannelMessage || !isConnected) return;
    sendDataChannel(null);
    sendJsonMessage(dataChannelMessage);
  }, [isConnected, dataChannelMessage]);

  useEffect(() => {
    if (!persona || !isConnected) return;
    sendJsonMessage({
      type: DatachannelMessageType.Avatar
    });
  }, [isConnected, persona]);

  useEffect(() => {
    if (messages.length === 0 && isConnected) {
      sendJsonMessage({
        type: DatachannelMessageType.ClearChat
      });
    }
  }, [messages]);

  const { isRecordingMic, stopMicrophone, startMicrophone } =
    useSpeechRecognition(sttLocale, (msg) => {
      sendJsonMessage(
        {
          message: msg,
          type: DatachannelMessageType.MicrophoneTranscription,
          affect: useMorph.getState().affects,
          timestamp: new Date().getTime(),
          id: uuidv4()
        },
        window.location.pathname.includes('event2')
          ? 'lk-chat-topic'
          : undefined
      );
      addMessage({
        role: 'user',
        content: msg,
        timestamp: Date.now(),
        affect: useMorph.getState().getDominantAffect()
      });
    });

  const nav = useNavigate();

  useEffect(() => {
    return () => {
      handleEndCall();
    };
  }, []);

  useEffect(() => {
    if (isConnected && persona) {
      sendJsonMessage({
        type: DatachannelMessageType.Avatar,
        avatar: persona.avatar.id
      });

      sendJsonMessage({
        type: DatachannelMessageType.SystemMessage,
        context: persona.description
      });

      sendJsonMessage({
        type: DatachannelMessageType.Voice,
        voice: persona.voice
      });
    }
  }, [persona, isConnected]);

  useDataChannel((msg) => {
    if (msg.from?.identity !== (secrets?.botIdentity || 'Yepic AI Agent'))
      return;
    const data: unknown = JSON.parse(new TextDecoder().decode(msg.payload));
    if (
      typeof data === 'object' &&
      data &&
      'message' in data &&
      typeof data.message === 'string' &&
      'timestamp' in data &&
      typeof data.timestamp === 'number'
    ) {
      addMessage({
        role: 'assistant',
        timestamp: data.timestamp,
        content: data.message
      });
    } else {
      logger.debug(data, 'received from dc:');
    }
  });

  const handleSendMessage = (msg = message) => {
    sendJsonMessage(
      {
        message: msg,
        type: DatachannelMessageType.TextboxInput,
        affect: useMorph.getState().affects,
        timestamp: new Date().getTime(),
        id: uuidv4()
      },
      window.location.pathname.includes('event2') ? 'lk-chat-topic' : undefined
    );
    addMessage({
      role: 'user',
      content: msg,
      timestamp: Date.now(),
      affect: useMorph.getState().getDominantAffect()
    });
    setMessage('');
  };

  const handleEndCall = () => {
    endCall();

    stopMicrophone();
    stopWebcam();
  };

  return {
    handleEndCall,
    handleSendMessage,
    startWebcam,
    stopWebcam,
    webcamLoading,
    isRecordingMic,
    startMicrophone,
    stopMicrophone,
    message,
    isWebcam,
    setMessage,
    sendJsonMessage
  };
};
