import { create } from 'zustand';
import { AvatarOptions } from '../../../shared';

type Store = {
  typeFilters: AvatarOptions[];
};

type Actions = {
  resetFilters: () => void;
  handleSetTypeFilter: (type: AvatarOptions) => void;
};

export const filterAvatarStore = create<Store & Actions>((set, get) => ({
  typeFilters: [],
  handleSetTypeFilter: (type) => {
    const newFilter = [...get().typeFilters];
    if (newFilter.includes(type)) {
      newFilter.splice(newFilter.indexOf(type), 1);
    } else {
      newFilter.push(type);
    }

    set({ typeFilters: newFilter });
  },
  resetFilters: () => {
    set({ typeFilters: [] });
  }
}));
