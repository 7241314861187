import axios from 'axios';
import { TAVUS_API_KEY, TAVUS_BASEURL } from 'shared/model';

export const tavusHttpService = axios.create({
  baseURL: TAVUS_BASEURL,
  timeout: 60000,
  headers: {
    'x-api-key': TAVUS_API_KEY
  }
});
