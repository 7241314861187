import { create } from 'zustand';
import avatarApi from '../api/avatarApi';
import { DEFAULT_AVATAR, GenderOptions, IAvatar } from 'shared/model';
import axios, { AxiosResponse } from 'axios';
import voiceStore, { getDefaultVoiceId } from '../../../stores/useVoiceStore';
import { CustomPhotoDetails } from '../../../components/SideBar/Avatars/CustomTalkingPhotoFlow/types/types';

type Store = {
  avatarsLoading: boolean;
  activeAvatar: IAvatar | null;
  allAvatars: IAvatar[];
  talkingPhotos: IAvatar[] | null;
  customTalkingPhotos: IAvatar[] | null;
  filteredAvatars: IAvatar[] | null;
  filteredTalkingPhotos: IAvatar[] | null;
  filteredCustomTalkingPhotos: IAvatar[] | null;
  genderFilters: GenderOptions[];
  isUploadAvatarOpen: boolean;
};

type Action = {
  createCustomTalkingPhoto: (avatar: CustomPhotoDetails) => Promise<number>;
  // setFilteredAvatars: () => void;
  setFilteredTalkingPhotos: () => void;
  setFilteredCustomTalkingPhotos: () => void;
  fetchAvatars: () => Promise<void>;
  selectAvatar: (avatar: IAvatar) => void;
  setGenderFilters: (value: GenderOptions) => void;
  resetGenders: () => void;
  deleteCustomTalkingPhoto: (avatarId: string) => Promise<AxiosResponse>;
  getAvatarById: (avatarId: string) => IAvatar | undefined;
  toggleUploadAvatar: () => void;
  updateAvatarStore: (key: string, value: any) => void;
};

export const avatarStore = create<Store & Action>((set, get) => ({
  updateAvatarStore: (key, value) => {
    set({ [key]: value });
  },
  avatarsLoading: true,
  activeAvatar: null,
  allAvatars: [],
  customTalkingPhotos: [],
  talkingPhotos: [],
  isUploadAvatarOpen: false,
  toggleUploadAvatar: () => {},
  createCustomTalkingPhoto: async (avatar) => {
    const { status } = await avatarApi.createCustomTalkingPhoto(avatar);

    await get().fetchAvatars();

    return status;
  },
  fetchAvatars: async () => {
    try {
      const actors = (await avatarApi.fetchAll()) as IAvatar[];

      // const publicAvatars = actors.filter(
      //   (a) => a.publicAvatar && !a.talkingPhoto && !a.customAvatar
      // );
      const talkingPhotos = actors.filter(
        (a) => a.talkingPhoto && !a.customAvatar
      );

      const customTalkingPhotos = actors.filter(
        (a) => a.talkingPhoto && a.customAvatar
      );

      set({
        allAvatars: actors,
        customTalkingPhotos,
        talkingPhotos
      });
      // get().setFilteredAvatars();
      get().setFilteredTalkingPhotos();
      get().setFilteredCustomTalkingPhotos();

      if (!get().activeAvatar) {
        const defaultActor = [...talkingPhotos, ...customTalkingPhotos].find(
          (a) => a.id === DEFAULT_AVATAR
        );
        if (defaultActor) return get().selectAvatar(defaultActor);

        return get().selectAvatar(talkingPhotos[0] || customTalkingPhotos[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set({
        avatarsLoading: false
      });
    }
  },
  selectAvatar: async (avatar) => {
    if (avatar.hasIdleLoop === undefined) {
      if (!avatar.idleLoopVideoUrl) {
        avatar.hasIdleLoop = false;
      } else {
        avatar.hasIdleLoop = await axios
          .head(avatar.idleLoopVideoUrl)
          .then(() => true)
          .catch(() => false);

        if (avatar.customAvatar) {
          set({
            customTalkingPhotos: get().customTalkingPhotos?.map((photo) =>
              photo.id === avatar.id ? avatar : photo
            )
          });
        } else {
          set({
            talkingPhotos: get().talkingPhotos?.map((photo) =>
              photo.id === avatar.id ? avatar : photo
            )
          });
        }
      }
    }

    const { chooseVoice, selectedVoice, voices } = voiceStore.getState();
    if (selectedVoice?.gender.toLowerCase() !== avatar.gender.toLowerCase()) {
      const voice = voices?.find(
        (v) => v.providerVoiceId === getDefaultVoiceId(avatar.gender)
      );
      if (voice) chooseVoice(voice);
    }
    set({
      activeAvatar: avatar
    });
  },
  filteredAvatars: null,
  filteredTalkingPhotos: null,
  filteredCustomTalkingPhotos: null,
  // setFilteredAvatars: () => {
  //   const avatars = get().publicAvatars;
  //   const genderFilters = get().genderFilters;
  //   set({
  //     filteredAvatars: avatars
  //       ?.filter(
  //         (avatar) =>
  //           !genderFilters.length ||
  //           genderFilters.includes(
  //             avatar.gender?.toLowerCase() as GenderOptions
  //           )
  //       )
  //       .sort((a, b) => (a.name > b.name ? 1 : -1))
  //       .sort((a, b) => {
  //         // Sort by presence of idle_video_url property
  //         const hasIdleVideoA = !!a.idleLoopVideoUrl;
  //         const hasIdleVideoB = !!b.idleLoopVideoUrl;

  //         if (hasIdleVideoA && !hasIdleVideoB) {
  //           return -1; // a has idle_video_url, prioritize a
  //         } else if (!hasIdleVideoA && hasIdleVideoB) {
  //           return 1; // b has idle_video_url, prioritize b
  //         }

  //         // Both have or don't have idle_video_url, sort alphabetically by name
  //         return a.name > b.name ? 1 : -1;
  //       })
  //   });
  // },
  setFilteredTalkingPhotos: () => {
    const talkingPhotos = get().talkingPhotos;
    const genderFilters = get().genderFilters;
    set({
      filteredTalkingPhotos: talkingPhotos
        ?.filter(
          (avatar) =>
            !genderFilters.length ||
            genderFilters.includes(
              avatar.gender?.toLowerCase() as GenderOptions
            )
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort((a, b) => {
          // Sort by presence of idle_video_url property
          const hasIdleVideoA = !!a.idleLoopVideoUrl;
          const hasIdleVideoB = !!b.idleLoopVideoUrl;

          if (hasIdleVideoA && !hasIdleVideoB) {
            return -1; // a has idle_video_url, prioritize a
          } else if (!hasIdleVideoA && hasIdleVideoB) {
            return 1; // b has idle_video_url, prioritize b
          }

          // Both have or don't have idle_video_url, sort alphabetically by name
          return a.name > b.name ? 1 : -1;
        })
    });
  },
  setFilteredCustomTalkingPhotos: () => {
    const customTalkingPhotos = get().customTalkingPhotos;
    const genderFilters = get().genderFilters;
    set({
      filteredCustomTalkingPhotos: customTalkingPhotos?.filter(
        (avatar) =>
          !genderFilters.length ||
          genderFilters.includes(avatar.gender?.toLowerCase() as GenderOptions)
      )
    });
  },
  genderFilters: [],
  resetGenders: () => {
    set({
      genderFilters: []
    });
    // get().setFilteredAvatars();
    get().setFilteredTalkingPhotos();
    get().setFilteredCustomTalkingPhotos();
  },
  setGenderFilters: (value) => {
    const genderFilters = get().genderFilters;
    if (genderFilters.includes(value)) {
      set({
        genderFilters: genderFilters.filter((filter) => filter !== value)
      });
    } else {
      set({
        genderFilters: [...genderFilters, value]
      });
    }
    // get().setFilteredAvatars();
    get().setFilteredTalkingPhotos();
    get().setFilteredCustomTalkingPhotos();
  },
  deleteCustomTalkingPhoto: async (avatarId: string) => {
    const response = await avatarApi.deleteCustomTalkingPhoto(avatarId);
    await get().fetchAvatars();
    return response;
  },
  getAvatarById: (avatarId) => {
    const allAvatars = get().allAvatars;

    return (
      allAvatars.find((a) => a.id === avatarId) ||
      allAvatars.find((a) => a.id === DEFAULT_AVATAR)
    );
  }
}));
