import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import { capitalize } from '../utils/utils';
import { IAvatar } from '../../../../../shared/model/types';
import { avatarStore } from '../../../../../entities/avatar';
import { toast } from 'react-toastify';
import { Loader } from 'shared/ui';

type CustomTalkingPhotoInfoProps = {
  onClose: () => void;
  avatar: IAvatar;
};

const CustomTalkingPhotoInfo: React.FC<CustomTalkingPhotoInfoProps> = ({
  onClose,
  avatar
}) => {
  const { deleteCustomTalkingPhoto } = avatarStore();
  const [imgUrl, setImgUrl] = useState(
    avatar.menuImageUrl ?? avatar.inputImageUrl ?? avatar.rawImageUrl
  );
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeletePhoto = async (id: string) => {
    try {
      setIsDeleting(true);
      const data = await deleteCustomTalkingPhoto(id);
      setIsDeleting(false);
      if (data) {
        setIsDeleteConfirmationOpened(false);
        toast('Custom photo deleted successfully');
        onClose();
      }
    } catch (err) {
      setIsDeleting(false);
      console.error(err);
      toast.error(`Failed to delete photo. Error: ${(err as Error).message}`);
    }
  };
  const handleError = () => {
    setImgUrl(avatar.inputImageUrl || '');
  };
  useEffect(() => {
    if (isDeleteConfirmationOpened) {
      setTimeout(() => {
        setIsDeleteConfirmationOpened(false);
      }, 3000);
    }
  }, [isDeleteConfirmationOpened]);

  return (
    <div className="ag-relative ag-w-full md:ag-flex md:ag-min-h-[500px] md:ag-overflow-hidden ag-z-[100]">
      <svg
        onClick={onClose}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ag-w-4 ag-h-4 ag-absolute ag-top-4 ag-right-4 md:ag-top-6 md:ag-right-6 ag-cursor-pointer ag-z-10 ag-text-neutral-400 md:ag-text-black"
      >
        <path
          d="M1.60938 14.846L14.453 1.8418M1.60938 1.8418L14.453 14.846"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <SideBar currentStep={null} />

      <div className="ag-pt-8 ag-pl-4 md:ag-pl-6 ag-pr-4 ag-pb-20 ag-w-full ag-relative ">
        <div className="ag-flex ag-gap-4 ag-mt-20">
          {imgUrl && (
            <img
              crossOrigin="anonymous"
              className={`ag-w-32 md:ag-w-72 ag-aspect-square ag-object-cover ag-rounded-lg`}
              src={imgUrl}
              alt={avatar.name}
              onError={handleError}
            />
          )}
          <div className="ag-text-gray-400 ag-flex ag-flex-col ag-gap-1 ag-font-normal ag-text-base ag-leading-6">
            <p>
              Name:{' '}
              <span className="ag-text-black"> {capitalize(avatar.name)}</span>
            </p>
            {avatar.age && (
              <p>
                Age: <span className="ag-text-black"> {avatar.age}</span>
              </p>
            )}
            <p>
              Gender:{' '}
              <span className="ag-text-black">
                {' '}
                {capitalize(avatar?.gender)}
              </span>
            </p>
            <p>
              Status:{' '}
              <span className="ag-text-black">
                {' '}
                {avatar.avatarStatus || 'Under review'}
              </span>
            </p>
            <button
              onClick={() =>
                isDeleteConfirmationOpened
                  ? handleDeletePhoto(avatar.id)
                  : setIsDeleteConfirmationOpened(true)
              }
              className="ag-rounded-lg ag-w-full ag-font-semibold ag-border ag-flex ag-justify-center ag-border-red-700 hover:ag-bg-red-700
               ag-active:bg-red-500 ag-active:border-red-500 ag-text-red-700 hover:ag-text-white ag-py-1.5 ag-mt-8"
            >
              {isDeleting ? (
                <Loader isButton />
              ) : isDeleteConfirmationOpened ? (
                'Confirm'
              ) : (
                'Delete photo'
              )}
            </button>
            {/* <Modal
              open={isDeleteConfirmationOpened}
              setOpen={setIsDeleteConfirmationOpened}
              // className="ag-w-[360px] ag-p-4"
            >
              <ConfirmationContent
                text={
                  <span className="ag-tracking-wide">
                    Are you sure you want to delete this photo?
                  </span>
                }
                btnLabel="Delete photo"
                onClick={handleDeletePhoto}
                isDeleting={isDeleting}
                onToggle={() =>
                  setIsDeleteConfirmationOpened((prevState) => !prevState)
                }
              />
            </Modal> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTalkingPhotoInfo;
