import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

type DropZoneProps = {
  children: FC<{ isDrag: boolean }>;
  onDragOver?: (e: DragEvent) => void;
  onDrop?: (e: DragEvent) => void;
  onDragLeave?: (e: DragEvent) => void;
  onDragEnter?: (e: DragEvent) => void;
};

type DragFn = (e: DragEvent) => void;

const DropZone: FC<DropZoneProps> = ({
  children: Children,
  onDragOver,
  onDrop,
  onDragLeave,
  onDragEnter
}) => {
  const drop = useRef<HTMLInputElement | null>(null);

  const [isDrag, setIsDrag] = useState(false);

  useEffect(() => {
    const { current } = drop;

    if (!current) return;

    const wrap = (fn?: DragFn | (DragFn | undefined)[]) => (e: DragEvent) => {
      e.preventDefault();
      if (!fn) return;
      if (Array.isArray(fn)) return fn.forEach((f) => f?.(e));

      fn(e);
    };

    const wrappedOnDragOver = wrap(onDragOver);
    const wrappedOnDrop = wrap(onDrop);
    const wrappedOnDragLeave = wrap([
      onDragLeave,
      () => {
        setIsDrag(false);
      }
    ]);
    const wrappedOnDragEnter = wrap([
      onDragEnter,
      () => {
        setIsDrag(true);
      }
    ]);

    current.addEventListener('dragover', wrappedOnDragOver);
    current.addEventListener('drop', wrappedOnDrop);
    current.addEventListener('dragleave', wrappedOnDragLeave);
    current.addEventListener('dragenter', wrappedOnDragEnter);

    return () => {
      current.removeEventListener('dragover', wrappedOnDragOver);
      current.removeEventListener('drop', wrappedOnDrop);
      current.removeEventListener('dragleave', wrappedOnDragLeave);
      current.removeEventListener('dragenter', wrappedOnDragEnter);
    };
  }, [onDragOver, onDrop, onDragLeave, onDragEnter]);

  return (
    <div ref={drop}>
      <Children isDrag={isDrag} />
    </div>
  );
};

export default DropZone;
