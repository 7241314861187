import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';

import {
  HiOutlineDotsVertical,
  HiOutlineTranslate,
  HiOutlineCheck
} from 'react-icons/hi';
import { BiX, BiCaptions } from 'react-icons/bi';
import { BsArrowRepeat } from 'react-icons/bs';

import { cn, useQueryParams, STT_LOCALES, STT_LOCALES_NAMES } from 'shared';

import Divider from 'components/Divider';
import Modal from 'components/shared/Modal';

import { videoCallStore } from 'features/livekit-call';

type Props = {
  isEvent: boolean;
};

const EmbedCallSettings = ({ isEvent }: Props) => {
  const {
    setLocale,
    sttLocale,
    withCaptions,
    toggleCaptions,
    withTapToTalk,
    toggleTapToTalk,
    getLivekitToken,
    endCall
  } = videoCallStore();
  const { id } = useParams();

  const query = useQueryParams();

  const [isLocaleOpen, setIsLocaleOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const handleToggleLocale = () => {
    setIsLocaleOpen((prev) => !prev);
  };

  const restartCall = () => {
    endCall();

    const agentKey = query.get('agentKey');
    const apiKey = query.get('apiKey');

    getLivekitToken(id as string, { apiKey, agentKey });
  };

  return (
    <>
      <button
        onClick={toggleSettings}
        className="ag-rounded-full ag-size-10 ag-flex ag-items-center ag-justify-center disabled:ag-opacity-80 ag-bg-neutral-200 ag-border ag-border-neutral-300 ag-relative"
      >
        <HiOutlineDotsVertical className="ag-text-xl" />
      </button>

      <Transition show={isSettingsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="ag-relative ag-z-50"
          onClose={toggleSettings}
        >
          {!isEvent && (
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0"
              enterTo="ag-opacity-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100"
              leaveTo="ag-opacity-0"
            >
              <div className="ag-fixed ag-inset-0 ag-bg-neutral-600 ag-bg-opacity-50 ag-transition-opacity" />
            </TransitionChild>
          )}
          <div
            className={cn(
              `ag-fixed ag-z-10 sm:ag-flex ag-scrollbar-none`,
              isEvent
                ? 'ag-bottom-[8.6rem] ag-left-2'
                : 'ag-w-full ag-bottom-0 ag-left-0 sm:ag-inset-0 '
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ag-ease-out ag-duration-300"
              enterFrom="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
              enterTo="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leave="ag-ease-in ag-duration-200"
              leaveFrom="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
              leaveTo="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
            >
              <DialogPanel
                className={cn(
                  'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-lg ag-transition-all sm:ag-m-auto ag-scrollbar-none ag-border ag-border-neutral-200',
                  isEvent ? 'sm:ag-w-[255px]' : 'sm:ag-w-[343px]'
                )}
              >
                <BiX
                  className="ag-absolute ag-top-3 ag-right-3 ag-text-3xl ag-text-neutral-gray ag-cursor-pointer ag-z-[99999]"
                  onClick={toggleSettings}
                />
                <div className="ag-p-4 ag-font-semibold">Settings</div>
                <Divider />
                <div className="ag-px-4 ag-py-2 ag-text-sm ag-text-neutral-700">
                  <div
                    className="ag-flex ag-items-center ag-justify-between ag-py-2 ag-cursor-pointer"
                    onClick={() => {
                      handleToggleLocale();
                      toggleSettings();
                    }}
                  >
                    <div className="ag-flex ag-items-center ag-gap-2">
                      <HiOutlineTranslate />
                      <div>Language</div>
                    </div>
                    <div className="ag-text-xs ag-text-neutral-500">
                      {STT_LOCALES_NAMES[STT_LOCALES.indexOf(sttLocale)]}
                    </div>
                  </div>

                  {isEvent && (
                    <label
                      htmlFor="tap"
                      className="ag-hidden lg:ag-flex ag-items-center ag-justify-between ag-py-2 ag-cursor-pointer"
                    >
                      <div className="ag-flex ag-items-center ag-gap-2">
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="currentColor"
                          stroke="none"
                          className="ag-size-3"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 0.5C5.55695 0.501389 4.41317 0.975941 3.56955 1.81955C2.90411 2.485 2.46829 3.33722 2.31321 4.25307C2.24404 4.66157 2.58612 5 3.00044 5C3.41441 5 3.74078 4.6593 3.84315 4.25819C3.97527 3.74052 4.2445 3.26286 4.62868 2.87868C5.19129 2.31607 5.95435 2 6.75 2C7.54565 2 8.30871 2.31607 8.87132 2.87868C9.2555 3.26286 9.52473 3.74052 9.65685 4.25819C9.75922 4.6593 10.0856 5 10.4996 5C10.9139 5 11.256 4.66157 11.1868 4.25307C11.0317 3.33722 10.5959 2.485 9.93045 1.81955C9.08683 0.975941 7.94305 0.501389 6.75 0.5Z"
                            fill="currentColor"
                          />
                          <path
                            d="M12.7504 21.5H9.42037C8.66791 21.4998 7.94299 21.2169 7.38937 20.7073L0.489368 14.3577C0.328266 14.2105 0.201212 14.0298 0.117057 13.8284C0.0329023 13.627 -0.00632925 13.4097 0.00209545 13.1916C0.0105201 12.9735 0.0663987 12.7598 0.165838 12.5655C0.265277 12.3712 0.405886 12.2009 0.577868 12.0665C0.872508 11.8506 1.23372 11.7453 1.5982 11.7691C1.96268 11.7928 2.30719 11.944 2.57137 12.1962L5.25037 14.6427V5C5.25037 4.60218 5.4084 4.22064 5.68971 3.93934C5.97101 3.65804 6.35254 3.5 6.75037 3.5C7.14819 3.5 7.52972 3.65804 7.81103 3.93934C8.09233 4.22064 8.25037 4.60218 8.25037 5V10.25C8.25037 9.85218 8.4084 9.47064 8.68971 9.18934C8.97101 8.90804 9.35254 8.75 9.75037 8.75C10.1482 8.75 10.5297 8.90804 10.811 9.18934C11.0923 9.47064 11.2504 9.85218 11.2504 10.25V11C11.2504 10.6022 11.4084 10.2206 11.6897 9.93934C11.971 9.65804 12.3525 9.5 12.7504 9.5C13.1482 9.5 13.5297 9.65804 13.811 9.93934C14.0923 10.2206 14.2504 10.6022 14.2504 11V11.75C14.2504 11.3522 14.4084 10.9706 14.6897 10.6893C14.971 10.408 15.3525 10.25 15.7504 10.25C16.1482 10.25 16.5297 10.408 16.811 10.6893C17.0923 10.9706 17.2504 11.3522 17.2504 11.75V17C17.2504 18.1935 16.7763 19.3381 15.9324 20.182C15.0884 21.0259 13.9438 21.5 12.7504 21.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <div>Tap down to talk panel</div>
                      </div>
                      <input
                        id="tap"
                        type="checkbox"
                        className="!ag-hidden"
                        checked={withTapToTalk}
                        onChange={toggleTapToTalk}
                      />
                      <div
                        className={cn(
                          'ag-border ag-border-indigo-600 ag-text-indigo-600 ag-rounded ag-size-4 ag-flex ag-items-center ag-justify-center ag-cursor-pointer',
                          withTapToTalk && 'ag-bg-indigo-50'
                        )}
                      >
                        {withTapToTalk ? <HiOutlineCheck /> : null}
                      </div>
                    </label>
                  )}
                  <label
                    htmlFor="captions"
                    className="ag-flex ag-items-center ag-justify-between ag-py-2 ag-cursor-pointer"
                  >
                    <div className="ag-flex ag-items-center ag-gap-2">
                      <BiCaptions />
                      <div>Captions</div>
                    </div>
                    <input
                      id="captions"
                      type="checkbox"
                      className="!ag-hidden"
                      checked={withCaptions}
                      onChange={toggleCaptions}
                    />
                    <div
                      className={cn(
                        'ag-border ag-border-indigo-600 ag-text-indigo-600 ag-rounded ag-size-4 ag-flex ag-items-center ag-justify-center ag-cursor-pointer',
                        withCaptions && 'ag-bg-indigo-50'
                      )}
                    >
                      {withCaptions ? <HiOutlineCheck /> : null}
                    </div>
                  </label>
                  <div
                    className="ag-flex ag-items-center ag-py-2 ag-gap-2 ag-cursor-pointer"
                    onClick={restartCall}
                  >
                    <BsArrowRepeat />
                    <div>Restart chat</div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {isEvent ? (
        <Transition show={isLocaleOpen} as={Fragment}>
          <Dialog
            as="div"
            className="ag-relative ag-z-50"
            onClose={handleToggleLocale}
          >
            <div
              className={cn(
                `ag-fixed ag-z-10 sm:ag-flex ag-scrollbar-none`,
                isEvent
                  ? 'ag-bottom-[8.6rem] ag-left-2'
                  : 'ag-w-full ag-bottom-0 ag-left-0 sm:ag-inset-0 '
              )}
            >
              <TransitionChild
                as={Fragment}
                enter="ag-ease-out ag-duration-300"
                enterFrom="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
                enterTo="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
                leave="ag-ease-in ag-duration-200"
                leaveFrom="ag-opacity-100 ag-translate-y-0 sm:ag-scale-100"
                leaveTo="ag-opacity-0 ag-translate-y-4 sm:ag-translate-y-0 sm:ag-scale-95"
              >
                <DialogPanel
                  className={cn(
                    'ag-relative ag-transform ag-rounded-lg ag-bg-white ag-text-left ag-shadow-xl ag-transition-all sm:ag-m-auto ag-scrollbar-none ag-border ag-border-neutral-200',
                    isEvent ? 'sm:ag-w-[255px]' : 'sm:ag-w-[343px]'
                  )}
                >
                  <BiX
                    className="ag-absolute ag-top-3 ag-right-3 ag-text-3xl ag-text-neutral-gray ag-cursor-pointer ag-z-[99999]"
                    onClick={handleToggleLocale}
                  />
                  <div className="ag-p-4 ag-font-semibold">
                    Choose your spoken language
                  </div>
                  <Divider />
                  <div className="ag-p-4">
                    {STT_LOCALES.map((locale, ind) => (
                      <div
                        key={locale}
                        className={cn(
                          'ag-p-2 ag-text-sm ag-text-neutral-700',
                          sttLocale === locale
                            ? 'ag-bg-neutral-100'
                            : 'hover:ag-bg-neutral-100 ag-cursor-pointer'
                        )}
                        onClick={() => {
                          setLocale(locale);
                          handleToggleLocale();
                        }}
                      >
                        {STT_LOCALES_NAMES[ind]}
                      </div>
                    ))}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <Modal
          open={isLocaleOpen}
          setOpen={handleToggleLocale}
          noPadding
          sizeClasses="ag-w-11/12 sm:ag-w-[343px] ag-border ag-border-neutral-200"
        >
          <div className="ag-p-4 ag-font-semibold">
            Choose your spoken language
          </div>
          <Divider />
          <div className="ag-p-4">
            {STT_LOCALES.map((locale, ind) => (
              <div
                key={locale}
                className={cn(
                  'ag-p-2 ag-text-sm ag-text-neutral-700',
                  sttLocale === locale
                    ? 'ag-bg-neutral-100'
                    : 'hover:ag-bg-neutral-100 ag-cursor-pointer'
                )}
                onClick={() => {
                  setLocale(locale);
                  handleToggleLocale();
                }}
              >
                {STT_LOCALES_NAMES[ind]}
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default EmbedCallSettings;
