import { create } from 'zustand';
import { Conversation, ConversationRequest } from './conversationTypes';
import { toast } from 'react-toastify';
import { conversationApi } from '../api';
import { ITavusPersona } from 'entities/tavus/persona';

type State = {
  currentConversation: Conversation | null;
  conversationLoading: boolean;
};

type Action = {
  startConversation: (currentPersona: ITavusPersona | null) => Promise<void>;
  endConversation: () => Promise<void>;
};

type Store = State & Action;

export const conversationStore = create<Store>((set, get) => ({
  currentConversation: null,
  conversationLoading: false,
  startConversation: async (currentPersona) => {
    if (!currentPersona) {
      toast.error('Please select a persona to start the conversation.');
      return;
    }
    set({ conversationLoading: true });

    const conversationRequest = {
      replica_id: currentPersona.default_replica_id,
      persona_id: currentPersona.persona_id,
      conversation_name: `Call with ${currentPersona.persona_name}`
    };

    try {
      const conversation = await conversationApi.createConversation(
        conversationRequest
      );

      set({ currentConversation: conversation });
    } catch (error) {
      toast.error("Couldn't start the conversation. Please try again later.");
    } finally {
      set({ conversationLoading: false });
    }
  },
  endConversation: async () => {
    const conversationId = get().currentConversation?.conversation_id;

    if (!conversationId) {
      toast.error('No conversation to end.');
      return;
    }

    try {
      await conversationApi.endConversation(conversationId);

      set({ currentConversation: null });
    } catch (error) {
      toast.error("Couldn't end the conversation. Please try again later.");
    }
  }
}));
