import { useEffect, useState } from 'react';
import { LuCopy } from 'react-icons/lu';
import { GoPlus } from 'react-icons/go';
import { MdAutorenew } from 'react-icons/md';

import Divider from 'components/Divider';
import Modal from 'components/shared/Modal';

import { FilledButton } from 'shared/ui';
import { ENVIRONMENT, showSuccessMessage } from 'shared/model';

import { personaStore } from 'entities/persona';
import { useGenerateEmbedCode } from '../model';

import { WhereSelector } from './WhereSelector';
import { CreditsWarning } from './CreditsWarning';
import { activeClasses, capitalize, cn, scrollBarClasses } from 'shared/lib';
import { useAuthStore } from 'stores/useAuthStore';
import { Link } from 'react-router-dom';
import { creditStore } from 'entities/credit';
import { FiInfo } from 'react-icons/fi';

const modalModes = ['embed', 'widget'] as const;
const modalModesGain = ['embed', 'widget', 'GAIN'] as const;

type ModalModes = typeof modalModes[number];
type ModalModesGain = typeof modalModesGain[number];

export const EmbedModal = () => {
  const { togglePersonaEmbedModal, personaEmbedModal } = personaStore();
  const [newDomain, setNewDomain] = useState('');

  const [modalModesCurrent, setModalModesCurrent] = useState<
    readonly ['embed', 'widget'] | readonly ['embed', 'widget', 'GAIN']
  >(modalModes);

  const [modalMode, setModalMode] = useState<ModalModes | ModalModesGain>(
    'widget'
  );
  const { productName } = creditStore();

  useEffect(() => {
    if (
      productName?.toLowerCase() === 'premium' &&
      ENVIRONMENT !== 'production'
    ) {
      setModalModesCurrent(modalModesGain);
    } else {
      setModalModesCurrent(modalModes);
    }
  }, [productName]);

  const handleChangeNewDomain = (value: string) => {
    setNewDomain(value);
  };

  const handleAddDomain = () => {
    onAddDomain(newDomain);
    setNewDomain('');
  };

  const handleSwitchModalMode = (type: ModalModes | ModalModesGain) => {
    setModalMode(type);
  };

  const {
    embedCode,
    widgetCode,
    allowedDomains,
    onAddDomain,
    generateEmbedCode,
    removeDomainName,
    where,
    handleChangeWhere
  } = useGenerateEmbedCode(personaEmbedModal as string);

  return (
    <Modal
      open={Boolean(personaEmbedModal)}
      setOpen={() => togglePersonaEmbedModal(null)}
      noPadding
      className="!ag-w-full sm:!ag-w-[464px]"
    >
      <div className="ag-p-6">
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          Embed Video Agent
        </h2>
      </div>
      <Divider />

      <div className="ag-p-6">
        <div className="ag-flex ag-items-center ag-p-1 ag-bg-neutral-100 ag-w-full ag-mb-6 ag-text-sm ag-font-semibold ag-text-neutral-600 ag-gap-2 ag-rounded-md ag-shadow-sm">
          {modalModesCurrent.map((mode) => (
            <button
              className={cn(
                'ag-w-1/2 ag-text-center ag-rounded-md ag-py-2',
                modalMode === mode
                  ? 'ag-bg-white ag-shadow-sm ag-cursor-default'
                  : 'ag-cursor-pointer'
              )}
              key={mode}
              onClick={() => handleSwitchModalMode(mode)}
              disabled={modalMode === mode}
            >
              {capitalize(mode)}
            </button>
          ))}
        </div>
        {modalMode === 'GAIN' ? (
          <div className="ag-border-red-300 ag-border ag-rounded-xl ag-flex ag-items-center ag-p-3 ag-bg-[#FFFBFA] ag-gap-3 ">
            <div className="ag-rounded-full ag-bg-red-100 ag-text-lg ag-text-red-600 ag-flex ag-items-center ag-justify-center ag-p-2">
              <FiInfo />
            </div>
            <div className="ag-text-red-700 ag-text-sm ag-font-medium">
              This is an internal link for GAIN event.
            </div>
          </div>
        ) : (
          <CreditsWarning />
        )}
        {modalMode === 'embed' && (
          <>
            <div className="ag-font-medium ag-text-sm ag-pt-6 ag-mb-2">
              Where can the video be embedded?
            </div>
            <WhereSelector
              where={where}
              handleChangeWhere={handleChangeWhere}
            />
            {where === 'specific domain' && (
              <div className="ag-mt-2">
                <div className="ag-flex ag-items-center ag-justify-between ag-gap-2 ag-mb-2">
                  <input
                    id="domain"
                    value={newDomain}
                    type="text"
                    placeholder="Enter domain"
                    className="ag-grow ag-border ag-border-neutral-200 ag-rounded-lg"
                    onChange={(e) => handleChangeNewDomain(e.target.value)}
                  />
                  <button
                    className="ag-rounded-lg ag-border ag-self-stretch ag-px-4 ag-flex ag-items-center"
                    onClick={handleAddDomain}
                  >
                    <GoPlus className="ag-text-xl ag-mr-2" />
                    <div>Add</div>
                  </button>
                </div>
                {allowedDomains.length > 0 && (
                  <div className="ag-rounded-lg ag-bg-neutral-50 ag-border ag-p-4 ag-space-y-4 ag-text-neutral-600 ag-text-sm">
                    {allowedDomains.map((d, ind) => (
                      <div
                        key={d + ind}
                        className="ag-flex ag-items-center ag-justify-between"
                      >
                        <div>{d}</div>
                        <button
                          onClick={() => removeDomainName(ind)}
                          className="ag-text-neutral-700"
                        >
                          <svg
                            width="18"
                            height="20"
                            viewBox="0 0 18 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.3333 5.00008V4.33341C12.3333 3.39999 12.3333 2.93328 12.1517 2.57676C11.9919 2.26316 11.7369 2.00819 11.4233 1.8484C11.0668 1.66675 10.6001 1.66675 9.66667 1.66675H8.33333C7.39991 1.66675 6.9332 1.66675 6.57668 1.8484C6.26308 2.00819 6.00811 2.26316 5.84832 2.57676C5.66667 2.93328 5.66667 3.39999 5.66667 4.33341V5.00008M7.33333 9.58342V13.7501M10.6667 9.58342V13.7501M1.5 5.00008H16.5M14.8333 5.00008V14.3334C14.8333 15.7335 14.8333 16.4336 14.5608 16.9684C14.3212 17.4388 13.9387 17.8212 13.4683 18.0609C12.9335 18.3334 12.2335 18.3334 10.8333 18.3334H7.16667C5.76654 18.3334 5.06647 18.3334 4.53169 18.0609C4.06129 17.8212 3.67883 17.4388 3.43915 16.9684C3.16667 16.4336 3.16667 15.7335 3.16667 14.3334V5.00008"
                              stroke="currentColor"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div
              className={cn(
                'ag-rounded-lg ag-border ag-p-4 ag-py-2 ag-mt-6 ag-max-h-20 ag-overflow-y-auto',
                scrollBarClasses
              )}
            >
              {embedCode}
            </div>
            <button
              className="ag-w-full ag-flex ag-items-center ag-justify-end ag-gap-2 ag-text-primary-700 ag-mt-4 ag-font-semibold"
              onClick={generateEmbedCode}
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ag-text-2xl"
              >
                <path
                  d="M10.6667 1.66663C10.6667 1.66663 11.3744 1.76773 14.3033 4.69666C17.2322 7.62559 17.2322 12.3743 14.3033 15.3033C13.2656 16.341 11.9994 17.011 10.6667 17.3134M10.6667 1.66663L15.6667 1.66663M10.6667 1.66663L10.6667 6.66663M7.33333 18.3331C7.33333 18.3331 6.62563 18.232 3.6967 15.3031C0.767767 12.3742 0.767767 7.62543 3.6967 4.6965C4.73443 3.65877 6.0006 2.98871 7.33333 2.68632M7.33333 18.3331L2.33333 18.3333M7.33333 18.3331L7.33333 13.3333"
                  stroke="#B015FF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div>Renew code</div>
            </button>
            <div className="ag-mt-6">
              <FilledButton
                className={cn(
                  'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm ag-w-full hover:ag-bg-primary-700',
                  activeClasses
                )}
                onClick={() =>
                  navigator.clipboard
                    .writeText(embedCode)
                    .then(() =>
                      showSuccessMessage('The embed code has been copied')
                    )
                }
              >
                Copy
              </FilledButton>
            </div>
          </>
        )}
        {modalMode === 'widget' && (
          <>
            <div className="ag-text-sm ag-text-neutral-700 ag-mb-1 ag-font-medium ag-mt-6">
              {'Paste this code before </head> tag'}
            </div>
            <div className="ag-border ag-rounded-lg ag-mb-2 ag-p-2 ag-text-neutral-900">
              {widgetCode[0]}
            </div>
            <FilledButton
              className={cn(
                'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm ag-mb-6 hover:ag-bg-primary-700',
                activeClasses
              )}
              onClick={() =>
                navigator.clipboard
                  .writeText(widgetCode[0])
                  .then(() =>
                    showSuccessMessage(
                      'The head part of widget code has been copied'
                    )
                  )
              }
            >
              Copy
            </FilledButton>
            <div className="ag-text-sm ag-text-neutral-700 ag-mb-1 ag-font-medium">
              {'Paste this code before </body> tag'}
            </div>
            <div className="ag-border ag-rounded-lg ag-mb-2 ag-p-2 ag-text-neutral-900">
              {widgetCode[1]}
            </div>
            <FilledButton
              className={cn(
                'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm ag-mb-6 hover:ag-bg-primary-700',
                activeClasses
              )}
              onClick={() =>
                navigator.clipboard
                  .writeText(widgetCode[1])
                  .then(() =>
                    showSuccessMessage(
                      'The body part of widget code has been copied'
                    )
                  )
              }
            >
              Copy
            </FilledButton>
          </>
        )}

        {modalMode === 'GAIN' && (
          <div className="ag-flex ag-flex-col ag-justify-between ag-h-96 ag-pt-6">
            <div className="ag-text-primary-700">{widgetCode[2]}</div>
            <div className="ag-space-y-4">
              <Link
                to={widgetCode[2]}
                target={ENVIRONMENT === 'local' ? '' : '_blank'}
                rel="noreferrer noopener"
                onClick={() => togglePersonaEmbedModal(null)}
                className={cn(
                  'ag-bg-primary-600 ag-rounded-md ag-flex ag-justify-center ag-items-center ag-text-white ag-text-sm  ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 hover:ag-bg-primary-700',
                  activeClasses
                )}
              >
                Open link
              </Link>
              <Link
                to={widgetCode[3]}
                target={ENVIRONMENT === 'local' ? '' : '_blank'}
                rel="noreferrer noopener"
                onClick={() => togglePersonaEmbedModal(null)}
                className={cn(
                  'ag-bg-primary-600 ag-rounded-md ag-flex ag-justify-center ag-items-center ag-text-white ag-text-sm  ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 hover:ag-bg-primary-700',
                  activeClasses
                )}
              >
                Open GAIN room link
              </Link>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
