import { create } from 'zustand';
import { DAILY_CALL_STATES } from './dailyCallModel';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';

type State = {
  currentState: string;
  callObject: DailyCall | null;
};

type Action = {
  setCurrentState: (currentState: string) => void;
  setCallObject: (callObject: DailyCall | null) => void;
  startLeavingCall: () => void;
  startHairCheck: (url: string) => void;
  joinCall: (url: string) => void;
  handleNewMeetingState: () => void;
};

type Store = State & Action;

export const dailyCallStore = create<Store>((set, get) => ({
  currentState: DAILY_CALL_STATES.STATE_IDLE,
  setCurrentState: (currentState: string) => set({ currentState }),
  callObject: null,
  setCallObject: (callObject: DailyCall | null) => set({ callObject }),
  startLeavingCall: () => {
    if (!get().callObject) return;
    if (get().currentState === DAILY_CALL_STATES.STATE_ERROR) {
      get()
        .callObject?.destroy()
        .then(() => {
          set({ callObject: null, currentState: DAILY_CALL_STATES.STATE_IDLE });
          DailyIframe.getCallInstance()?.destroy();
        });
    } else {
      set({ currentState: DAILY_CALL_STATES.STATE_LEAVING });
      get().callObject?.leave();
      DailyIframe.getCallInstance()?.destroy();
      set({ callObject: null, currentState: DAILY_CALL_STATES.STATE_IDLE });
    }
  },
  startHairCheck: async (url: string) => {
    const newCallObject = DailyIframe.createCallObject();
    set({
      callObject: newCallObject,
      currentState: DAILY_CALL_STATES.STATE_HAIRCHECK
    });
    await newCallObject.preAuth({ url });
    // await newCallObject.startCamera();
  },
  joinCall: (url) => {
    get().callObject?.join({ url, userName: 'user' });
  },
  handleNewMeetingState: () => {
    switch (get().callObject?.meetingState()) {
      case 'joined-meeting':
        set({ currentState: DAILY_CALL_STATES.STATE_JOINED });
        break;
      case 'left-meeting':
        get()
          .callObject?.destroy()
          .then(() => {
            set({
              callObject: null,
              currentState: DAILY_CALL_STATES.STATE_IDLE
            });
          });
        break;
      case 'error':
        set({ currentState: DAILY_CALL_STATES.STATE_ERROR });
        break;
      default:
        break;
    }
  }
}));
