import type { FC, ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { cn } from 'shared/lib';

export const OutlinedButton: FC<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { borderSecondary?: boolean; textSecondary?: boolean }
> = ({
  className = '',
  borderSecondary,
  textSecondary,
  type = 'button',
  ...props
}) => {
  return (
    <button
      className={cn(
        `ag-border ${
          borderSecondary ? 'ag-border-secondary' : 'ag-border-neutral-300'
        } ${
          textSecondary ? 'ag-text-secondary' : 'ag-text-neutral-700'
        } ag-flex ${
          className.includes('ag-justify-') ? '' : 'ag-justify-center'
        } ag-items-center ag-text-sm disabled:ag-opacity-50 ag-rounded-lg`,
        className
      )}
      type={type}
      {...props}
    />
  );
};
