import Modal from 'components/shared/Modal';
import { IStarterQuestion, IStarterQuestionReq } from 'entities/persona';
import { FC, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { showError } from 'shared/model';
import { FilledButton, OutlinedButton } from 'shared/ui';
import { FiMinus } from 'react-icons/fi';

type Props = {
  starterQuestions?: IStarterQuestionReq[];
  onChange: (newStarters: IStarterQuestionReq[]) => void;
  classNameBg?: string;
};

export const AddStarters: FC<Props> = ({
  starterQuestions = [],
  onChange,
  classNameBg = 'ag-bg-neutral-800'
}) => {
  const [starters, setStarters] =
    useState<IStarterQuestionReq[]>(starterQuestions);
  const [newStarter, setNewStarter] = useState<string>('');
  const [isAdding, setIsAdding] = useState(false);

  const handleOpenAddModal = (isOpen: boolean) => {
    setIsAdding(isOpen);
  };

  const handleAddStarter = () => {
    if (newStarter === '') return showError('Starter must not be empty');

    const newStarters = [...starters, { question: newStarter }];

    setStarters(newStarters);
    onChange(newStarters);

    setNewStarter('');
    handleOpenAddModal(false);
  };

  const handleRemoveStarter = (index: number) => {
    const newStarters = [...starters];
    newStarters.splice(index, 1);

    setStarters(newStarters);
    onChange(newStarters);
  };

  return (
    <div className="ag-flex ag-flex-col">
      <div className="ag-text-gray-100">Suggested Starters (Optional)</div>
      <div className="ag-text-sm ag-text-neutral-400 ag-mb-4">
        Add some examples of how users could begin a chat. They will appear in
        the chat as buttons. For example, for a customer service Video Agent:
        “tell me about your subscriptions” , “tell me about your company”, etc.
      </div>
      <div
        className="ag-text-primary-600 ag-flex ag-items-center ag-cursor-pointer ag-mb-4"
        onClick={() => handleOpenAddModal(true)}
      >
        <FaPlus className="ag-mr-2" />
        <div>Add new starter</div>
      </div>
      {starters &&
        starters.length > 0 &&
        starters.map((s, ind) => (
          <div
            key={`${s.question}-${ind}`}
            className="ag-flex ag-items-center ag-mb-4"
          >
            <div
              className={`ag-mr-4 ag-rounded-lg ${classNameBg} ag-border ag-border-neutral-600 ag-px-4 ag-py-2 ag-w-full ag-text-neutral-100`}
            >
              {s.question}
            </div>
            <div
              className="ag-flex ag-items-center ag-justify-center ag-w-6 ag-h-6 ag-rounded-full ag-bg-neutral-600 ag-cursor-pointer"
              onClick={() => handleRemoveStarter(ind)}
            >
              <FiMinus className="ag-text-gray-900 ag-text-lg" />
            </div>
          </div>
        ))}

      <Modal
        open={isAdding}
        setOpen={() => handleOpenAddModal(false)}
        noPadding
      >
        <div className="ag-p-6 ag-pb-5 ag-font-semibold ag-text-lg ag-border-b ag-border-neutral-200">
          Suggested Starter
        </div>
        <div className="ag-p-6">
          <textarea
            className="ag-rounded-lg ag-border-neutral-200 ag-w-full focus:ag-border-primary-600 focus:ag-outline-none focus:ag-ring-0 ag-mb-6"
            value={newStarter}
            onChange={(e) => setNewStarter(e.target.value)}
            placeholder="For example, for a customer service video agent: “tell me about your subscriptions”, “tell me about your company”, etc."
          />
          <div className="ag-flex ag-items-center ag-justify-end ag-gap-4">
            <OutlinedButton
              onClick={() => handleOpenAddModal(false)}
              className="ag-text-sm ag-px-4 ag-py-2 ag-w-20"
            >
              Cancel
            </OutlinedButton>
            <FilledButton
              onClick={handleAddStarter}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-20"
            >
              Create
            </FilledButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};
