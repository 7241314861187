import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FilledButton,
  MAIN_ROUTE,
  OutlinedButton,
  showError,
  Loader,
  cn,
  useQueryParams,
  TAVUS_PREFIX
} from 'shared';

import { TavusTopBar } from './TavusTopBar';
import {
  TavusChooseReplica,
  TavusChooseModel,
  tavusSteps
} from 'widgets/tavus-create-persona';

import { useFormik } from 'formik';

import { userStore } from 'entities/user';
import {
  ITavusPersonaFormik,
  tavusInitialPersona,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { Replica } from 'entities/tavus/replica';
import { TavusPersonaDataForm } from 'features/tavus-persona-form';

/**
 * CreatePersonaPage component.
 *
 * This component allows users to create a new persona by going through multiple steps.
 * It handles form submission, state management, and conditional rendering based on the current step.
 *
 */

export const TavusCreatePersonaPage = () => {
  const { user } = userStore();

  const [currentStep, setCurrentStep] = useState(1);

  const [replica, setReplica] = useState<Replica | undefined>();
  const isPersonaCreating = tavusPersonaStore(
    (state) => state.isPersonaCreating
  );
  const addPersona = tavusPersonaStore((state) => state.addPersona);
  const replicasLoading = replicaStore((state) => state.replicasLoading);
  const getReplicaById = replicaStore((state) => state.getReplicaById);
  const getReplicaVideo = replicaStore((state) => state.getReplicaVideo);
  const replicas = replicaStore((state) => state.replicas);
  const nav = useNavigate();
  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  const onSubmit = async (values: ITavusPersonaFormik) => {
    const submitValues = { ...values };
    if (submitValues.name === '') {
      submitValues.name = 'My Video Agent';
    }
    const persona = await addPersona({
      persona_name: submitValues.name,
      context: submitValues.context,
      system_prompt: submitValues.systemPrompt,
      default_replica_id: submitValues.defaultReplicaId,
      layers: {
        llm: {
          model: submitValues.model
        }
      }
    });
    if (persona) nav(TAVUS_PREFIX + '?posthog-agents-create=true');
  };

  const formik = useFormik<ITavusPersonaFormik>({
    initialValues: tavusInitialPersona,
    validationSchema: Yup.object({
      name: Yup.string(),
      systemPrompt: Yup.string()
        .required('Please enter system prompt')
        .max(10000, 'Please make system prompt shorter'),
      context: Yup.string()
        .required('Please enter context')
        .max(10000, 'Please make system prompt shorter')
    }),
    onSubmit
  });

  useEffect(() => {
    if (replicas.length) {
      formik.setFieldValue('defaultReplicaId', replicas[0].replica_id);
    }
  }, [replicas]);

  useEffect(() => {
    if (!replicasLoading)
      setReplica(getReplicaById(formik.values.defaultReplicaId));
  }, [formik.values.defaultReplicaId, replicasLoading]);

  useEffect(() => {
    if (!user) {
      showError('You need to login to access this page.');
      nav(MAIN_ROUTE);
    }
  }, [user]);

  const isStepWithAvatarPreview = currentStep === 1;

  return (
    <div className="ag-bg-neutral-900 ag-text-neutral-100 ag-h-screen ag-overflow-auto ag-relative sm:ag-static ag-pt-0 ">
      <TavusTopBar currentStep={currentStep} onStepChange={handleChangeStep} />
      <form
        onSubmit={formik.handleSubmit}
        className="ag-relative  sm:ag-static ag-pb-[63px] sm:ag-pb-0 ag-grid ag-grid-rows-[1fr,auto]"
      >
        <div className="ag-h-full ag-grid ag-grid-rows-[auto,1fr]">
          {isStepWithAvatarPreview ? (
            <div className="ag-size-[12rem] sm:ag-size-56 ag-aspect-square ag-mx-auto ag-overflow-hidden ag-rounded-xl ag-border-none ag-mt-9 sm:ag-mt-10 ag-mb-8">
              {!!replica && (
                <video
                  preload="metadata"
                  src={getReplicaVideo(replica.replica_id)}
                  className="ag-w-full ag-h-full ag-object-cover ag-border-none"
                />
              )}
            </div>
          ) : (
            <div />
          )}
          <div
            className={cn(
              'ag-mx-auto ag-w-full md:ag-px-[calc((100vw-36rem)/2)] ag-px-4 sm:ag-px-6',
              !isStepWithAvatarPreview && 'ag-pt-10 ag-pb-20'
            )}
          >
            {currentStep === 1 && (
              <TavusChooseReplica
                defaultReplicaId={formik.values.defaultReplicaId}
                onChange={(value: string) =>
                  formik.setFieldValue('defaultReplicaId', value)
                }
              />
            )}
            {currentStep === 2 && <TavusPersonaDataForm formik={formik} />}
            {currentStep === 3 && (
              <TavusChooseModel
                formik={formik}
                onChange={(value: string) =>
                  formik.setFieldValue('model', value)
                }
              />
            )}
          </div>
        </div>

        <div className="ag-fixed sm:ag-absolute static ag-bottom-0 ag-left-0 ag-flex ag-items-center ag-w-full ag-border-t ag-px-4 sm:ag-px-6 ag-py-3 ag-justify-end ag-gap-4 ag-bg-neutral-800 ag-border-neutral-700  ">
          {currentStep === 1 && (
            <Link to={MAIN_ROUTE} className="ag-block ag-w-1/2 sm:ag-w-auto">
              <OutlinedButton className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-block ag-w-full sm:ag-w-36">
                Cancel
              </OutlinedButton>
            </Link>
          )}
          {currentStep !== 1 && (
            <OutlinedButton
              onClick={() => handleChangeStep(currentStep - 1)}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
            >
              Back
            </OutlinedButton>
          )}
          {currentStep !== tavusSteps.length && (
            <FilledButton
              type="button"
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
              onClick={async () => {
                if (currentStep === 3) {
                  const errors = await formik.validateForm();

                  if (Object.keys(errors).length > 0) {
                    for (const err in errors) {
                      showError((errors as { [key: string]: string })[err]);
                    }
                    return;
                  }
                }
                handleChangeStep(currentStep + 1);
              }}
            >
              Next
            </FilledButton>
          )}
          {currentStep === tavusSteps.length && (
            <FilledButton
              type="submit"
              disabled={isPersonaCreating}
              onClick={() => {
                if (Object.keys(formik.errors).length > 0) {
                  Object.keys(formik.errors).forEach((e) =>
                    showError((formik.errors as { [key: string]: string })[e])
                  );
                  return;
                }
              }}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36 disabled:ag-bg-primary-600"
            >
              {isPersonaCreating ? <Loader isButton size={20} /> : 'Create'}
            </FilledButton>
          )}
        </div>
      </form>
    </div>
  );
};
