import React, { useState } from 'react';
import clsx from 'clsx';
import { Replica } from 'entities/tavus/replica';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';

type AvatarItemProps = {
  replica: Replica;
  onChooseReplica: (replica: Replica) => void;
  isActive?: boolean;
};

export const TavusReplicaItem: React.FC<AvatarItemProps> = ({
  replica,
  onChooseReplica,
  isActive
}) => {
  const getReplicaVideo = replicaStore((state) => state.getReplicaVideo);
  const handleChooseReplica = () => {
    onChooseReplica(replica);
  };

  return (
    <div
      onClick={handleChooseReplica}
      className={clsx(
        `ag-relative ag-group ag-h-fit`,
        isActive
          ? 'ag-cursor-default'
          : 'ag-cursor-pointer ag-opacity-60 hover:ag-opacity-100'
      )}
    >
      {getReplicaVideo(replica.replica_id) && (
        <video
          preload="metadata"
          src={getReplicaVideo(replica.replica_id)}
          className={`ag-w-full ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-600'
          } `}
        />
      )}
      <div className="ag-hidden group-hover:ag-block ag-absolute ag-bottom-0 ag-top-2/3 ag-left-0 ag-right-0 ag-bg-title-gradient ag-rounded-t" />
      <span className="ag-text-white ag-text-sm ag-inline ag-absolute ag-bottom-2 ag-left-2">
        {replica.replica_name}
      </span>
    </div>
  );
};
