import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { cn } from 'shared/lib';

import { useGuide } from 'hooks/useGuide';

export const Layout: FC<
  PropsWithChildren<{ className?: string; withNav?: boolean }>
> = ({ children, className, withNav = true }) => {
  const { stepIndex } = useGuide();
  const [isNavBarOpened, setIsNavBarOpened] = useState<boolean>(
    window.innerWidth > 1280
  );

  const isXLWidth = useRef(window.innerWidth >= 1280);

  const handleOpenNavBar = () => {
    setIsNavBarOpened(true);
  };

  const handleCloseNavBar = () => {
    setIsNavBarOpened(false);
  };

  useEffect(() => {
    const onResize = () => {
      isXLWidth.current = window.innerWidth >= 1280;
      if (
        (isXLWidth.current && !isNavBarOpened) ||
        (!isXLWidth.current && isNavBarOpened)
      ) {
        setIsNavBarOpened(isXLWidth.current && !isNavBarOpened);
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isNavBarOpened]);

  return (
    <main
      className={cn(
        `ag-w-full dark:ag-bg-neutral-900 ag-grid ag-grid-rows-[auto,1fr] xl:ag-flex ag-relative ag-font-inter ag-overflow-auto ag-h-dvh`,
        className
      )}
    >
      {/* {withNav && (
          <>
            <MobileNavBar onOpenNavbar={handleOpenNavBar} />
            <NavBar
              onClose={handleCloseNavBar}
              navbarOpened={isNavBarOpened}
              onOpenNavbar={handleOpenNavBar}
            />
          </>
        )} */}
      {/* <div
          className={`ag-flex ag-flex-col ag-w-full ag-h-full ag-overflow-auto`}
        > */}
      {children}
      {/* </div> */}
    </main>
  );
};
