import { UserStoreAction } from '../stores/useAuthStore';
import { AxiosResponse } from 'axios';
import {
  httpService,
  embedHttpService,
  httpInviteService,
  IVerifyResponse,
  getCookie,
  setCookie,
  ENVIRONMENT,
  TApiKeys
} from 'shared';

// V3 auth
interface Tokens {
  accessToken: string;
  accessTokenExpiresOn: string;
  refreshToken: string;
  refreshTokenExpiresOn: string;
}

export const authService = {
  login: async (credentials: Parameters<UserStoreAction['login']>['0']) => {
    const {
      data: {
        accessToken,
        accessTokenExpiresOn,
        refreshTokenExpiresOn,
        refreshToken
      }
    } = await httpService.post<Tokens>('/users/login', credentials);

    setCookie(
      `${ENVIRONMENT}_yepic_access_token`,
      accessToken,
      accessTokenExpiresOn
    );
    setCookie(
      `${ENVIRONMENT}_yepic_refresh_token`,
      refreshToken,
      refreshTokenExpiresOn
    );
  },
  logout: () => {
    return httpService.post('/users/logout');
  },
  register: (userData: Parameters<UserStoreAction['register']>['0']) => {
    return httpService.post('/users', userData);
  },
  forgotPassword: (
    email: Parameters<UserStoreAction['forgotPassword']>['0']
  ) => {
    return httpService.patch('/users/password/forgot', { email });
  },
  changePassword: (
    newPassword: Parameters<UserStoreAction['changePassword']>['0']
  ) => {
    return httpService.patch('/users/password', newPassword);
  },
  resetPassword: (querystring: string, password: string) => {
    return httpService.patch('/users/password/reset/' + querystring, {
      password
    });
  },
  changeEmail: (email: Parameters<UserStoreAction['changeEmail']>['0']) => {
    return httpService.patch('/users/email', email);
  },
  verifyEmail: async (
    data: string
  ): Promise<AxiosResponse<IVerifyResponse>> => {
    return await httpService.post('/users/email/verify/' + data);
  },
  resendVerificationEmail: async (
    email: string
  ): Promise<AxiosResponse<any>> => {
    return await httpService.post('/users/email/verify/reset', { email });
  },
  updateOrganizationName: (
    data: Parameters<UserStoreAction['updateOrganizationName']>['0']
  ) => {
    return httpService.post('/organization/updatename', data);
  },
  refreshToken: async () => {
    const oldRefreshToken = getCookie(`${ENVIRONMENT}_yepic_refresh_token`);
    if (!oldRefreshToken) return null;
    const {
      data: { accessToken, accessTokenExpiresOn }
    } = await httpService.post<Tokens>(
      '/users/token/refresh',
      {
        refreshToken: oldRefreshToken
      },
      {
        _retry: true
      } as object
    );

    setCookie(
      `${ENVIRONMENT}_yepic_access_token`,
      accessToken,
      accessTokenExpiresOn
    );
  },
  checkSuspension: async (email: string) => {
    const encodedEmail = encodeURIComponent(email);
    return await httpInviteService.get(`/v1/suspension/${encodedEmail}`);
  },
  getLivekitToken: async (
    data: {
      personaId: string;
    },
    keys?: TApiKeys
  ): Promise<{
    avatarId: string;
    voiceId: string;
    liveKitSeverUrl: string;
    liveKitUserToken: string;
    liveKitChatBotIdentity: string;
    liveKitUserIdentity: string;
  }> => {
    const headers = keys
      ? {
          'x-api-key': keys.apiKey,
          'x-agent-key': keys.agentKey
        }
      : {};

    const res = await httpService.post('chatstreams', data, { headers });
    return res.data;
  }
};
