import { personaStore } from 'entities/persona';
import { Layout } from 'widgets/layout';
import { PersonasList } from 'widgets/personas-list';
import { Loader } from 'shared/ui';
import { useEffect, useState } from 'react';
import { logger, useQueryParams } from 'shared/lib';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusPersonasList } from 'widgets/tavus-personas-list';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';

export const TavusDashboard = () => {
  const { tavusPersonasLoading, fetchPersonas, tavusPersonas } =
    tavusPersonaStore();

  useEffect(() => {
    if (tavusPersonas.length === 0) {
      fetchPersonas();
    }
  }, []);

  const params = useQueryParams();

  const [showSurvey, setShowSurvey] = useState<
    'posthog-agents-call' | 'posthog-agents-create' | boolean
  >(false);

  useEffect(() => {
    if (params.get('posthog-agents-call') === 'true')
      setShowSurvey('posthog-agents-call');

    if (params.get('posthog-agents-create') === 'true')
      setShowSurvey('posthog-agents-create');
  }, []);

  useEffect(() => {
    logger.debug('posthog survey is triggered', showSurvey);
  }, [showSurvey]);

  return (
    <Layout className="">
      {typeof showSurvey === 'string' && (
        <div id={showSurvey} className="absolute size-0"></div>
      )}
      {tavusPersonasLoading ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center -ag-pt-24">
          <Loader size={96} />
        </div>
      ) : (
        <TavusPersonasList />
      )}
    </Layout>
  );
};
