import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const FormError = ({ children }: Props) => {
  return (
    <p
      className="ag-mt-1 ag-text-xs ag-text-red-600 ag-text-left ag-block"
      id="email-error"
    >
      {children}
    </p>
  );
};

export default FormError;
