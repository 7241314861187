import { FC, ReactElement, useState } from 'react';
import { useFormik } from 'formik';
import FormError from '../FormError/FormError';
import { AiFillGithub } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import { FilledButton, Loader, OutlinedButton } from '../../../shared';
import { AxiosError } from 'axios';
import { useAuthStore } from '../../../stores/useAuthStore';

type Props = {
  onClose: () => void;
};

type ValidationErrors = {
  password?: string;
  email?: string;
};

const validate = (values: { password: string; email: string }) => {
  const errors: ValidationErrors = {};
  if (!values.password) {
    errors.password = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  return errors;
};

const errorsMap: Record<string, string> = {
  InvalidCredentials: 'The email or password is incorrect.',
  UserIsNotActive: 'User account locked'
};

const LoginForm: FC<Props> = ({ onClose }) => {
  const [responseStatus, setResponseStatus] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { login } = useAuthStore();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        await login(values);
        setResponseStatus(null);
        onClose();
      } catch (e: any) {
        if (e instanceof AxiosError) {
          setResponseStatus(
            e.response?.data?.errorCode ||
              'Sorry, our server is experiencing some issues. Please try again later.'
          );
        } else {
          if (e?.message) setResponseStatus(e.message);
        }
      }
      resetForm();
      setIsLoading(false);
    }
  });
  return (
    <form
      className="bg-white md:rounded-xl md:w-full flex max-md:justify-center max-md:w-screen max-md:min-h-screen flex-col md:gap-5 z-20 h-full px-6 pb-4 pt-6 md:pt-5 md:my-8 md:p-6"
      onSubmit={formik.handleSubmit}
    >
      <div className="max-md:mb-5 flex flex-col justify-center items-center gap-1">
        <div className="bg-no-repeat bg-cover w-[43px] h-[30px] mb-3" />
        {/* TODO: Fix imports */}
        <h2 className=" text-lg font-medium text-neutral-900 leading-7">
          Sign in
        </h2>
        <p className="text-neutral-600 text-sm font-light">
          Access your account to start a conversation
        </p>
      </div>
      <div className="max-md:mb-8 flex flex-col gap-4 md:gap-3 text-sm">
        <div className="flex flex-col text-sm">
          <label className="flex flex-col gap-1.5 text-neutral-700">
            Email*
            <input
              className={`border border-neutral-300 py-2 px-3 rounded-md 
                            ${
                              formik.errors.email && formik.touched.email
                                ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:outline-red-500  focus:border-red-500'
                                : 'text-gray-900 placeholder:text-gray-400 focus:outline-secondary'
                            }`}
              placeholder="Enter your email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
            />
            {formik.errors.email && formik.touched.email ? (
              <FormError>{formik.errors.email}</FormError>
            ) : null}
          </label>
        </div>
        <div className="flex flex-col text-sm">
          <label className="flex flex-col gap-1.5 text-neutral-700">
            Password*
            <input
              className={`border border-neutral-300 py-1.5 px-3 rounded-md 
                            ${
                              formik.errors.password && formik.touched.password
                                ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
                                : 'text-gray-900 placeholder:text-gray-400 focus:ring-secondary focus:border-secondary '
                            }`}
              placeholder="•••••••"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              type="password"
            />
            {formik.errors.password && formik.touched.password ? (
              <FormError>{formik.errors.password}</FormError>
            ) : null}
          </label>
        </div>
      </div>
      <FormError>
        {responseStatus && responseStatus in errorsMap
          ? errorsMap[responseStatus]
          : responseStatus}
      </FormError>
      <a
        className="w-full text-end text-secondary font-semibold"
        href={`${process.env.REACT_APP_STUDIO_DOMAIN}/forgot-password`}
      >
        Forgot password
      </a>
      <div className="max-md:mb-6 flex flex-col gap-2">
        <FilledButton className="py-2 text-base h-11" type="submit">
          {isLoading ? <Loader isButton /> : 'Sign in'}
        </FilledButton>
        {/* <div className="flex justify-around gap-2 hidden"> */}
        <div className="justify-around gap-2 hidden">
          <OutlinedButton className="w-full gap-2 font-semibold leading-6 text-neutral-700 py-2">
            <AiFillGithub size={28} />
            Github
          </OutlinedButton>
          <OutlinedButton className="w-full gap-2 font-semibold leading-6 text-neutral-700 py-2">
            <FcGoogle size={28} />
            Google
          </OutlinedButton>
        </div>
      </div>
      <div className="flex items-center justify-center gap-1 text-base">
        <p className="font-light leading-7">Are you new?</p>
        <a
          className="text-secondary font-semibold"
          href={`${process.env.REACT_APP_STUDIO_DOMAIN}/register`}
        >
          Sign up
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
