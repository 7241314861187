import { useParams } from 'react-router';
import { useEffect } from 'react';
import { FilledButton, Loader, showError, useQueryParams } from 'shared';

import { tavusPersonaStore } from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { conversationStore } from 'entities/tavus/conversation';
import { TavusEmbedConference } from './TavusEmbedConference';

export const TavusCallEmbed = () => {
  const { startConversation, currentConversation, conversationLoading } =
    conversationStore();
  const { fetchOne, currentPersona, setCurrentPersona } = tavusPersonaStore();
  const { id } = useParams();
  const { getReplicaVideo } = replicaStore();
  useEffect(() => {
    getPersonaData(id);
  }, []);

  const getPersonaData = async (id: string | undefined) => {
    if (!id) return showError("Can't get agent. Please check embed url");
    const persona = await fetchOne(id).then((success) => {
      !success &&
        showError(
          'Unable to get persona data, please check embed url or try again later'
        );
      if (success) {
        console.log('success', success);
        return success;
      }
    });
    if (persona) {
      setCurrentPersona(persona);
    }
  };

  const startCall = () => {
    if (!id) return showError("Can't get agent. Please check embed url");
    startConversation(currentPersona);
  };

  return (
    <div className={`ag-w-full ag-bg-white ag-text-neutral-900`}>
      {!currentPersona ? (
        <div className="ag-w-full ag-h-screen ag-flex ag-items-center ag-justify-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="ag-h-screen ag-flex ag-flex-col ag-overflow-hidden">
          <div className="ag-relative ag-z-50 ag-left-0 ag-top-0 ag-w-full ag-border-b ag-bg-white">
            <div className="ag-flex ag-flex-col ag-justify-center ag-items-center ag-p-3 ag-text-xs">
              <div className="ag-font-semibold">
                {currentPersona.persona_name}
              </div>
              <div className="ag-text-neutral-600">Powered by yepic ai</div>
            </div>
          </div>

          <>
            {conversationLoading ? (
              <div className="ag-w-full ag-bg-white ag-flex ag-items-center ag-justify-center ag-h-screen">
                <Loader size={50} />
              </div>
            ) : !currentConversation ? (
              <div className="ag-w-full ag-grow ag-flex ag-justify-center ag-items-center">
                <div className="ag-w-56 lg:ag-w-96 ag-flex ag-flex-col ag-items-center ag-justify-center ag-mx-auto ">
                  <div className="ag-w-56 ag-h-56 ag-rounded-full ag-overflow-hidden ag-mb-7">
                    <video
                      className="ag-w-full ag-h-full ag-object-cover"
                      src={getReplicaVideo(currentPersona.default_replica_id)}
                    />
                  </div>
                  <FilledButton
                    className="ag-w-full ag-py-2 ag-mb-7"
                    onClick={startCall}
                  >
                    Start new chat
                  </FilledButton>
                </div>
              </div>
            ) : (
              <div className={`ag-bg-white ag-grow ag-shrink-0`}>
                <TavusEmbedConference />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};
