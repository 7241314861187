import React, { useEffect, useRef, useState } from 'react';

import { AiFillCaretDown } from 'react-icons/ai';
import { capitalize } from '../utils/utils';

type SelectInputProps = {
  name: string;
  options: string[];
  value: string | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  className?: string;
  error?: string | boolean;
  showFont?: boolean;
  scroll?: boolean;
  placeholder?: string;
  borderColorClass?: string;
};

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  options,
  value,
  onChange,
  disabled,
  className,
  error,
  showFont = false,
  scroll = true,
  placeholder = 'Select...',
  borderColorClass = 'border-tertiary'
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`group relative ${className}`}>
      {disabled ? (
        <div
          className={`block px-3 pr-8 py-2 border ${borderColorClass} disabled:${borderColorClass} shadow-sm rounded-md w-full`}
        >
          <div></div>
        </div>
      ) : (
        <div
          className={`px-3 py-2 border ${borderColorClass} focus:outline-none shadow-sm  focus:ring-secondary focus:border-secondary rounded-md w-full cursor-pointer relative flex items-center justify-between ${
            error && 'border-red-500'
          }`}
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <span>{value ? capitalize(value) : placeholder}</span>
          <AiFillCaretDown className={`${isOpen && 'transform rotate-180'}`} />
          <div
            className={`absolute w-full max-h-[10rem] top-12 py-1 px-1 left-0 bg-white z-50 border ${borderColorClass} rounded-lg ${
              isOpen ? 'block' : 'hidden'
            } ${scroll ? 'overflow-y-scroll' : 'overflow-y-auto'}`}
          >
            {options.map((o, ind) => (
              <option
                onClick={() => onChange(o)}
                key={`${name}-${o}`}
                value={o}
                style={showFont ? { fontFamily: o } : {}}
                className={`py-2 px-2 ${
                  o === value && 'bg-slate-200 rounded-lg'
                }`}
              >
                {capitalize(o)}
              </option>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
