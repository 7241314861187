import Modal from '../../../components/shared/Modal';
import { personaStore, Visibility } from 'entities/persona';
import { BiX } from 'react-icons/bi';
import Divider from '../../../components/Divider';
import { FilledButton, Toggle } from 'shared/ui';
import Input from '../../../components/shared/Input';
import {
  CALL_PERSONA_ROUTE,
  showError,
  showSuccessMessage
} from 'shared/model';
import { CreditsWarning } from 'features/embed-persona';
import { LuCopy } from 'react-icons/lu';
import { useAuthStore } from 'stores/useAuthStore';
import { logger } from 'shared/lib';

const generateShareLink = (personaId: string, apiKey: string) => {
  const origin = window.location.origin;
  const url = `${origin}${CALL_PERSONA_ROUTE.replace(
    ':id',
    personaId
  )}?agentKey=${apiKey}&shared=true`;

  return url;
};

export const ShareModal = () => {
  const { setPersonaShareModal, personaShareModal, updateVisibility } =
    personaStore();

  const { agentKey } = useAuthStore();

  const isDisabled =
    personaShareModal?.visibility === Visibility.UNKNOWN ||
    personaShareModal?.visibility === Visibility.PRIVATE;

  if (!personaShareModal) return null;

  if (!agentKey) {
    showError('Agent sharing key is missing');
    setPersonaShareModal(null);
  }

  return (
    <Modal
      setOpen={() => setPersonaShareModal(null)}
      open={!!personaShareModal}
      closeButton={
        <BiX
          onClick={() => setPersonaShareModal(null)}
          className="ag-cursor-pointer ag-w-11 ag-h-11 ag-p-2.5 ag-absolute ag-right-2.5 ag-top-2.5"
        />
      }
      noPadding
      className="!ag-w-[464px] max-sm:ag-mt-auto max-sm:ag-mb-2.5"
    >
      <div className="ag-text-left">
        <div className="ag-p-6 ag-pb-5">
          <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
            Share Video Agent
          </h2>
        </div>
        <Divider className="ag-border-neutral-200" />
        <div className="ag-p-6 ag-pb-0">
          <CreditsWarning />
        </div>
        <div className="ag-p-6 ag-space-y-4">
          <Divider className="ag-border-neutral-200" />
          {personaShareModal?.visibility !== Visibility.PUBLIC && (
            <>
              <div className="ag-flex ag-items-center ag-justify-between">
                <span className="ag-text-neutral-700 ag-font-medium">
                  Enable sharing
                </span>

                <Toggle
                  enabled={
                    personaShareModal?.visibility === Visibility.UNLISTED
                  }
                  setEnabled={(isUnlisted) => {
                    personaShareModal &&
                      updateVisibility(
                        personaShareModal?.id,
                        isUnlisted ? Visibility.UNLISTED : Visibility.PRIVATE
                      );
                  }}
                />
              </div>
              <span className="ag-text-sm ag-text-neutral-700">
                Anyone with the link can chat with this Video Agent.
              </span>
            </>
          )}
          <div className="ag-flex ag-items-center ag-justify-between ag-mt-6 ag-gap-2">
            <Input
              className="ag-w-full ag-py-2.5 ag-px-3.5 ag-text-neutral-900 ag-text-base ag-cursor-copy"
              onMouseDown={() =>
                !isDisabled &&
                navigator.clipboard
                  .writeText(
                    generateShareLink(
                      personaShareModal.id as string,
                      agentKey as string
                    )
                  )
                  .then(() => showSuccessMessage('Share link copied'))
              }
              disabled={isDisabled}
              readOnly
              value={generateShareLink(
                personaShareModal.id as string,
                agentKey as string
              )}
            />
            <FilledButton
              className="ag-py-2.5 ag-ml-1 ag-px-[1.125rem] ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-gap-3 ag-text-base"
              disabled={isDisabled}
              onClick={() =>
                !isDisabled &&
                navigator.clipboard
                  .writeText(
                    generateShareLink(
                      personaShareModal.id as string,
                      agentKey as string
                    )
                  )
                  .then(() => showSuccessMessage('Share link copied'))
              }
            >
              <LuCopy className="ag-text-xl" />
              <div>Copy</div>
            </FilledButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
