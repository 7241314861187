import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import {
  ListPersonas,
  ITavusPersona,
  ICreateTavusPersonaReq,
  ICreateTavusPersonaRes
} from '../model';

const tavusPersonasEndpoint = '/personas';

export const tavusPersonaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<ITavusPersona> = await tavusHttpService.get(
      `${tavusPersonasEndpoint}/${id}`
    );

    return data;
  },
  delete: (id: string) => {
    return tavusHttpService.delete(`${tavusPersonasEndpoint}/${id}`);
  },
  fetchAll: async () => {
    const { data }: AxiosResponse<ListPersonas> = await tavusHttpService.get(
      tavusPersonasEndpoint + '?persona_type=user&limit=100'
    );

    return data.data;
  },
  create: async (personaData: ICreateTavusPersonaReq) => {
    const { data }: AxiosResponse<ICreateTavusPersonaRes> =
      await tavusHttpService.post(tavusPersonasEndpoint, personaData);

    return data;
  }
};
