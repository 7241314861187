import { IAvatar } from 'shared/model';

export interface IPersonaList {
  id: string;
  name: string;
  avatarUrl: string;
  voice: string;
  description: string;
  visibility: Visibility;
  definition: string;
  greeting: string;
  createdDate: Date;
  canEdit?: boolean;
}

interface IAsset {
  extension: `.${string}`;
  id: string;
  name: string;
  url: string;
}

export enum Visibility {
  UNKNOWN = 'Unknown',
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  UNLISTED = 'Unlisted'
}

export interface IPersona extends Omit<IPersonaList, 'avatarUrl'> {
  avatar: Pick<
    IAvatar,
    | 'id'
    | 'name'
    | 'gender'
    | 'menuImageUrl'
    | 'inputImageUrl'
    | 'idleLoopVideoUrl'
  > & {
    upperBody512LoopUrl: string;
    upperBody1024LoopUrl: string;
  };
  model: string;
  knowledgeSources?: IKnowledge[];
  starterQuestions?: IStarterQuestion[];
  callToActions?: ICallToAction[];
  voiceDetail?: {
    id: string;
    name: string;
    voiceId: string;
    gender: string;
    providerVoiceId: string;
    language: string;
    languageCode: string;
    languageCodesJson: string;
    provider: string;
    privateVoice: boolean;
  };
  introMessages?: IIntroMessage[];
}

export interface IPersonaFormik {
  name: string;
  model: string;
  avatarId: string;
  voice: string;
  description: string;
  visibility: Visibility;
  definition: string;
  greeting: string;
  knowledgeSources: IKnowledgeReq[];
  starterQuestions: IStarterQuestionReq[];
  callToActions: ICallToAction[];
  introMessages: IIntroMessage[];
}

export interface ICreatePersonaReq extends Partial<IPersonaFormik> {}
//   name?: string;
//   model?: string;
//   avatarId?: string;
//   voice?: string;
//   description?: string;
//   visibility?: Visibility;
//   definition?: string;
//   greeting?: string;
//   knowledgeSources?: IKnowledgeReq[];
//   starterQuestions?: IStarterQuestionReq[];
//   callToActions?: ICallToAction[];
//   introMessages?: IIntroMessage[];
// }

export interface IIntroMessage {
  seconds: number;
  videoUrl: string;
}

export interface IStarterQuestionReq {
  question: string;
}

export interface IStarterQuestion extends IStarterQuestionReq {
  id: string;
}

export interface ICallToAction {
  action: string;
  link: string;
}

export interface IKnowledgeReq {
  assetId: string;
  type: KnowledgeType;
}

export interface IKnowledge extends IKnowledgeReq {
  id: string;
  asset: IAsset;
}

export interface IKnowledgeAdd extends IKnowledgeReq {
  filename: string;
}

export type KnowledgeType = 'Unknown' | 'Grounded' | 'Hybrid' | 'Ungrounded';
