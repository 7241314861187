import { AxiosError } from 'axios';
import { logger } from './logger';

export const getErrorMessage = (
  err: unknown,
  baseErrorMessage: string = '',
  prefix: string = ''
): string => {
  if (err instanceof AxiosError) {
    return prefix + (err.response?.data.message || err.message);
  }

  if (err instanceof Error) return prefix + err.message;

  if (typeof err === 'string') return prefix + err;

  if (
    typeof err === 'object' &&
    err &&
    'message' in err &&
    typeof err.message === 'string'
  )
    return prefix + err.message;

  return prefix + (baseErrorMessage || 'Unknown error');
};

export const getErrorMessageV2 = (
  err: unknown,
  fallbackMessage: string = '',
  showFallbackWError: boolean = false
) => {
  if (err instanceof AxiosError) {
    if (err.response && err.response.data) {
      const res = err.response.data;

      if (typeof res === 'string') {
        return 'An unexpected error occured. Please try again later.';
      }
      if ('message' in res) {
        return showFallbackWError
          ? fallbackMessage + ' Error: ' + res.message
          : res.message;
      }
      if ('title' in res) {
        return showFallbackWError
          ? fallbackMessage + ' Error: ' + res.title
          : res.title;
      }
    } else {
      return (
        fallbackMessage ||
        'An unexpected error occured. Please try again later.'
      );
    }
  }

  return (
    fallbackMessage || 'An unexpected error occured. Please try again later.'
  );
};
