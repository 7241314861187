const Checkbox = ({
  label,
  onClick,
  choosenValues,
  value
}: {
  label: string;
  onClick: (gender: string) => void;
  choosenValues: string[];
  value: string;
}) => {
  return (
    <label className="ag-flex ag-items-center">
      <input
        type="checkbox"
        className="ag-hidden"
        onChange={(e) => {
          onClick(e.target.value);
        }}
        checked={choosenValues.includes(value)}
        value={value}
      />
      <svg
        className="ag-w-4 ag-h-4 ag-inline ag-mr-2"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="#FCF5FF"
          className={`${
            choosenValues.includes(value) ? 'ag-visible' : 'ag-hidden'
          }`}
        />
        <path
          d="M12 5L6.5 10.5L4 8"
          stroke="#C34DFF"
          strokeWidth="1.6666"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`${
            choosenValues.includes(value) ? 'ag-visible' : 'ag-hidden'
          }`}
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#D4D4D4"
          fill={`${choosenValues.includes(value) ? 'none' : '#fff'}`}
        />
      </svg>
      <span className="ag-text-sm ag-whitespace-nowrap">{label}</span>
    </label>
  );
};

export default Checkbox;
