import { httpService, logger } from '../../../shared';
import { CustomPhotoDetails } from '../../../components/SideBar/Avatars/CustomTalkingPhotoFlow/types/types';

const avatarEndpoint = '/avatars';

const avatarApi = {
  fetchAll: async () => {
    const { data } = await httpService.get(avatarEndpoint);
    return data;
  },
  createCustomTalkingPhoto: async (payload: CustomPhotoDetails) => {
    const data = await httpService.post(avatarEndpoint, payload);
    return data;
  },
  deleteCustomTalkingPhoto: async (id: string) => {
    const data = httpService.delete(avatarEndpoint + `/${id}`);
    return data;
  }
};

export default avatarApi;
