import { useEffect, useRef, useState } from 'react';

import { GoChevronDown } from 'react-icons/go';

import { leadingZero, isArabic } from 'shared';

import { videoCallStore } from 'features/livekit-call';

export const UnderTheHood = () => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { messages, persona } = videoCallStore();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight
      });
    }
  }, [messages, open]);

  return (
    <div className="ag-border-t ag-border-neutral-600 ag-py-4 ag-px-6">
      <button
        className="ag-text-white ag-flex"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span className="ag-mr-2">Under The Hood</span>
        <GoChevronDown className="ag-w-6 ag-h-6" />
      </button>
      {open && (
        <div className="ag-pt-6">
          <div>
            <span>Transcription</span>
            <div
              className="ag-text-sm ag-mt-2 ag-rounded-md ag-border ag-border-neutral-600 ag-bg-neutral-700 ag-py-3 ag-px-3.5 ag-h-44 ag-scrollbar-none ag-overflow-y-auto ag-flex ag-flex-col ag-gap-1.5"
              ref={ref}
            >
              {(messages || [])
                .filter((m) => m.role !== 'system')
                .map((m, i) => {
                  const date = new Date(m.timestamp);
                  return (
                    <div
                      key={`${m.role}${m.content}${i}`}
                      className={`ag-flex ag-gap-2 ${
                        isArabic(m.content)
                          ? 'ag-flex-row-reverse ag-text-right'
                          : ''
                      }`}
                    >
                      <div>
                        <span
                          className={
                            m.role === 'user'
                              ? 'ag-text-green-400'
                              : 'ag-text-primary-400'
                          }
                        >
                          {m.role === 'user'
                            ? 'You'
                            : persona?.name ?? 'Unknown avatar'}
                        </span>
                        <span
                          className={
                            m.role === 'user'
                              ? 'ag-ml-2 ag-text-green-400'
                              : 'ag-ml-2 ag-text-primary-400'
                          }
                        >
                          {leadingZero(date.getHours())}:
                          {leadingZero(date.getMinutes())}
                          <span className="ag-hidden sm:ag-inline">
                            :{leadingZero(date.getSeconds())}.
                            {leadingZero(date.getMilliseconds())}
                          </span>
                          :
                        </span>
                      </div>
                      {m.affect?.map((affect) => (
                        <div
                          key={affect}
                          className={`py-0.5 ag-px-2 ag-text-xs ag-flex ag-justify-center ag-items-center ag-rounded-2xl ${
                            m.role === 'user'
                              ? 'ag-bg-[rgb(2,122,72)] ag-text-[rgb(236,253,243)]'
                              : 'ag-bg-primary-700 ag-text-primary-50'
                          }`}
                        >
                          {affect}
                        </div>
                      ))}
                      <span>{m.content}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
