import { Ref, RefObject, useEffect } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLDivElement> | null,
  showFunc: (show: boolean) => void
) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (ref?.current && !ref?.current.contains(e.target as Node)) {
      showFunc(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
};
