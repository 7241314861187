import { FC } from 'react';
import Voice from '../../../components/SideBar/Voices/Voice';
import { IAvatar, IVoice } from 'shared/model';
import voiceStore from '../../../stores/useVoiceStore';
import { FormikErrors } from 'formik';
import { ICreatePersonaReq } from 'entities/persona';
import { usePlaySound } from 'features/play-sound';
import { VoiceFilter } from 'entities/voice/ui/VoiceFilter';
import { Loader } from 'shared/ui';

type Props = {
  avatarGender?: IAvatar['gender'];
  voice: string;
  onChange: (
    value: string
  ) =>
    | Promise<void>
    | Promise<FormikErrors<ICreatePersonaReq>>
    | Promise<void | FormikErrors<ICreatePersonaReq>>;
};

export const ChooseVoice: FC<Props> = ({
  voice: voiceId,
  avatarGender,
  onChange
}) => {
  const { filteredVoices, loading } = voiceStore();

  const handleChangeVoice = (voice: IVoice) => {
    onChange(voice.voiceId);
  };

  const { currentAudio, isPlaying, handlePauseAudio, handlePlayAudio } =
    usePlaySound();

  return (
    <>
      <div className="ag-mb-6">
        <VoiceFilter />
      </div>
      <div className="ag-h-full ag-flex ag-flex-col ag-gap-y-2 ag-overflow-auto ag-scrollbar-none ag-text-white ag-pb-20">
        {loading ? (
          <div className="ag-flex ag-justify-center">
            <Loader />
          </div>
        ) : filteredVoices?.length ? (
          filteredVoices.map((voice) => (
            <Voice
              key={voice.voiceId + voice.gender}
              voice={voice}
              doesGenderMatch={
                avatarGender
                  ? avatarGender.toLowerCase() === voice.gender
                  : true
              }
              onPause={handlePauseAudio}
              selected={voiceId === voice.voiceId}
              onPlay={handlePlayAudio}
              currentAudio={currentAudio}
              isPlaying={isPlaying}
              onHandleChange={handleChangeVoice}
            />
          ))
        ) : (
          <div className="ag-text-base ag-w-full ag-mx-auto ag-p-3 ag-text-center">
            No voices found matching filters
          </div>
        )}
      </div>
    </>
  );
};
