import type { PropsWithChildren } from 'react';
import { createContext, useEffect, useState } from 'react';
import { GuideContextI, GuideStepI, GuideSteps } from '../shared/model/types';
import { useNavigate } from 'react-router';

export const GuideContext = createContext<GuideContextI>({
  step: null,
  stepIndex: null,
  handleStartGuide: () => {},
  handleEndGuide: () => {},
  handleNextStep: () => {},
  stepsLength: 0,
  isMobile: false
});

const guideSteps: readonly GuideStepI[] = [
  {
    name: GuideSteps.TYPE_OR_SPEAK,
    text: 'Send a message with a text or audio prompt.',
    position: 'bottom-right',
    hash: ''
  },
  {
    name: GuideSteps.AVATARS,
    text: 'Select an avatar for your chat or upload your own talking photo.',
    position: 'right-top',
    hash: 'avatar'
  },
  {
    name: GuideSteps.VOICES,
    text: 'Give your avatar a voice from our world-leading providers.',
    position: 'right-top',
    hash: 'voice'
  },
  {
    name: GuideSteps.STYLE,
    text: 'Customise your chatbot with your own style.',
    position: 'right-top',
    hash: 'style'
  },
  {
    name: GuideSteps.ENGINE,
    text: 'Finally, choose your chatbot engine or integrate your own.',
    position: 'right-top',
    hash: 'engine'
  }
] as const;

const mobileGuideSteps: readonly GuideStepI[] = [
  {
    name: GuideSteps.TYPE_OR_SPEAK,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'bottom-center',
    hash: ''
  },
  {
    name: GuideSteps.OPEN_TOOLS_PANEL,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'top-right',
    hash: ''
  },
  {
    name: GuideSteps.AVATARS,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'top-center',
    hash: 'avatar'
  },
  {
    name: GuideSteps.VOICES,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'top-center',
    hash: 'voice'
  },
  {
    name: GuideSteps.STYLE,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'top-center',
    hash: 'style'
  },
  {
    name: GuideSteps.ENGINE,
    text: 'Lorem ipsum dolor sit amet consectetur. Vitae bibendum nulla et urna convallis elit lobortis. Magna rhoncus mattis lorem sed euismod massa tempor leo convallis.',
    position: 'top-center',
    hash: 'engine'
  }
] as const;

const GuideProvider = ({ children }: PropsWithChildren) => {
  const [step, setStep] = useState<GuideStepI | null>(null);
  const [stepIndex, setStepIndex] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const handleStartGuide = () => {
    const isMobile = window.innerWidth < 1024;
    const guide = isMobile ? mobileGuideSteps : guideSteps;
    setIsMobile(isMobile);
    setStep({ ...guide[0] });
    setStepIndex(1);
    nav({
      hash: guide[0].hash
    });
  };
  const handleEndGuide = () => {
    const guide = isMobile ? mobileGuideSteps : guideSteps;
    setStep(null);
    setStepIndex(null);
    if (stepIndex === guide.length) {
      nav({
        hash: ''
      });
    }
  };
  useEffect(() => {
    const onResize = () => {
      if (
        isMobile &&
        typeof stepIndex === 'number' &&
        window.innerWidth > 1024
      ) {
        handleEndGuide();
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isMobile, stepIndex]);

  const nav = useNavigate();

  const handleNextStep = () => {
    if (!step || !stepIndex) return;
    const guide = isMobile ? mobileGuideSteps : guideSteps;
    if (stepIndex === guide.length) {
      return handleEndGuide();
    }
    const nextStep = { ...guide[stepIndex] };
    setStep(nextStep);
    nav({ hash: nextStep.hash });
    setStepIndex(stepIndex + 1);
  };

  return (
    <GuideContext.Provider
      value={{
        step,
        stepIndex,
        handleStartGuide,
        handleNextStep,
        handleEndGuide,
        stepsLength: isMobile ? mobileGuideSteps.length : guideSteps.length,
        isMobile
      }}
    >
      {children}
    </GuideContext.Provider>
  );
};

export default GuideProvider;
