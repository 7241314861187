import { useEffect, useState } from 'react';

export const useAutoPlayVideo = () => {
  const [autoplayAllowed, setAutoplayAllowed] = useState(false);

  const handleUserInteraction = () => {
    // User interaction detected, enable autoplay
    setAutoplayAllowed(true);

    // Clean up the event listeners once autoplay is allowed
    window.removeEventListener('mousedown', handleUserInteraction);
    window.removeEventListener('keydown', handleUserInteraction);
    window.removeEventListener('touchstart', handleUserInteraction);
  };

  useEffect(() => {
    // Add event listeners for common user interactions
    window.addEventListener('mousedown', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  return { autoplayAllowed };
};
