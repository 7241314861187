import { StateStorage } from 'zustand/middleware/persist';
import { getCookie, setCookie } from './cookie';

export const cookieStorage = (
  expires?: Date | number | string,
  cookieName?: string
): StateStorage => ({
  getItem: (name: string) => {
    return getCookie(cookieName || name);
  },
  setItem: (name: string, value: string) => {
    setCookie(cookieName || name, value, expires);
  },
  removeItem: async (name: string): Promise<void> => {
    setCookie(cookieName || name, '', 0);
  }
});
