import { create } from 'zustand';
import { logger, throttle } from 'shared';
import { Source } from './Source';

export type MorphState = {
  loading: boolean;
  isWebcam: boolean;
  dominantEmotion: string;
  affects: Record<string, number>;
};

type Actions = {
  load: (
    aiSdkState: 'loading' | 'idle' | 'ready' | 'error',
    mphToolsState: 'loading' | 'idle' | 'ready' | 'error'
  ) => Promise<void>;
  start: (ref: HTMLVideoElement) => Promise<void>;
  stop: () => Promise<void>;
  bindEvents: () => () => void;
  getDominantAffect: () => string[];
};

export const useMorph = create<MorphState & Actions>((set, get) => ({
  loading: false,
  isWebcam: false,
  dominantEmotion: '',
  affects: {},
  getDominantAffect: () => {
    logger.debug({ affects: get().affects });
    return Object.entries(get().affects).reduce(
      ({ affects, value }, [affectName, affectValue]) => {
        if (affectValue > value) {
          return {
            affects: [affectName],
            value: affectValue
          };
        }
        if (affectValue === value) {
          return {
            affects: [...affects, affectName],
            value
          };
        }
        return {
          affects,
          value
        };
      },
      { value: 0, affects: [] } as { value: number; affects: string[] }
    ).affects;
  },
  load: async (
    aiSdkState: 'loading' | 'idle' | 'ready' | 'error',
    mphToolsState: 'loading' | 'idle' | 'ready' | 'error'
  ) => {
    set({ loading: true });
    if (aiSdkState === 'ready' && mphToolsState === 'ready') {
      await getAiSdkControls();
    }
    set({ loading: false });
  },
  start: async (toVideoElement) => {
    const { start, source } = await getAiSdkControls();
    await source.useCamera({
      toVideoElement
    });
    await start();
    set({ isWebcam: true });
  },
  stop: async () => {
    const { stop } = await getAiSdkControls();
    await stop();
    set({ isWebcam: false, dominantEmotion: '', affects: {} });
  },
  bindEvents: () => {
    const onEmotionResult = (evt: any) => {
      if (!get().isWebcam) return;
      set({ dominantEmotion: evt.detail.output.dominantEmotion || '' });
    };

    const onEventBarrierResult = throttle((evt: any) => {
      if (!get().isWebcam) return;
      set({ affects: evt.detail.output.affects38 });
    }, 1000);

    window.addEventListener('CY_FACE_EMOTION_RESULT', onEmotionResult);
    window.addEventListener(
      'CY_FACE_AROUSAL_VALENCE_RESULT',
      onEventBarrierResult
    );

    return () => {
      window.removeEventListener('CY_FACE_EMOTION_RESULT', onEmotionResult);
      window.removeEventListener(
        'CY_FACE_AROUSAL_VALENCE_RESULT',
        onEventBarrierResult
      );
    };
  }
}));

/* Prevents globalThis being reported as an error by eslint */
/* global globalThis */

// Singleton
let aiSdkInstance: any;
let source: any;

async function initAiSdk() {
  if (aiSdkInstance) {
    throw new Error('An instance of the AI-SDK is already running.');
  }
  source = new Source();

  aiSdkInstance = await window.CY.loader()
    .licenseKey('sk795a6705b0837e76c1c8187b8c9f3ea6b583c321621b') // <--- ##############
    .source(source)
    .addModule(window.CY.modules().FACE_EMOTION.name, {
      enableBalancer: false, // example of custom setting
      smoothness: 0.5
    })
    .addModule(window.CY.modules().FACE_AROUSAL_VALENCE.name, {
      smoothness: 0.9 // example of custom setting
    })
    .load();
}

/**
 * Loads the MorphCast SDK, only the first time, then returns the controls for the start / stop
 *
 * @returns {Promise<{getModule: *, stop: *, CY: *, start: *, source: *}>}
 */
export async function getAiSdkControls() {
  if (aiSdkInstance === undefined) {
    await initAiSdk();
  }

  const { start, stop, getModule } = aiSdkInstance;
  return { start, stop, getModule, source, CY: window.CY };
}
