import { AxiosResponse } from 'axios';
import { httpService } from 'shared/api';
import { logger } from 'shared/lib';
import { IAsset } from '../model';

const assetsEndpoint = '/assets';

export const assetApi = {
  uploadFile: async (
    formData: FormData,
    onUploadProgress?: (e: any) => void
  ): Promise<IAsset> => {
    const { data } = await httpService.post(
      assetsEndpoint + '/import/file',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return data;
  }
};
