import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { cn } from 'shared/lib';

export const FilledButton: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className = '', type = 'button', ...props }) => {
  return (
    <button
      className={cn(
        'ag-bg-primary-600 ag-rounded-md ag-flex ag-justify-center ag-items-center ag-text-white ag-text-sm disabled:ag-bg-primary-200',
        className
      )}
      type={type}
      {...props}
    />
  );
};
