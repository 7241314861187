import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import talkingPhotoDialogImage from '../images/talkPhotoDialogImg.png';
import logo from '../images/logo.png';
import { useNavigate } from 'react-router';
import { MAIN_ROUTE } from 'shared/model';

type SideBarProps = {
  currentStep: number | null;
  showSupportButton?: boolean;
};

const SideBar: React.FC<SideBarProps> = ({
  currentStep,
  showSupportButton = false
}) => {
  return (
    <div className="md:ag-min-w-[200px] ag-bg-dark-primary ag-relative ag-pt-4 sm:ag-pl-3 ag-rounded-tl-lg ag-rounded-tr-lg md:ag-rounded-tr-none md:ag-rounded-bl-lg ">
      <div>
        <img
          src={logo}
          alt="logo"
          className="ag-inline-block ag-shrink-0 ag-w-8 xl:ag-w-12 ag-mr-2"
        />
        <span className="ag-text-xs ag-text-gray-400">My Talking Photos</span>
      </div>
      {(currentStep || currentStep === 0) && (
        <div className="ag-flex ag-justify-center md:ag-block ag-text-xs md:ag-text-base ag-mt-4 md:ag-mt-11 sm:ag-ml-2 ag-text-gray-400 ag-pb-4 ag-relative">
          <div
            className={`ag-mb-1 md:ag-mb-4 ag-mr-3 md:ag-mr-0 ${
              currentStep > 0 && 'ag-text-secondary'
            }`}
          >
            {currentStep > 1 ? (
              <BsFillCheckCircleFill className="ag-inline-block ag-mr-1 md:ag-mr-2 ag-text-secondary" />
            ) : (
              <span className="ag-mr-2 ag-inline-block ag-w-3">1.</span>
            )}
            Upload photo
          </div>
          <div
            className={`ag-mb-1 md:ag-mb-4 ag-mr-3 md:ag-mr-0 ${
              currentStep > 1 && 'ag-text-secondary'
            }`}
          >
            {currentStep > 2 ? (
              <BsFillCheckCircleFill className="ag-inline-block ag-mr-1 md:ag-mr-2 ag-text-secondary" />
            ) : (
              <span className="ag-mr-2 ag-inline-block ag-w-3">2.</span>
            )}
            Details
          </div>
          <div
            className={`ag-mb-1 md:ag-mb-4 ag-mr-3 md:ag-mr-0 ${
              currentStep > 2 && 'ag-text-secondary'
            }`}
          >
            {currentStep > 3 ? (
              <BsFillCheckCircleFill className="ag-inline-block ag-mr-1 md:ag-mr-2 ag-text-secondary" />
            ) : (
              <span className="ag-mr-2 ag-inline-block ag-w-3">3.</span>
            )}
            Review
          </div>
          {/* <div className={`${currentStep > 3 && 'ag-text-secondary'}`}>
            <span className="ag-mr-1 md:ag-mr-2 ag-inline-block ag-w-3">4.</span> Review
          </div> */}
        </div>
      )}
      <img
        src={talkingPhotoDialogImage}
        alt="talking photo"
        className="ag-hidden md:ag-block ag-absolute md:ag-bottom-0 ag-right-0 md:ag-left-0"
      />
      {showSupportButton && (
        <button
          className="ag-underline ag-text-gray-400 ag-absolute ag-hidden sm:ag-block ag-bottom-5 ag-right-3 md:ag-bottom-3 md:ag-right-1/2 ag-text-sm ag-opacity-80 hover:ag-opacity-100"
          // @ts-ignore
          onClick={() => window.HubSpotConversations.widget.open()}
        >
          Need help?
        </button>
      )}
    </div>
  );
};

export default SideBar;
