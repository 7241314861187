import { FC } from 'react';
import { Switch } from '@headlessui/react';
import { clsx } from 'clsx';
import { cn } from 'shared/lib';

interface ToggleProps {
  enabled: boolean;
  setEnabled: (value: boolean) => void;
  title?: string;
  disabled?: boolean;
  className?: string;
}

export const Toggle: FC<ToggleProps> = ({
  enabled,
  setEnabled,
  title,
  disabled,
  className
}) => {
  return (
    <Switch.Group
      as="div"
      className={cn(
        'ag-flex ag-items-center ag-justify-between ag-rounded xl:ag-mr-1',
        className
      )}
    >
      {title && (
        <span className="ag-flex ag-flex-grow ag-flex-col">
          <Switch.Label
            as="span"
            className="ag-text-sm ag-font-medium ag-text-black"
            passive
          >
            {title}
          </Switch.Label>
        </span>
      )}
      <Switch
        checked={enabled}
        onChange={() => {
          setEnabled(!enabled);
        }}
        className={clsx(
          enabled ? 'ag-bg-primary-600' : 'ag-bg-neutral-100',
          'ag-relative ag-inline-flex ag-h-6 ag-w-11 ag-flex-shrink-0 ag-cursor-pointer ag-rounded-full ag-border-2 ag-border-transparent ag-transition-colors ag-duration-200 ag-ease-in-out focus:ag-outline-none disabled:ag-opacity-50 disabled:ag-pointer-events-none'
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'ag-translate-x-5' : 'ag-translate-x-0',
            'ag-pointer-events-none ag-inline-block ag-h-5 ag-w-5 ag-transform ag-rounded-full ag-bg-white ag-shadow ag-transition ag-duration-200 ag-ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};
