import { FC, useEffect, useMemo, useState } from 'react';
import { RoomOptions, VideoPresets } from 'livekit-client';

import { USER_CHOICES } from 'shared';

import { LiveKitRoom, videoCallStore } from 'features/livekit-call';

import { VideoConference } from './VideoConference';

const FullscreenView = () => {
  const { secrets } = videoCallStore();

  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        red: false,
        dtx: false,
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216]
      },
      audioCaptureDefaults: {
        deviceId: USER_CHOICES.audioDeviceId ?? undefined
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true
    };
  }, [USER_CHOICES]);

  if (!secrets) return null;

  return (
    // <div className="ag-bg-neutral-900 ag-w-full ag-h-dvh ag-flex ag-flex-col ag-overflow-hidden ag-grow xl:ag-grow-0 xl:ag-shrink ag-relative">
    <LiveKitRoom
      token={secrets.userToken}
      serverUrl={secrets.url}
      options={roomOptions}
      video={USER_CHOICES.videoEnabled}
      audio={USER_CHOICES.audioEnabled}
    >
      <VideoConference />
    </LiveKitRoom>
    // </div>
  );
};

export default FullscreenView;
