import { FC, useState } from 'react';
import { FaRegUser } from 'react-icons/fa';
import { IoVolumeMediumOutline } from 'react-icons/io5';
import { TbFileDescription } from 'react-icons/tb';
import { LuUserSquare2 } from "react-icons/lu";
import {
  ICreatePersonaReq,
  IKnowledgeReq,
  IPersona,
  IPersonaFormik,
  personaStore
} from 'entities/persona';
import { DEFAULT_ENGINE, IAvatar, MAIN_ROUTE } from 'shared/model';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { AvatarFilter, filterAvatarStore } from 'features/filter-avatars';
import AvatarItem from '../../../components/SideBar/Avatars/AvatarsItem';
import { avatarStore } from 'entities/avatar';
import Divider from '../../../components/Divider';
import Modal from '../../../components/shared/Modal';
import CustomTalkingPhotoFlow from '../../../components/SideBar/Avatars/CustomTalkingPhotoFlow/CustomTalkingPhotoFlow';
import { FilledButton } from 'shared/ui';
import { ChooseVoice } from 'features/choose-voice';
import { PersonaDataForm } from 'features/persona-form';
import { cn, logger, scrollBarClasses } from 'shared/lib';
import { Engine, engines } from 'entities/engine';
import { Knowledge } from 'widgets/create-persona';

type Tool = {
  name: string;
  icon: FC<{ className: string }>;
};

const tools: Tool[] = [
  {
    name: 'Persona',
    icon: LuUserSquare2
  },
  {
    name: 'Actor',
    icon: FaRegUser
  },
  {
    name: 'Voice',
    icon: IoVolumeMediumOutline
  },
  {
    name: 'Knowledge',
    icon: TbFileDescription
  },
  {
    name: 'Engine',
    icon: ({ className }) => (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none"  xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M14.584 11.9165C14.584 12.8415 13.8423 13.5832 12.9173 13.5832C11.9923 13.5832 11.2507 12.8415 11.2507 11.9165C11.2507 10.9915 12.0007 10.2498 12.9173 10.2498C13.834 10.2498 14.584 10.9998 14.584 11.9165ZM7.08399 10.2498C6.16732 10.2498 5.41732 10.9998 5.41732 11.9165C5.41732 12.8332 6.16732 13.5832 7.08399 13.5832C8.00065 13.5832 8.75065 12.8415 8.75065 11.9165C8.75065 10.9915 8.00899 10.2498 7.08399 10.2498ZM19.1673 11.4998V13.9998C19.1673 14.4582 18.7923 14.8332 18.334 14.8332H17.5007V15.6665C17.5007 16.5915 16.759 17.3332 15.834 17.3332H4.16732C3.72529 17.3332 3.30137 17.1576 2.98881 16.845C2.67625 16.5325 2.50065 16.1085 2.50065 15.6665V14.8332H1.66732C1.20898 14.8332 0.833984 14.4582 0.833984 13.9998V11.4998C0.833984 11.0415 1.20898 10.6665 1.66732 10.6665H2.50065C2.50065 7.4415 5.10898 4.83317 8.33399 4.83317H9.16732V3.77484C8.66732 3.4915 8.33399 2.94984 8.33399 2.33317C8.33399 1.4165 9.08399 0.666504 10.0007 0.666504C10.9173 0.666504 11.6673 1.4165 11.6673 2.33317C11.6673 2.94984 11.334 3.4915 10.834 3.77484V4.83317H11.6673C14.8923 4.83317 17.5007 7.4415 17.5007 10.6665H18.334C18.7923 10.6665 19.1673 11.0415 19.1673 11.4998ZM17.5007 12.3332H15.834V10.6665C15.834 8.3665 13.9673 6.49984 11.6673 6.49984H8.33399C6.03399 6.49984 4.16732 8.3665 4.16732 10.6665V12.3332H2.50065V13.1665H4.16732V15.6665H15.834V13.1665H17.5007V12.3332Z" fill="#D4D4D4"/>
</svg>

      
    )
  }
];

type Props = {
  setAvatarUrl: (value: {
    menuUrl: string;
    idleLoopUrl: string;
    inputImageUrl?: string;
    gender: IAvatar['gender'];
  }) => void;
  avatarGender: IAvatar['gender'];
  // eslint-disable-next-line 
  formik: ReturnType<typeof useFormik<IPersonaFormik>>;
  persona: IPersona;
};

export const EditPersonaSidebar = ({
  setAvatarUrl,
  formik,
  avatarGender,
  persona
}: Props) => {
  const [tool, setTool] = useState(tools[0].name);
  const [files, setFiles] = useState<string[]>(
    (persona.knowledgeSources ?? []).map((kn) => kn.asset.name)
  );

  const {
    filteredCustomTalkingPhotos,
    filteredTalkingPhotos,
    toggleUploadAvatar
  } = avatarStore();

  const { typeFilters } = filterAvatarStore();

  const handleChangeFiles = (files: string[]) => {
    setFiles(files);
  };

  return (
    <div className="ag-w-full max-lg:ag-overflow-auto lg:ag-w-[556px] ag-bg-neutral-800 ag-border-r ag-border-neutral-600 ag-grid max-lg:ag-grid-rows-[4.625rem,auto] lg:ag-grid-cols-[5rem,auto]">
      <div className="ag-h-full max-lg:ag-border-b lg:ag-border-r ag-border-neutral-600 lg:ag-pt-6 ag-flex lg:ag-flex-col">
        {tools.map((t) => (
          <label
            key={t.name}
            className={`ag-w-full ag-cursor-pointer ag-h-[74px] ag-flex ag-gap-1 ag-flex-col ag-items-center ag-justify-center ag-text-white ${
              tool === t.name ? 'ag-bg-neutral-700' : ''
            }`}
          >
            <input
              type="radio"
              name="tool"
              className="ag-sr-only"
              value={t.name}
              onChange={() => setTool(t.name)}
            />
            <t.icon className="ag-w-5 ag-h-5" />
            <span className="ag-text-xs ag-font-semibold">{t.name}</span>
          </label>
        ))}
      </div>
      <div className={cn("ag-px-4 ag-py-8 ag-overflow-y-auto", scrollBarClasses)}>
        {tool === tools[0].name && (
          <PersonaDataForm classNameBg="ag-bg-neutral-700" formik={formik} />
        )}
        {tool === tools[1].name && (
          <div className="ag-grid ag-grid-rows-[auto,1fr] ag-space-y-6 ag-h-full">
            <div className="ag-space-y-6">
              <FilledButton
                className="ag-w-full ag-py-2.5 ag-font-semibold"
                onClick={toggleUploadAvatar}
              >
                Upload talking photo
              </FilledButton>

              <Divider className="ag-border-neutral-600" />
              <div>
                <AvatarFilter />
              </div>
            </div>
            <div className="ag-grid ag-grid-cols-2 ag-gap-4 ag-overflow-y-auto ag-scrollbar-none">
              {filteredCustomTalkingPhotos &&
                (!typeFilters.length || typeFilters.includes('custom')) &&
                filteredCustomTalkingPhotos.map((avatar) => (
                  <AvatarItem
                    key={avatar.id}
                    onChooseAvatar={() => {
                      formik.setFieldValue('avatarId', avatar.id);
                      setAvatarUrl({
                        idleLoopUrl: avatar.idleLoopVideoUrl,
                        menuUrl: avatar.menuImageUrl,
                        inputImageUrl: avatar.inputImageUrl,
                        gender: avatar.gender
                      });
                    }}
                    isActive={formik.values.avatarId === avatar.id}
                    avatar={avatar}
                  />
                ))}
              {filteredTalkingPhotos &&
                (!typeFilters.length || typeFilters.includes('talkingPhoto')) &&
                filteredTalkingPhotos.map((avatar) => (
                  <AvatarItem
                    key={avatar.id}
                    onChooseAvatar={() => {
                      formik.setFieldValue('avatarId', avatar.id);
                      setAvatarUrl({
                        idleLoopUrl: avatar.idleLoopVideoUrl,
                        menuUrl: avatar.menuImageUrl,
                        inputImageUrl: avatar.inputImageUrl,
                        gender: avatar.gender
                      });
                    }}
                    isActive={formik.values.avatarId === avatar.id}
                    avatar={avatar}
                  />
                ))}
            </div>
          </div>
        )}
        {tool === tools[2].name && (
          <div className="ag-grid ag-grid-rows-[auto,1fr] ag-h-full">
            <ChooseVoice
              voice={formik.values.voice}
              onChange={async (voiceId) =>
                formik.setFieldValue('voice', voiceId)
              }
              avatarGender={avatarGender}
            />
          </div>
        )}
        {tool === tools[4].name && (
          <div className="ag-space-y-2">
            {engines.map((e) => {
              return (
                <Engine
                  key={e.name}
                  {...e}
                  isActive={e.value === formik.values.model}
                  onChange={() => formik.setFieldValue('model', e.value)}
                />
              );
            })}
          </div>
        )}
        {tool === tools[3].name && (
          <Knowledge
            files={files}
            onChangeFile={handleChangeFiles}
            formik={formik}
            onChange={(value: IKnowledgeReq[]) =>
              formik.setFieldValue('knowledgeSources', value)
            }
            isCreate={false}
          />
        )}
      </div>
    </div>
  );
};
