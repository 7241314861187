import { useEffect, useRef, useState } from 'react';

import { useConnectionState } from '@livekit/components-react';
import { ConnectionState, RoomEvent } from 'livekit-client';

import { VscLoading } from 'react-icons/vsc';

import { useClickOutside } from 'shared';

import { videoCallStore, useBotTrack } from 'features/livekit-call';

const connectionColor: Record<ConnectionState, { text: string; bg: string }> = {
  [ConnectionState.Connecting]: {
    text: 'ag-text-[#F5F5F5]',
    bg: 'ag-bg-[#F5F5F5]'
  },
  [ConnectionState.Reconnecting]: {
    text: 'ag-text-[#F5F5F5]',
    bg: 'ag-bg-[#F5F5F5]'
  },
  [ConnectionState.SignalReconnecting]: {
    text: 'ag-text-[#F5F5F5]',
    bg: 'ag-bg-[#F5F5F5]'
  },
  [ConnectionState.Connected]: {
    text: 'ag-text-[#039855]',
    bg: 'ag-bg-[#039855]'
  },
  [ConnectionState.Disconnected]: {
    text: 'ag-text-red-600',
    bg: 'ag-bg-red-600'
  }
};

export const StatusConnection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isVideoStarted } = videoCallStore();

  const botTrack = useBotTrack({
    updateOnlyOn: [RoomEvent.ConnectionStateChanged],
    onlySubscribed: false
  });

  const userConnection = useConnectionState();

  const connectionState: ConnectionState = (() => {
    if (!botTrack)
      return isVideoStarted
        ? ConnectionState.Disconnected
        : ConnectionState.Connecting;

    return userConnection;
  })();

  const botConnection: ConnectionState =
    (botTrack && ConnectionState.Connected) ||
    (isVideoStarted
      ? ConnectionState.Disconnected
      : ConnectionState.Connecting);

  useClickOutside(ref, setIsOpen);

  return (
    <div
      className="ag-group ag-p-2 ag-rounded-lg ag-border ag-border-neutral-600"
      ref={ref}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        onClick={() => setIsOpen((prev) => !prev)}
        className={`${connectionColor[connectionState].text} ag-animate-pulse ag-cursor-pointer lg:ag-cursor-default`}
      >
        <path
          d="M16.2429 5.75732C18.586 8.10047 18.586 11.8995 16.2429 14.2426M7.75758 14.2426C5.41443 11.8995 5.41443 8.10047 7.75758 5.75732M4.92893 17.0711C1.02369 13.1658 1.02369 6.8342 4.92893 2.92896M19.0715 2.92896C22.9768 6.8342 22.9768 13.1658 19.0715 17.0711M12.0002 12C13.1048 12 14.0002 11.1046 14.0002 10C14.0002 8.89543 13.1048 8 12.0002 8C10.8957 8 10.0002 8.89543 10.0002 10C10.0002 11.1046 10.8957 12 12.0002 12ZM12.0002 12V21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
      <div
        className={`ag-text-sm ag-w-40 ${
          isOpen ? 'ag-flex' : 'ag-hidden'
        } lg:ag-hidden lg:group-hover:ag-flex ag-top-full ag-right-0 ag-rounded-lg ag-mt-2 ag-p-2 ag-gap-2 ag-absolute ag-flex-col ag-border ag-border-neutral-600 ag-bg-neutral-900`}
      >
        <div
          className={`${connectionColor[userConnection].text} ag-flex ag-justify-between ag-items-center`}
        >
          <span>User: {userConnection}</span>
          {userConnection === ConnectionState.Connecting ? (
            <VscLoading className="ag-animate-spin ag-w-3.5 ag-h-3.5" />
          ) : (
            <div
              className={`ag-rounded-full ag-w-2 ag-h-2 ${connectionColor[userConnection].bg}`}
            />
          )}
        </div>
        <div
          className={`${connectionColor[botConnection].text} ag-flex ag-justify-between ag-items-center`}
        >
          <span>Bot: {botConnection}</span>
          {botConnection === ConnectionState.Connecting ? (
            <VscLoading className="ag-animate-spin ag-w-3.5 ag-h-3.5" />
          ) : (
            <div
              className={`ag-rounded-full ag-w-2 ag-h-2 ${connectionColor[botConnection].bg}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
