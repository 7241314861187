import { useNavigate } from 'react-router-dom';
import { GoPlus } from 'react-icons/go';
import { TAVUS_CREATE_PERSONA_ROUTE } from 'shared/model';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusPersona } from './TavusPersona';

export const TavusPersonasList = () => {
  const { tavusPersonas } = tavusPersonaStore();

  const { isAuth, toggleCreateAccModal } = useAuthStore();
  const { isChatAvailable, toggleFeatureBlockModal } = creditStore();
  const nav = useNavigate();

  const handleCreatePersona = () => {
    if (isAuth) {
      if (isChatAvailable) {
        nav(TAVUS_CREATE_PERSONA_ROUTE);
      } else {
        toggleFeatureBlockModal('agents');
      }
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <div className="!ag-pb-0 ag-p-4 sm:ag-p-6 xl:ag-p-20 ag-flex ag-flex-col ag-h-screen ag-w-full">
      <h1 className="ag-font-semibold dark:ag-text-white ag-mb-8">
        Who do you want to speak to today?
      </h1>
      <div className="ag-w-full ag-grid ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6">
        <button
          onClick={handleCreatePersona}
          className="ag-h-72 lg:ag-h-72 3xl:ag-h-96 ag-p-3 ag-border ag-bg-primary-100 dark:ag-bg-neutral-800 ag-border-primary-300 dark:ag-border-neutral-600 ag-rounded-lg ag-flex ag-flex-col ag-items-center ag-justify-center ag-text-primary-600 dark:ag-text-neutral-100 ag-text-center"
        >
          <GoPlus className="ag-size-10" />
          <span className="ag-text-sm ag-font-semibold">
            Create Video Agent
          </span>
        </button>

        {tavusPersonas.map((persona, ind) => (
          <TavusPersona key={persona.persona_id + ind} persona={persona} />
        ))}
      </div>
    </div>
  );
};
