import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const baseURL =
  'https://storage.googleapis.com/upload/storage/v1/b/yepicai-backend.appspot.com/o?uploadType=media&name=';

const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
});

const http2 = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_NEW,
  headers: {
    Accept: 'application/json'
  }
});

const getOptions = (file: File) => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': file.type
    },
    body: file
  };
};

// TODO: Refactor with one method

const filesService = {
  upload: async (file: File) => {
    const fileName = uuidv4() + '.' + file.name.split('.').pop();
    const res = await fetch(baseURL + fileName, getOptions(file));
    const data = await res.json();
    return data;
  },
  uploadWithProgress: async (
    file: File,
    onUploadProgress: (e: any) => void
  ) => {
    const fileName = uuidv4() + '.' + file.name.split('.').pop();
    const { data } = await http.post('/' + fileName, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress
    });
    return data;
  },
  detectFace: async (file: FormData) => {
    const { data } = await http2.post('/facedetection', file, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
  }
};

export default filesService;
