import { Engine, engines } from 'entities/engine';
import { IPersonaFormik } from 'entities/persona';
import { FormikErrors, FormikProps } from 'formik';
import { DEFAULT_ENGINE } from 'shared/model';

type Props = {
  formik: FormikProps<IPersonaFormik>;
  onChange: (
    value: string
  ) => Promise<void> | Promise<FormikErrors<IPersonaFormik>>;
};

export const ChooseEngine = ({ formik, onChange }: Props) => {
  return (
    <div>
      <div className="ag-text-neutral-100">Engine</div>
      <div className="ag-text-neutral-400 ag-text-sm ag-mb-4">
        Select an engine for your video agent
      </div>
      <div className="ag-space-y-2">
        {engines.map((e) => (
          <Engine
            key={e.name}
            {...e}
            isActive={e.value === formik.values.model}
            onChange={() => onChange(e.value)}
          />
        ))}
      </div>
    </div>
  );
};
