import { AxiosResponse } from 'axios';
import { ConversationRequest, Conversation } from '../model';
import { tavusHttpService } from 'shared/api';

const tavusConversationsEndpoint = '/conversations';

export const conversationApi = {
  createConversation: async (conversationData: ConversationRequest) => {
    const { data }: AxiosResponse<Conversation> = await tavusHttpService.post(
      tavusConversationsEndpoint,
      conversationData
    );

    return data;
  },
  endConversation: async (conversationId: string) => {
    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}/${conversationId}/end`
    );

    return data;
  }
};
