import { useEffect, useState } from 'react';

export const usePlaySound = () => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayAudio = (audio: HTMLAudioElement) => {
    if (isPlaying) {
      handlePauseAudio();
    }
    setCurrentAudio(audio);
    setIsPlaying(true);
    audio.play();
  };

  const handlePauseAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio.removeEventListener('ended', () => setIsPlaying(false));
      setCurrentAudio(null);
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener('ended', () => setIsPlaying(false));
    }
  }, [currentAudio]);

  return {
    currentAudio,
    isPlaying,
    handlePlayAudio,
    handlePauseAudio
  };
};
