import React, { FC } from 'react';
import { logger } from 'shared/lib';

type Props = {
  value: string;
  isActive: boolean;
  name: string;
  provider: string;
  icon: JSX.Element;
  description: string;
  onChange: () => void;
};

export const Engine: FC<Props> = ({
  value,
  isActive,
  name,
  provider,
  icon,
  description,
  onChange
}) => {
  return (
    <div
      key={`${value}-engine`}
      className={`ag-border ag-text-white ${
        isActive
          ? 'ag-border-secondary ag-cursor-default'
          : 'ag-border-neutral-600 ag-cursor-pointer'
      } ag-items-center ag-text-sm disabled:ag-opacity-50 ag-rounded-lg ag-w-full ag-justify-start ag-flex ag-flex-col ag-px-4 ag-py-3 ag-gap-3 `}
      onClick={() => {
        onChange();
      }}
    >
      <div className="ag-w-full ag-flex ag-flex-row ag-gap-x-3 ag-items-center">
        {icon}
        <div className="ag-flex ag-flex-col ag-text-start">
          <span className="ag-mb-1">{name}</span>
          <span className="ag-text-neutral-400">{description}</span>
        </div>
      </div>
    </div>
  );
};
