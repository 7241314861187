import {
  HiExclamationCircle,
  HiOutlineCheckCircle,
  HiXCircle
} from 'react-icons/hi';
import { toast } from 'react-toastify';

export const showMessage = (text: string) => {
  toast(text);
};

export const showSuccessMessage = (text: string) => {
  toast.success(text, {
    icon: (
      <HiOutlineCheckCircle
        className="ag-size-6 ag-text-green-400"
        aria-hidden="true"
      />
    )
  });
};

export const showError = (text: string) => {
  toast.error(text, {
    icon: <HiXCircle className="ag-size-6 ag-text-red-400" aria-hidden="true" />
  });
};

export const showWarn = (text: string) => {
  toast.warn(text, {
    icon: (
      <HiExclamationCircle
        className="ag-size-6 ag-text-yellow-400"
        aria-hidden="true"
      />
    )
  });
};
