import { FC, useEffect, useMemo, useState } from 'react';
import { RoomOptions, VideoPresets } from 'livekit-client';

import { USER_CHOICES } from 'shared';

import { LiveKitRoom, videoCallStore } from 'features/livekit-call';

import { EventConference } from './EventConference';

const EventView = () => {
  const { secrets } = videoCallStore();

  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        red: false,
        dtx: false,
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216]
      },
      audioCaptureDefaults: {
        deviceId: USER_CHOICES.audioDeviceId ?? undefined
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true
    };
  }, [USER_CHOICES]);

  if (!secrets) return null;

  return (
    <LiveKitRoom
      token={secrets.userToken}
      serverUrl={secrets.url}
      options={roomOptions}
      video={USER_CHOICES.videoEnabled}
      audio={USER_CHOICES.audioEnabled}
    >
      <EventConference />
    </LiveKitRoom>
  );
};

export default EventView;
