import { activeClasses, cn, logger } from 'shared';
import { DatachannelMessageType, useCall } from 'features/livekit-call';

type Props = {
  className?: string;
};

export const StopTalkingBtn = ({ className }: Props) => {
  const { sendJsonMessage } = useCall();

  const handleStopTalking = () => {
    sendJsonMessage({
      message: '/stop',
      type: DatachannelMessageType.StopTalk
    });
  };

  return (
    <button
      onClick={handleStopTalking}
      className={cn(
        'ag-size-10 ag-block ag-mx-auto ag-p-2.5 ag-bg-neutral-900/60 ag-rounded-lg',
        className || 'ag-absolute ag-left-0 ag-right-0 ag-bottom-0 '
      )}
    >
      <div
        className={cn(
          'ag-rounded-md ag-w-full ag-h-full ag-bg-white',
          activeClasses
        )}
      ></div>
    </button>
  );
};
