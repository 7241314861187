import {
  DailyAudio,
  DailyProvider,
  DailyVideo,
  useParticipantIds,
  useVideoTrack
} from '@daily-co/daily-react';
import { conversationStore } from 'entities/tavus/conversation';
import { DAILY_CALL_STATES, events } from 'features/daily-call/model';
import { dailyCallStore } from 'features/daily-call/model/dailyCallStore';
import { useEffect } from 'react';
import { Loader } from 'shared/ui';
import { TavusEmbedControlBar } from 'widgets/call-controls';

export const TavusEmbedConference = () => {
  const { currentConversation, endConversation } = conversationStore();

  const {
    startHairCheck,
    callObject,
    handleNewMeetingState,
    currentState,
    startLeavingCall,
    joinCall
  } = dailyCallStore();

  useEffect(() => {
    if (currentConversation) {
      startHairCheck(currentConversation.conversation_url);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (!callObject) return;

    handleNewMeetingState();
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  const handleEndCall = () => {
    if (currentConversation?.conversation_id) {
      startLeavingCall();
      endConversation();
    }
  };

  useEffect(() => {
    if (currentState === DAILY_CALL_STATES.STATE_HAIRCHECK) {
      if (currentConversation) {
        joinCall(currentConversation?.conversation_url);
      }
    }
  }, [currentState]);

  if (!callObject) return null;

  return (
    <div
      className={`ag-flex ag-items-center ag-justify-center ag-relative ag-h-full`}
    >
      <DailyProvider callObject={callObject}>
        {[
          DAILY_CALL_STATES.STATE_JOINING,
          DAILY_CALL_STATES.STATE_JOINED,
          DAILY_CALL_STATES.STATE_ERROR
        ].includes(currentState) ? (
          <div className="ag-w-full ag-h-full">
            <Video />
            <TavusEmbedControlBar onCallEnd={handleEndCall} />
          </div>
        ) : (
          <div className="ag-w-full ag-h-screen ag-top-0 ag-left-0 ag-right-0 ag-bottom-0 ag-object-cover ag-z-20 ag-flex ag-items-center ag-justify-center">
            <Loader size={96} />
          </div>
        )}

        <DailyAudio />
      </DailyProvider>
    </div>
  );
};

const Video = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  return (
    <div className="ag-relative ag-w-full ag-pb-[4.5rem] ag-h-full ag-flex ag-justify-center ">
      {remoteParticipantIds.length > 0 ? (
        remoteParticipantIds.map((id) => (
          <DailyVideo
            automirror
            key={id}
            sessionId={id}
            type={'video'}
            playableStyle={{ width: '100%', height: '100%' }}
            fit="cover"
          />
        ))
      ) : (
        <div
          className={
            'ag-w-full ag-h-screen ag-flex ag-justify-center ag-items-center ag-pb-5 ag-flex-col'
          }
        >
          <Loader size={96} />
        </div>
      )}
    </div>
  );
};
