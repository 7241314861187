import {
  useLiveKitRoom,
  LiveKitRoomProps,
  LKFeatureContext,
  RoomContext
} from '@livekit/components-react';
import { FC, PropsWithChildren } from 'react';

export const LiveKitRoom: FC<PropsWithChildren<LiveKitRoomProps>> = (props) => {
  const {
    room,
    htmlProps: { className, ...htmlProps }
  } = useLiveKitRoom(props);

  return (
    <>
      {room && (
        <RoomContext.Provider value={room}>
          <LKFeatureContext.Provider value={props.featureFlags}>
            {props.children}
          </LKFeatureContext.Provider>
        </RoomContext.Provider>
      )}
    </>
  );
};
